import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Icon from "@mui/material/Icon";
import ExpansionPanel from "@mui/material/Accordion";
import ExpansionPanelSummary from "@mui/material/AccordionSummary";
import ExpansionPanelDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { appActions, userActions } from "../../../../../../actions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Paper from "@mui/material/Paper";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import MessageFormat from "messageformat";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class SelectPlan extends Component {
  state = {
    value: 0
    //dependentOupOut:false
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      previousPlan:
        props.user.previousPlans.filter(
          plan => plan.option === props.user.previousSelectedPlan
        )[0] || "",
      plans: props.user.currentPlans,
      dependentPlans: props.user.premiumPlans,
      planSelected: props.state.user.planSelected || "",
      value: 0,
      content: props.user.content,
      placeholderMap: props.user.placeholderMap,
      dependent: props.user.dependentByRelation || "Dependants",
      dependendentPlanSelected: props.user.planSelectedDependent || "",
      showDependentBadge: new Map(),
      planMatrix: new Map(),
      dependentOptOut: new Map(),
      rateMatrics: props.user.rateMatrics,
      masterChild: false,
      masterSpouse: false,
      relationReadOnly: false,
      disallowNextPlanSelection: false,
      defaultPlan: {},
      isPruAgencyMedEnrolment:
        props.user.client_name.config.isPruAgencyMedEnrolment === "True"
          ? true
          : false,
      optOutDependent: false,
      optOutDependentButton: false
    };
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleCardClickAllIn = this.handleCardClickAllIn.bind(this);
  }

  handleChange = (event, value) => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let planSelected = this.state.planSelected;
    let dependentPlanSelected = this.state.dependendentPlanSelected;
    let pruAgencyPlanTypes = isPruAgencyEnrolment
      ? [
          "Group Term Life (TPD up to 65 YO, death benefit)",
          "Group Crisis Cover Accelerated (37 Critical illness)",
          "Group Pre-crisis Guard Accelerated (Early CI)"
        ]
      : [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits"
        ];
    this.props.scrollToTop();
    this.setState({ value });

    if (isPruAgencyEnrolment) {
      // Disable next plan selection if previous plan haven't selected
      if (this.props.user.dependencies.length > 0) {
        if (planSelected === "" && dependentPlanSelected && value !== 0) {
          this.setState({
            disallowNextPlanSelection: true
          });
        } else if (
          planSelected !== "" &&
          dependentPlanSelected &&
          value !== 0
        ) {
          this.setState({
            disallowNextPlanSelection: true
          });
          if (
            planSelected.has(pruAgencyPlanTypes[value - 1]) &&
            planSelected.get(pruAgencyPlanTypes[value - 1]) !== "" &&
            dependentPlanSelected.has(pruAgencyPlanTypes[value - 1])
          ) {
            dependentPlanSelected
              .get(pruAgencyPlanTypes[value - 1])
              .filter(dependent => {
                if (dependent.planDep !== "") {
                  this.setState({
                    disallowNextPlanSelection: false
                  });
                }
              });
          }
        } else if (value === 0) {
          this.setState({
            disallowNextPlanSelection: false
          });
        }
      }
    } else if (this.state.isPruAgencyMedEnrolment) {
      if (this.props.user.dependencies.length > 0) {
        if (
          planSelected === "" &&
          dependentPlanSelected === "" &&
          value !== 0
        ) {
          this.setState({
            disallowNextPlanSelection: true
          });
        } else if (
          planSelected !== "" &&
          (dependentPlanSelected === "" || dependentPlanSelected) &&
          value !== 0
        ) {
          this.setState({
            disallowNextPlanSelection: true
          });

          if (
            value !== 0 &&
            planSelected.has(pruAgencyPlanTypes[value - 1]) &&
            planSelected.get(pruAgencyPlanTypes[value - 1]) !== "" &&
            planSelected.get(pruAgencyPlanTypes[value - 1]) !== "Opt Out"
          ) {
            this.setState({
              disallowNextPlanSelection: false
            });
          }
        } else if (value === 0) {
          this.setState({
            disallowNextPlanSelection: false
          });
        }
      }

      if (
        value === 1 &&
        this.props.user.coverage_details.standAloneGMM === true &&
        planSelected &&
        planSelected.has(pruAgencyPlanTypes[0]) &&
        planSelected.get(pruAgencyPlanTypes[0]) === "Opt Out"
      ) {
        this.setState({
          disallowNextPlanSelection: false
        });
      }

      if (
        this.props.user.dependencies.length > 0 &&
        dependentPlanSelected &&
        dependentPlanSelected.has(pruAgencyPlanTypes[value - 1])
      ) {
        if (dependentPlanSelected.get(pruAgencyPlanTypes[value - 1]) === "") {
          this.setState({
            disallowNextPlanSelection: true
          });
        } else {
          dependentPlanSelected
            .get(pruAgencyPlanTypes[value - 1])
            .filter(dependent => {
              if (dependent.planDep !== "Opt Out") {
                this.setState({
                  disallowNextPlanSelection: false
                });
              }
              if (dependent.planDep === "Opt Out") {
                this.setState({
                  optOutDependentButton: true,
                  optOutDependent: true
                });
              }
            });
        }
      } else if (value === 0) {
        this.setState({
          optOutDependentButton: false
        });
      }
    }
  };
  handleCoverageNext = value => {
    this.props.scrollToTop();
    this.setState({ value });
  };

  handleChangeSelect = planHead => event => {
    let myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    myDependentSelectMap.set(planHead.planType, "");
    showDependentBadge.set(planHead.planType, false);
    let dependent = event.target.value;
    this.setState(
      {
        ...this.state,
        [event.target.name]: dependent,
        dependendentPlanSelected: myDependentSelectMap
      },
      () => {
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculationDep(myDependentSelectMap)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(myDependentSelectMap, showDependentBadge)
            ? true
            : false
        );
        this.props.setDependentByRelation(dependent);
      }
    );
  };

  handleChangeSwitch = (type, dependent, coverage) => event => {
    this.props.handleIsPlanChanged(true);
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let pruAgencyPlanTypes = isPruAgencyEnrolment
      ? [
          "Group Term Life (TPD up to 65 YO, death benefit)",
          "Group Crisis Cover Accelerated (37 Critical illness)",
          "Group Pre-crisis Guard Accelerated (Early CI)"
        ]
      : [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits"
        ];
    let questionDepMap = new Map(this.props.user.questionDepMap);
    let selectedValue = new Map(this.props.user.selectedValue);
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    let myDependentSelectMap = new Map();
    let dependentOptOut = new Map();
    let dependentOptOutName = new Map();
    if (this.props.user.dependentOptOutName !== undefined) {
      dependentOptOutName = new Map(this.props.user.dependentOptOutName);
    }
    dependentOptOut = new Map();
    if (this.props.user.dependentOptOut !== undefined) {
      dependentOptOut = new Map(this.props.user.dependentOptOut);
    }
    // myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }
    let mySelectMap = new Map();
    if (this.props.user.planSelected !== undefined) {
      mySelectMap = new Map(this.props.user.planSelected);
    }

    if (type === "name" && isPruAgencyEnrolment === true) {
      let empPlanObj = mySelectMap.get(coverage) || "";
      let planObjList = [];
      planObjList = myDependentSelectMap.get(coverage) || [];
      let planObj = { planDep: "Opt Out", dependentType: dependent };
      let index = planObjList.findIndex(
        x => x.dependentType.id === dependent.id
      );

      if (isPruAgencyEnrolment === true) {
        if (event.target.checked) {
          empPlanObj = "";
          if (index === -1) {
            planObjList.push(planObj);
          } else {
            planObjList.splice(index, 1, planObj);
          }
        } else {
          empPlanObj = "";
          planObjList.splice(index, 1);
          showDependentBadge.set(coverage, false);
        }

        for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
          if (coverage === pruAgencyPlanTypes[count]) {
            for (
              let index = count;
              index < pruAgencyPlanTypes.length;
              index++
            ) {
              // To clear child plan selection
              if (index + 1 < pruAgencyPlanTypes.length) {
                mySelectMap.set(pruAgencyPlanTypes[index + 1], empPlanObj);
                myDependentSelectMap.set(pruAgencyPlanTypes[index + 1], []);
                showDependentBadge.set(pruAgencyPlanTypes[index + 1], false);
              }
              myDependentSelectMap.set(pruAgencyPlanTypes[index], planObjList);
              if (
                mySelectMap.get(pruAgencyPlanTypes[index]) &&
                myDependentSelectMap.get(pruAgencyPlanTypes[index])
              ) {
                myDependentSelectMap
                  .get(pruAgencyPlanTypes[index])
                  .filter(dependent => {
                    if (
                      mySelectMap.get(pruAgencyPlanTypes[index]) &&
                      dependent.planDep === "Opt Out"
                    ) {
                      showDependentBadge.set(pruAgencyPlanTypes[index], true);
                    } else {
                      if (index + 1 < pruAgencyPlanTypes.length) {
                        showDependentBadge.set(
                          pruAgencyPlanTypes[index + 1],
                          false
                        );
                      }
                      showDependentBadge.set(pruAgencyPlanTypes[index], false);
                    }
                  });
              } else if (
                count + 1 < pruAgencyPlanTypes.length &&
                myDependentSelectMap.get(pruAgencyPlanTypes[index]).length === 0
              ) {
                showDependentBadge.set(pruAgencyPlanTypes[count + 1], false);
                if (count + 2 < pruAgencyPlanTypes.length) {
                  showDependentBadge.set(pruAgencyPlanTypes[count + 2], false);
                }
              }
              dependentOptOutName.set(dependent.id, event.target.checked);
              let questionSet = new Map(questionDepMap.get(dependent.id));
              questionSet.clear();
              questionDepMap.set(dependent.id, questionSet);
              let remarksSet = new Map(selectedValue.get(dependent.id));
              remarksSet.clear();
              selectedValue.set(dependent.id, remarksSet);
            }
          }
        }
      }
    } else if (type === "name" && this.state.isPruAgencyMedEnrolment === true) {
      let empPlanObj = mySelectMap.get(coverage) || "";
      let planObjList = [];
      planObjList = myDependentSelectMap.get(coverage) || [];
      let spouse;

      this.props.user.dependencies.map(dependent => {
        if (dependent.dependent === "SPOUSE") {
          spouse = dependent;
        }
      });
      let planObj = { planDep: "Opt Out", dependentType: dependent };
      let index = planObjList.findIndex(
        x => x.dependentType.id === dependent.id
      );

      if (isPruAgencyEnrolment === true || this.state.isPruAgencyMedEnrolment) {
        if (event.target.checked) {
          this.setState({
            optOutDependent: true
          });
          if (index === -1) {
            planObjList.push(planObj);
          } else {
            planObjList.splice(index, 1, planObj);
          }
        } else {
          empPlanObj = "";
          planObjList.splice(index, 1);
          showDependentBadge.set(coverage, false);
        }

        if (isPruAgencyEnrolment || this.state.isPruAgencyMedEnrolment) {
          for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
            if (coverage === pruAgencyPlanTypes[count]) {
              for (
                let index = count;
                index < pruAgencyPlanTypes.length;
                index++
              ) {
                // To clear child plan selection
                if (index + 1 < pruAgencyPlanTypes.length) {
                  mySelectMap.set(pruAgencyPlanTypes[index + 1], empPlanObj);
                  myDependentSelectMap.set(pruAgencyPlanTypes[index + 1], []);
                  showDependentBadge.set(pruAgencyPlanTypes[index + 1], false);
                }
                myDependentSelectMap.set(
                  pruAgencyPlanTypes[index],
                  planObjList
                );

                if (
                  mySelectMap.get(pruAgencyPlanTypes[index]) &&
                  myDependentSelectMap.get(pruAgencyPlanTypes[index])
                ) {
                  myDependentSelectMap
                    .get(pruAgencyPlanTypes[index])
                    .filter(dependent => {
                      if (
                        mySelectMap.get(pruAgencyPlanTypes[index]) &&
                        dependent.planDep === "Opt Out"
                      ) {
                        showDependentBadge.set(pruAgencyPlanTypes[index], true);
                      } else {
                        if (index + 1 < pruAgencyPlanTypes.length) {
                          showDependentBadge.set(
                            pruAgencyPlanTypes[index + 1],
                            false
                          );
                        }
                        showDependentBadge.set(
                          pruAgencyPlanTypes[index],
                          false
                        );
                      }
                    });
                } else if (
                  count + 1 < pruAgencyPlanTypes.length &&
                  myDependentSelectMap.get(pruAgencyPlanTypes[index]).length ===
                    0
                ) {
                  showDependentBadge.set(pruAgencyPlanTypes[count + 1], false);
                  if (count + 2 < pruAgencyPlanTypes.length) {
                    showDependentBadge.set(
                      pruAgencyPlanTypes[count + 2],
                      false
                    );
                  }
                }
              }
            }
          }

          if (
            coverage ===
            "Group Accidental Death & Dismemberment (GADD) Benefits"
          ) {
            myDependentSelectMap.set(coverage, planObjList);
            if (
              mySelectMap.get(coverage) !== "" &&
              myDependentSelectMap.get(coverage).length > 0
            ) {
              if (
                mySelectMap.get(coverage) !== "" &&
                myDependentSelectMap.get(coverage).length > 0
              ) {
                showDependentBadge.set(coverage, true);
              } else {
                showDependentBadge.set(coverage, false);
              }
            }
          }
        }

        dependentOptOutName.set(dependent.id, event.target.checked);
        let questionSet = new Map(questionDepMap.get(dependent.id));
        questionSet.clear();
        questionDepMap.set(dependent.id, questionSet);
        let remarksSet = new Map(selectedValue.get(dependent.id));
        remarksSet.clear();
        selectedValue.set(dependent.id, remarksSet);
      }
    } else if (type === "name") {
      let planObjList = [];
      planObjList = myDependentSelectMap.get(coverage) || [];
      let planObj = { planDep: "Opt Out", dependentType: dependent };
      let index = planObjList.findIndex(
        x => x.dependentType.id === dependent.id
      );

      if (event.target.checked) {
        if (index === -1) {
          planObjList.push(planObj);
        } else {
          planObjList.splice(index, 1, planObj);
        }
      } else {
        planObjList.splice(index, 1);
        showDependentBadge.set(coverage, false);
      }
      myDependentSelectMap.set(coverage, planObjList);
      if (
        myDependentSelectMap.get(coverage).length ===
        this.state.planMatrix.get(coverage)
      ) {
        let optOutCount = 0;
        myDependentSelectMap.get(coverage).filter(planObj => {
          planObj.planDep === "Opt Out" ? optOutCount++ : optOutCount;
        });
        if (optOutCount === this.state.planMatrix.get(coverage)) {
          mySelectMap.get(coverage) && mySelectMap.get(coverage) !== "Opt Out"
            ? showDependentBadge.set(coverage, true)
            : showDependentBadge.set(coverage, false);
        } else {
          showDependentBadge.set(coverage, event.target.checked ? true : false);
        }
      }
      dependentOptOutName.set(dependent.id, event.target.checked);

      let questionSet = new Map(questionDepMap.get(dependent.id));
      questionSet.clear();
      questionDepMap.set(dependent.id, questionSet);
      // questionDepMap.delete(dependent.id);
      let remarksSet = new Map(selectedValue.get(dependent.id));
      remarksSet.clear();
      selectedValue.set(dependent.id, remarksSet);
    }

    if (type === "relation") {
      let depPremiumSA = new Map(this.props.user.depPremiumSA);
      let planObjList = [];
      planObjList = myDependentSelectMap.get(coverage) || [];
      let index = planObjList.findIndex(
        x => x.dependentType.id === dependent.id
      );
      let spouse;
      this.props.user.dependencies.map(dependent => {
        if (dependent.dependent === "SPOUSE") {
          spouse = dependent;
        }
      });
      let planObj = { planDep: "Opt Out", dependentType: dependent };
      let empPlanObj = mySelectMap.get(coverage) || "";

      if (this.state.isPruAgencyMedEnrolment) {
        empPlanObj = "";
        if (event.target.checked) {
          if (index === -1) {
            planObjList.push(planObj);
          } else {
            planObjList.splice(index, 1, planObj);
          }
          this.setState({ optOutDependent: true });
        } else {
          planObj = {
            planDep: this.state.planSelected.get(coverage),
            dependentType: dependent
          };
          empPlanObj = "";
          planObjList.splice(index, 1);
          planObjList.push(planObj);
          showDependentBadge.set(coverage, false);
          this.setState({ optOutDependent: false });
        }
      } else {
        planObjList.push(planObj);
      }

      if (this.state.isPruAgencyMedEnrolment) {
        myDependentSelectMap.set(
          "Group Accidental Death & Dismemberment (GADD) Benefits",
          []
        );

        if (event.target.checked) {
          let gaddPlanObj = {
            planDep: "Opt Out",
            dependentType: spouse
          };
          myDependentSelectMap.set(
            "Group Accidental Death & Dismemberment (GADD) Benefits",
            [gaddPlanObj]
          );
        } else {
          myDependentSelectMap.set(
            "Group Accidental Death & Dismemberment (GADD) Benefits",
            []
          );
        }
      }

      // myDependentSelectMap.set(coverage, planObjList);
      // dependentOptOut.set(coverage, event.target.checked);
      if (event.target.checked) {
        for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
          if (coverage === pruAgencyPlanTypes[count]) {
            for (
              let index = count;
              index < pruAgencyPlanTypes.length;
              index++
            ) {
              // To clear child plan selection
              if (index + 1 < pruAgencyPlanTypes.length) {
                mySelectMap.set(pruAgencyPlanTypes[index + 1], empPlanObj);
                myDependentSelectMap.set(pruAgencyPlanTypes[index + 1], []);
                showDependentBadge.set(pruAgencyPlanTypes[index + 1], false);
              }
              myDependentSelectMap.set(pruAgencyPlanTypes[index], planObjList);
              depPremiumSA.delete(pruAgencyPlanTypes[index]);
              this.props.setDepSAandPremium(depPremiumSA);
              if (
                mySelectMap.get(pruAgencyPlanTypes[index]) &&
                myDependentSelectMap.get(pruAgencyPlanTypes[index])
              ) {
                myDependentSelectMap
                  .get(pruAgencyPlanTypes[index])
                  .filter(dependent => {
                    if (
                      mySelectMap.get(pruAgencyPlanTypes[index]) &&
                      dependent.planDep === "Opt Out"
                    ) {
                      showDependentBadge.set(pruAgencyPlanTypes[index], true);
                    } else {
                      if (index + 1 < pruAgencyPlanTypes.length) {
                        showDependentBadge.set(
                          pruAgencyPlanTypes[index + 1],
                          false
                        );
                      }
                      showDependentBadge.set(pruAgencyPlanTypes[index], false);
                    }
                  });
              } else if (
                count + 1 < pruAgencyPlanTypes.length &&
                myDependentSelectMap.get(pruAgencyPlanTypes[index]).length === 0
              ) {
                showDependentBadge.set(pruAgencyPlanTypes[count + 1], false);
                if (count + 2 < pruAgencyPlanTypes.length) {
                  showDependentBadge.set(pruAgencyPlanTypes[count + 2], false);
                }
              }
            }
          }
        }
      } else {
        let pruAgencyMedPlanTypes = [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits",
          "Group Accidental Death & Dismemberment (GADD) Benefits"
        ];

        for (let count = 0; count < pruAgencyMedPlanTypes.length; count++) {
          if (coverage === pruAgencyMedPlanTypes[count]) {
            for (
              let index = count;
              index < pruAgencyMedPlanTypes.length;
              index++
            ) {
              mySelectMap.set(pruAgencyMedPlanTypes[index + 1], "");
              myDependentSelectMap.set(pruAgencyMedPlanTypes[index + 1], []);
              if (index + 1 < pruAgencyMedPlanTypes.length) {
                showDependentBadge.set(pruAgencyMedPlanTypes[index + 1], false);
              }
            }
          }
        }
        mySelectMap.delete(undefined);
        myDependentSelectMap.delete(undefined);
      }

      if (
        coverage === "Group Hospital & Surgical (GHS) Benefits" ||
        coverage === "Group Extended Major Medical Rider (GMM) Benefits"
      ) {
        if (
          myDependentSelectMap &&
          myDependentSelectMap.has(
            "Group Accidental Death & Dismemberment (GADD) Benefits"
          )
        ) {
          myDependentSelectMap
            .get("Group Hospital & Surgical (GHS) Benefits")
            .filter(plan => {
              if (plan.planDep === "Opt Out") {
                let spouse;
                let hasSpouse = false;
                if (this.props.user.dependencies.length > 0) {
                  this.props.user.dependencies.map(dependent => {
                    if (dependent.dependent === "SPOUSE") {
                      spouse = dependent;
                      hasSpouse = true;
                    }
                  });
                }

                let depPlanObj = {
                  planDep: "Opt Out",
                  dependentType: hasSpouse ? spouse : ""
                };
                myDependentSelectMap.set(
                  "Group Accidental Death & Dismemberment (GADD) Benefits",
                  [depPlanObj]
                );
              }
            });
        }
      }

      if (
        myDependentSelectMap.get(coverage).length ===
        this.state.planMatrix.get(coverage)
      ) {
        showDependentBadge.set(coverage, event.target.checked ? true : false);
      }
    }
    if (type === "cluster") {
      let optOutVal = 0;
      let masterOptedOut = false;
      let spouseDisableCount = 0;
      let childrenDisableCount = 0;
      this.props.user.currentPlans.map(currentPlan => {
        let categoryRule = this.props.user.client_name.config.categoryRules[
          this.props.user.category
        ];
        let masterPlanName = "";
        if (this.props.user.depRule[currentPlan.coverageId].masterOptOut) {
          masterPlanName = currentPlan.planType;
        }
        if (coverage == masterPlanName) {
          if (event.target.checked) {
            masterOptedOut = true;
            this.props.user.currentPlans.map(otherPlan => {
              if (
                this.props.user.depRule[otherPlan.coverageId]
                  .neglectMasterOptOut !== true
              ) {
                if (otherPlan.coverageId != currentPlan.coverageId) {
                  if (dependent === "Spouse Only") {
                    categoryRule[[otherPlan.coverageId]].spouseEnrole = "false";
                    this.state.masterSpouse = true;
                    myDependentSelectMap.set(otherPlan.planType, "");
                    showDependentBadge.set(otherPlan.planType, false);
                    dependentOptOut.set(
                      otherPlan.planType + "Spouse Only",
                      false
                    );
                    dependentOptOut.set(
                      otherPlan.planType + "Child(ren) Only",
                      false
                    );
                    spouseDisableCount += 1;
                  }
                  if (dependent === "Child(ren) Only") {
                    categoryRule[[otherPlan.coverageId]].childrenEnrole =
                      "false";
                    this.state.masterChild = true;
                    myDependentSelectMap.set(otherPlan.planType, "");
                    showDependentBadge.set(otherPlan.planType, false);
                    dependentOptOut.set(
                      otherPlan.planType + "Spouse Only",
                      false
                    );
                    dependentOptOut.set(
                      otherPlan.planType + "Child(ren) Only",
                      false
                    );
                    childrenDisableCount += 1;
                  }
                  //reset other employee plan
                  mySelectMap.delete(otherPlan.planType);
                }
              }
            });
            if (
              categoryRule[currentPlan.coverageId].dependentOptOutRates !==
              undefined
            ) {
              optOutVal = parseFloat(
                categoryRule[currentPlan.coverageId].dependentOptOutRates
              );
            }
          } else {
            masterOptedOut = false;
            this.props.user.currentPlans.map(otherPlan => {
              if (
                otherPlan.coverageId != currentPlan.coverageId &&
                this.props.user.depRule[otherPlan.coverageId]
                  .neglectMasterOptOut !== true
              ) {
                if (dependent === "Spouse Only") {
                  categoryRule[[otherPlan.coverageId]].spouseEnrole = "true";
                  spouseDisableCount -= 1;
                  this.state.masterSpouse = false;
                  showDependentBadge.set(otherPlan.planType, false);
                }
                if (dependent === "Child(ren) Only") {
                  categoryRule[[otherPlan.coverageId]].childrenEnrole = "true";
                  childrenDisableCount -= 1;
                  this.state.masterChild = false;
                  showDependentBadge.set(otherPlan.planType, false);
                }
              }
            });
            if (
              categoryRule[currentPlan.coverageId].dependentOptOutRates !==
              undefined
            ) {
              optOutVal = 0;
            }
          }
        } else if (coverage == currentPlan.planType) {
          if (event.target.checked) {
            if (
              categoryRule[currentPlan.coverageId].dependentOptOutRates !==
              undefined
            ) {
              optOutVal = parseFloat(
                categoryRule[currentPlan.coverageId].dependentOptOutRates
              );
            }
          } else {
            optOutVal = 0;
          }
        }
      });
      let planObjList = [];

      planObjList = myDependentSelectMap.get(coverage) || [];
      let index = planObjList.findIndex(x => x.dependentType === dependent);
      let planObj = {
        planDep: "Opt Out",
        dependentType: dependent,
        optOutValue: optOutVal,
        masterOptOut: masterOptedOut,
        coverageId: this.getCoverageIdByName(coverage)
      };

      if (event.target.checked) {
        if (index === -1) {
          planObjList.push(planObj);
        } else {
          planObjList.splice(index, 1, planObj);
        }
      } else {
        planObjList.splice(index, 1);
        showDependentBadge.set(coverage, false);
      }

      myDependentSelectMap.set(coverage, planObjList);

      if (
        coverage === "Group Pruclinicare (GPRU) Benefits" &&
        this.props.user?.client_name?.client_name === "RI"
      ) {
        console.log("this.props.user", this.props.user);

        let gospCoverage = "Group OutPatient Specialist (GOSP) Benefits";
        let gospPlan = myDependentSelectMap.get(gospCoverage) || [];

        let index = gospPlan.findIndex(x => x.dependentType === dependent);
        let planObj = {
          planDep: "Opt Out",
          dependentType: dependent,
          optOutValue: 0,
          masterOptOut: masterOptedOut,
          coverageId: this.getCoverageIdByName(gospCoverage)
        };

        if (event.target.checked) {
          if (index === -1) {
            gospPlan.push(planObj);
          } else {
            gospPlan.splice(index, 1, planObj);
          }
        } else {
          gospPlan.splice(index, 1);
          showDependentBadge.set(gospCoverage, false);
        }

        if (dependent === "Spouse Only") {
          dependentOptOut.set(
            gospCoverage + "Spouse Only",
            event.target.checked
          );
        }
        if (dependent === "Child(ren) Only") {
          dependentOptOut.set(
            gospCoverage + "Child(ren) Only",
            event.target.checked
          );
        }

        if (
          myDependentSelectMap.get(gospCoverage).length ===
          this.state.planMatrix.get(gospCoverage)
        ) {
          let optOutCount = 0;
          myDependentSelectMap.get(gospCoverage).filter(planObj => {
            planObj.planDep === "Opt Out" ? optOutCount++ : optOutCount;
          });
          if (masterOptedOut) {
            optOutCount += spouseDisableCount;
            optOutCount += childrenDisableCount;
          }
          if (optOutCount === this.state.planMatrix.get(gospCoverage)) {
            mySelectMap.get(gospCoverage) &&
            mySelectMap.get(gospCoverage) !== "Opt Out"
              ? showDependentBadge.set(gospCoverage, true)
              : showDependentBadge.set(gospCoverage, false);
          } else {
            showDependentBadge.set(
              gospCoverage,
              event.target.checked ? true : false
            );
          }
        }
      }

      if (dependent === "Spouse Only") {
        dependentOptOut.set(coverage + "Spouse Only", event.target.checked);
      }
      if (dependent === "Child(ren) Only") {
        dependentOptOut.set(coverage + "Child(ren) Only", event.target.checked);
      }
      if (
        myDependentSelectMap.get(coverage).length ===
        this.state.planMatrix.get(coverage)
      ) {
        let optOutCount = 0;
        myDependentSelectMap.get(coverage).filter(planObj => {
          planObj.planDep === "Opt Out" ? optOutCount++ : optOutCount;
        });
        if (masterOptedOut) {
          optOutCount += spouseDisableCount;
          optOutCount += childrenDisableCount;
        }
        if (optOutCount === this.state.planMatrix.get(coverage)) {
          mySelectMap.get(coverage) && mySelectMap.get(coverage) !== "Opt Out"
            ? showDependentBadge.set(coverage, true)
            : showDependentBadge.set(coverage, false);
        } else {
          showDependentBadge.set(coverage, event.target.checked ? true : false);
        }
      }
    }
    if (type === "ALLIN") {
      let planObjList = [];
      planObjList = myDependentSelectMap.get(coverage) || [];
      let depPremiumSA = new Map(this.props.user.depPremiumSA);
      let index = planObjList.findIndex(
        x => x.dependentType.id === dependent.id
      );
      if (event.target.checked) {
        let planObj = { planDep: "Opt Out", dependentType: dependent };
        if (index === -1) {
          planObjList.push(planObj);
        } else {
          planObjList.splice(index, 1, planObj);
        }
        depPremiumSA.set(dependent.id, { premium: parseFloat(0.0).toFixed(2) });
        this.props.setDepSAandPremium(depPremiumSA);
        dependentOptOutName.set(dependent.id, event.target.checked);
        myDependentSelectMap.set(coverage, planObjList);
      } else {
        let planDep = mySelectMap.get(coverage);
        let planObj = { planDep: planDep, dependentType: dependent };
        if (index === -1) {
          planObjList.push(planObj);
        } else {
          planObjList.splice(index, 1, planObj);
        }
        dependentOptOutName.delete(dependent.id);
        myDependentSelectMap.set(coverage, planObjList);
        this.premiumCalculationDep(myDependentSelectMap);
      }

      if (
        myDependentSelectMap.get(coverage).length === dependentOptOutName.size
      ) {
        // showDependentBadge.set(coverage, false);
        // allow submitting empty case
        if (
          this.props.user.client_name.config.allowEmptySubmissionALLIN ===
          "True"
        ) {
          showDependentBadge.set(coverage, true);
        } else {
          showDependentBadge.set(coverage, false);
        }
      } else {
        showDependentBadge.set(coverage, true);
      }

      myDependentSelectMap = this.handleCardClickAllIn(
        myDependentSelectMap,
        mySelectMap.get(coverage),
        coverage
      );
    }

    this.setState(
      {
        [name]: event.target.checked,
        dependentOptOut: dependentOptOut,
        dependentOptOutName: dependentOptOutName,
        dependendentPlanSelected: myDependentSelectMap,
        planSelected: mySelectMap
      },
      () => {
        this.props.selectPlan(mySelectMap);
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculationDep(myDependentSelectMap)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(myDependentSelectMap, showDependentBadge)
            ? true
            : false
        );
        this.props.setDependentOptOut(dependentOptOut);
        this.props.setDependentOptOutName(dependentOptOutName);
        this.props.selectHDFValue(questionDepMap);
        this.props.selectHDFRemarks(selectedValue);
      }
    );
  };

  handleChangeEmployeeSwitch = (type, user, coverage) => event => {
    this.props.handleIsPlanChanged(true);
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    let isEmpHasSpouse = false;
    let pruAgencyPlanTypes = isPruAgencyEnrolment
      ? [
          "Group Term Life (TPD up to 65 YO, death benefit)",
          "Group Crisis Cover Accelerated (37 Critical illness)",
          "Group Pre-crisis Guard Accelerated (Early CI)"
        ]
      : event.target.checked
      ? [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits"
        ]
      : [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits",
          "Group Accidental Death & Dismemberment (GADD) Benefits"
        ];
    let questionDepMap = new Map(this.props.user.questionDepMap);
    let selectedValue = new Map(this.props.user.selectedValue);
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    let mySelectMap = new Map();

    let employeeOptOut = new Map();
    let dependentOptOutName = new Map();
    if (this.props.user.dependentOptOutName !== undefined) {
      dependentOptOutName = new Map(this.props.user.dependentOptOutName);
    }

    if (isPruAgencyEnrolment === true || isPruAgencyMedEnrolment === true) {
      if (this.props.user.dependencies.length > 0) {
        isEmpHasSpouse = true;
      }
    }

    if (
      !user.depRule[this.state.plans[0].coverageId]
        .independantDependantSelection === "true"
    ) {
      dependentOptOutName = new Map();
    } else {
      if (!event.target.checked) {
        dependentOptOutName = new Map();
      }
    }
    let employeeOptOutName = new Map();
    if (this.props.user.employeeOptOutName !== undefined) {
      employeeOptOutName = new Map(this.props.user.employeeOptOutName);
    }
    let dependentOptOut = new Map();
    if (this.props.user.dependentOptOut !== undefined) {
      dependentOptOut = new Map(this.props.user.dependentOptOut);
    }
    dependentOptOut = new Map();
    employeeOptOut = new Map();
    if (this.props.user.employeeOptOut !== undefined) {
      employeeOptOut = new Map(this.props.user.employeeOptOut);
    }
    let myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }

    mySelectMap = new Map();
    if (this.props.user.planSelected !== undefined) {
      mySelectMap = new Map(this.props.user.planSelected);
    }
    if (type === "name") {
      let planObj = mySelectMap.get(coverage) || "";
      let dependent = this.props.user.dependencies[0];
      let depPlanObjList = myDependentSelectMap.get(coverage) || [];
      let depPlanObj = { planDep: "Opt Out", dependentType: dependent };
      let hasDependent = false;
      let index;
      if (this.props.user.dependencies.length > 0) {
        index = depPlanObjList.findIndex(
          x => x.dependentType.id === dependent.id
        );
      }
      if (this.props.user.dependencies.length > 0) {
        hasDependent = true;
      }

      if (isPruAgencyEnrolment === true) {
        if (event.target.checked) {
          // Opt Out Employee
          planObj = "Opt Out";
          // Opt Out Dependent
          if (hasDependent === true) {
            if (index === -1) {
              depPlanObjList.push(depPlanObj);
            } else {
              depPlanObjList.splice(index, 1, depPlanObj);
            }
          }
        } else {
          planObj = "";
          if (hasDependent === true) {
            depPlanObjList.splice(index, 1);
          }
          showDependentBadge.set(coverage, false);
        }

        // myDependentSelectMap.set(coverage, depPlanObjList);
        for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
          if (coverage === pruAgencyPlanTypes[count]) {
            for (
              let count1 = count;
              count1 < pruAgencyPlanTypes.length;
              count1++
            ) {
              mySelectMap.set(pruAgencyPlanTypes[count1], planObj);
              if (hasDependent === true) {
                myDependentSelectMap.set(
                  pruAgencyPlanTypes[count1],
                  depPlanObjList
                );
              }

              if (
                mySelectMap.get(pruAgencyPlanTypes[count1]) &&
                mySelectMap.get(pruAgencyPlanTypes[count1]) === "Opt Out"
              ) {
                showDependentBadge.set(pruAgencyPlanTypes[count1], true);

                if (hasDependent !== true) {
                  if (
                    mySelectMap.get(pruAgencyPlanTypes[count1 - 1]) &&
                    mySelectMap.get(pruAgencyPlanTypes[count1 - 1]) ===
                      "Opt Out"
                  ) {
                    showDependentBadge.set(
                      pruAgencyPlanTypes[count1 - 1],
                      true
                    );
                  }
                }
              } else {
                showDependentBadge.set(pruAgencyPlanTypes[count1], false);
              }

              if (hasDependent === true) {
                if (
                  myDependentSelectMap.get(pruAgencyPlanTypes[count1 - 1]) &&
                  myDependentSelectMap.get(pruAgencyPlanTypes[count1 - 1])
                    .length === 0
                ) {
                  showDependentBadge.set(pruAgencyPlanTypes[count1 - 1], false);
                }

                dependentOptOutName.set(dependent.id, event.target.checked);
                let questionSet = new Map(questionDepMap.get(dependent.id));
                questionSet.clear();
                questionDepMap.set(dependent.id, questionSet);
                let remarksSet = new Map(selectedValue.get(dependent.id));
                remarksSet.clear();
                selectedValue.set(dependent.id, remarksSet);
              }
            }
          }
        }
      } else if (isPruAgencyMedEnrolment) {
        let dependentType;
        let hasSpouse = false;
        let hasChild = false;
        let hasDependent =
          this.props.user.dependencies.length > 0 ? true : false;

        this.props.user.dependencies.map(dependent => {
          if (dependent.dependent === "SPOUSE") {
            hasSpouse = true;
          } else if (dependent.dependent === "CHILD") {
            hasChild = true;
          }
        });

        if (hasSpouse && hasChild) {
          dependentType = "Spouse & Child(ren)";
        } else if (hasSpouse && !hasChild) {
          dependentType = "Spouse Only";
        } else if (!hasSpouse && hasChild) {
          dependentType = "Child(ren) Only";
        }

        if (event.target.checked) {
          // Opt Out Employee
          planObj = "Opt Out";
          //Opt Out Dependent
          if (hasDependent === true) {
            // if (index === -1) {
            //   depPlanObjList.splice(index, 1, depPlanObj);
            // } else {
            //   depPlanObjList.splice(index, 1, depPlanObj);
            // }
            depPlanObjList.splice(index, 1, depPlanObj);
          }
          showDependentBadge.set(coverage, false);
        } else {
          planObj = "";
          if (hasDependent === true) {
            depPlanObjList.splice(index, 1);
          }
          showDependentBadge.set(coverage, false);
        }
        for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
          if (coverage === pruAgencyPlanTypes[count]) {
            mySelectMap.set(pruAgencyPlanTypes[count], planObj);
            for (
              let count1 = count;
              count1 < pruAgencyPlanTypes.length;
              count1++
            ) {
              if (
                (pruAgencyPlanTypes[count1] ===
                  "Group Extended Major Medical Rider (GMM) Benefits" &&
                  this.props.user.coverage_details.standAloneGMM === true) ||
                pruAgencyPlanTypes[count1] ===
                  "Group Hospital & Surgical (GHS) Benefits"
              ) {
                if (event.target.checked) {
                  myDependentSelectMap.set(pruAgencyPlanTypes[count1], [
                    {
                      planDep: "Opt Out",
                      dependentType: hasDependent ? dependentType : []
                    }
                  ]);
                } else {
                  mySelectMap.set(pruAgencyPlanTypes[count1], "");
                  myDependentSelectMap.set(pruAgencyPlanTypes[count1], []);
                }
              } else {
                if (
                  pruAgencyPlanTypes[count1] ===
                  "Group Extended Major Medical Rider (GMM) Benefits"
                ) {
                  if (event.target.checked) {
                    myDependentSelectMap.set(pruAgencyPlanTypes[count1], [
                      {
                        planDep: "Opt Out",
                        dependentType: hasDependent ? dependentType : []
                      }
                    ]);
                  } else {
                    mySelectMap.set(pruAgencyPlanTypes[count1], "");
                    myDependentSelectMap.set(pruAgencyPlanTypes[count1], []);
                  }
                }
                mySelectMap.set(pruAgencyPlanTypes[count1], planObj);
                if (
                  hasDependent === true &&
                  pruAgencyPlanTypes[count1] !==
                    "Group Extended Major Medical Rider (GMM) Benefits"
                ) {
                  myDependentSelectMap.set(
                    pruAgencyPlanTypes[count1],
                    depPlanObjList
                  );
                }
                if (
                  mySelectMap &&
                  mySelectMap.has("Group Hospital & Surgical (GHS) Benefits") &&
                  mySelectMap.get(
                    "Group Hospital & Surgical (GHS) Benefits"
                  ) === "Opt Out"
                )
                  myDependentSelectMap.set(
                    "Group Accidental Death & Dismemberment (GADD) Benefits",
                    depPlanObjList
                  );
              }

              if (
                mySelectMap.get(pruAgencyPlanTypes[count1]) &&
                (mySelectMap.get(pruAgencyPlanTypes[count1]) === "Opt Out" ||
                  mySelectMap.get(pruAgencyPlanTypes[count1]) !== "")
              ) {
                showDependentBadge.set(pruAgencyPlanTypes[count1], true);
              } else {
                showDependentBadge.set(pruAgencyPlanTypes[count1], false);
              }

              if (hasDependent === true) {
                if (
                  myDependentSelectMap.get(pruAgencyPlanTypes[count1 - 1]) &&
                  myDependentSelectMap.get(pruAgencyPlanTypes[count1 - 1])
                    .length === 0
                ) {
                  showDependentBadge.set(pruAgencyPlanTypes[count1 - 1], false);
                }

                dependentOptOutName.set(dependent.id, event.target.checked);
                let questionSet = new Map(questionDepMap.get(dependent.id));
                questionSet.clear();
                questionDepMap.set(dependent.id, questionSet);
                let remarksSet = new Map(selectedValue.get(dependent.id));
                remarksSet.clear();
                selectedValue.set(dependent.id, remarksSet);
              }
            }
          }
        }

        if (
          coverage === "Group Accidental Death & Dismemberment (GADD) Benefits"
        ) {
          mySelectMap.set(coverage, planObj);
          if (hasDependent === true) {
            myDependentSelectMap.set(coverage, depPlanObjList);

            if (
              mySelectMap.get(coverage) !== "" &&
              myDependentSelectMap.get(coverage).length > 0
            ) {
              if (
                mySelectMap.get(coverage) !== "" &&
                myDependentSelectMap.get(coverage).length > 0
              ) {
                showDependentBadge.set(coverage, true);
              } else {
                showDependentBadge.set(coverage, false);
              }
            }
          }
        }
      } else {
        if (event.target.checked) {
          planObj = "Opt Out";
        } else {
          planObj = "";
          showDependentBadge.set(coverage, false);
        }
        mySelectMap.set(coverage, planObj);
      }

      if (
        coverage === "Group Accidental Death & Dismemberment (GADD) Benefits"
      ) {
        let hasDependent =
          this.props.user.dependencies.length > 0 ? true : false;
        if (
          !hasDependent &&
          mySelectMap &&
          mySelectMap.has(
            "Group Accidental Death & Dismemberment (GADD) Benefits"
          ) &&
          mySelectMap.get(
            "Group Accidental Death & Dismemberment (GADD) Benefits"
          ) !== ""
        ) {
          myDependentSelectMap.set(
            "Group Accidental Death & Dismemberment (GADD) Benefits",
            []
          );
          showDependentBadge.set(
            "Group Accidental Death & Dismemberment (GADD) Benefits",
            true
          );
        }
      }

      if (
        myDependentSelectMap.get(coverage) &&
        myDependentSelectMap.get(coverage).length ===
          this.state.planMatrix.get(coverage) &&
        mySelectMap.get(coverage) !== "" &&
        !this.state.isPruAgencyMedEnrolment
      ) {
        if (
          user.depRule[this.state.plans[0].coverageId]
            .independantDependantSelection === "true"
        ) {
          showDependentBadge.set(coverage, true);
        } else {
          showDependentBadge.set(coverage, false);
        }
      }

      if (showDependentBadge.get(coverage)) {
        if (
          user.depRule[this.state.plans[0].coverageId]
            .independantDependantSelection === "true"
        ) {
          if (
            this.props.user.dependencies.length === 0 ||
            this.dependentEnrolmentCheck(this.state.plans[0].coverageId) ===
              "false" ||
            this.spouseEnrolmentCheck(this.state.plans[0].coverageId) ===
              "false" ||
            this.childrenEnrolmentCheck(this.state.plans[0].coverageId) ===
              "false"
          ) {
            if (!isPruAgencyEnrolment || hasDependent === true) {
              showDependentBadge.set(coverage, false);
            }
          }
        }
      }

      if (
        user.depRule[this.state.plans[0].coverageId]
          .independantDependantSelection === "true"
      ) {
        // only clear the dependentSelectMap when the employee is opted in
        if (!event.target.checked) {
          myDependentSelectMap.set(coverage, "");
        }
      } else {
      }

      employeeOptOutName.set(user.id, event.target.checked);
      let questionSet = new Map(questionDepMap.get(user.id));
      questionSet.clear();
      questionDepMap.set(user.id, questionSet);
      // questionDepMap.delete(user.id);
      let remarksSet = new Map(selectedValue.get(user.id));
      remarksSet.clear();
      selectedValue.set(user.id, remarksSet);
      // selectedValue.delete(user.id);edValue.delete(user.id);
    } else if (type === "employeeONLY") {
      let planObj = mySelectMap.get(coverage) || "";
      if (event.target.checked) {
        planObj = "Opt Out";
        showDependentBadge.set(coverage, true);
      } else {
        planObj = "";
        showDependentBadge.set(coverage, false);
      }
      mySelectMap.set(coverage, planObj);
      myDependentSelectMap.set(coverage, "");
    }

    this.setState(
      {
        [name]: event.target.checked,
        dependentOptOut: dependentOptOut,
        dependentOptOutName: dependentOptOutName,
        dependendentPlanSelected: myDependentSelectMap,
        employeeOptOut: employeeOptOut,
        employeeOptOutName: employeeOptOutName,
        planSelected: mySelectMap
      },
      () => {
        this.props.selectPlan(mySelectMap);
        this.props.premiumCalculationEmp(this.premiumCalculation(mySelectMap));
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculationDep(myDependentSelectMap)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          isPruAgencyEnrolment
            ? isEmpHasSpouse
              ? this.handleNextOnDependent(
                  myDependentSelectMap,
                  showDependentBadge
                )
                ? true
                : false
              : this.handleNext(mySelectMap, showDependentBadge)
            : this.handleNextOnDependent(
                myDependentSelectMap,
                showDependentBadge
              )
        );
        this.props.setDependentOptOut(dependentOptOut);
        this.props.setEmployeeOptOut(employeeOptOut);
        this.props.setDependentOptOutName(dependentOptOutName);
        this.props.setEmployeeOptOutName(employeeOptOutName);
        this.props.selectHDFValue(questionDepMap);
        this.props.selectHDFRemarks(selectedValue);
      }
    );
  };

  handleExpandClick = key => event => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  getNumberOfChildren = () => {
    let numberOfChildren = this.props.user.dependencies.filter(
      x => x.dependent !== "SPOUSE"
    );
    return numberOfChildren.length;
  };

  handleCardClick = (plan, planHead) => {
    this.props.handleIsPlanChanged(true);
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let pruAgencyPlanTypes = isPruAgencyEnrolment
      ? [
          "Group Term Life (TPD up to 65 YO, death benefit)",
          "Group Crisis Cover Accelerated (37 Critical illness)",
          "Group Pre-crisis Guard Accelerated (Early CI)"
        ]
      : [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits"
        ];
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    let mySelectMap = new Map();
    if (this.props.user.planSelected !== undefined) {
      mySelectMap = new Map(this.props.user.planSelected);
    }
    mySelectMap.set(planHead.planType, plan);
    let myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }

    if (isPruAgencyEnrolment || this.state.isPruAgencyMedEnrolment) {
      for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
        if (planHead.planType === pruAgencyPlanTypes[count]) {
          for (let index = count; index < pruAgencyPlanTypes.length; index++) {
            // To clear child plan selection
            if (index + 1 < pruAgencyPlanTypes.length) {
              if (
                this.state.isPruAgencyMedEnrolment &&
                this.props.user.coverage_details.standAloneGMM === true &&
                pruAgencyPlanTypes[index + 1] ===
                  "Group Accidental Death & Dismemberment (GADD) Benefits" &&
                mySelectMap.get("Group Hospital & Surgical (GHS) Benefits") ===
                  "Opt Out"
              ) {
                mySelectMap.set(
                  "Group Hospital & Surgical (GHS) Benefits",
                  "Opt Out"
                );
                mySelectMap.set(pruAgencyPlanTypes[index + 1], "Opt Out");
                showDependentBadge.set(pruAgencyPlanTypes[index + 1], true);
              } else {
                if (
                  planHead.planType ===
                  "Group Accidental Death & Dismemberment (GADD) Benefits"
                ) {
                  myDependentSelectMap.set(
                    "Group Accidental Death & Dismemberment (GADD) Benefits",
                    []
                  );
                }
                mySelectMap.set(pruAgencyPlanTypes[index + 1], "");
                myDependentSelectMap.get(pruAgencyPlanTypes[index]) &&
                  myDependentSelectMap
                    .get(pruAgencyPlanTypes[index])
                    .filter(plan => {
                      if (plan.planDep !== "Opt Out") {
                        myDependentSelectMap.set(pruAgencyPlanTypes[index], []);
                        showDependentBadge.set(
                          pruAgencyPlanTypes[index],
                          false
                        );
                      }
                    });
              }
              myDependentSelectMap.get(pruAgencyPlanTypes[index + 1]) &&
                myDependentSelectMap
                  .get(pruAgencyPlanTypes[index + 1])
                  .filter(plan => {
                    if (plan.planDep !== "Opt Out") {
                      myDependentSelectMap.set(
                        pruAgencyPlanTypes[index + 1],
                        []
                      );
                      showDependentBadge.set(
                        pruAgencyPlanTypes[index + 1],
                        false
                      );
                    }
                  });
            }
            if (
              mySelectMap.get(pruAgencyPlanTypes[index]) &&
              myDependentSelectMap.get(pruAgencyPlanTypes[index])
            ) {
              myDependentSelectMap
                .get(pruAgencyPlanTypes[index])
                .filter(dependent => {
                  if (
                    mySelectMap.get(pruAgencyPlanTypes[index]) !== "" &&
                    dependent.planDep !== ""
                  ) {
                    showDependentBadge.set(pruAgencyPlanTypes[index], true);
                  } else {
                    showDependentBadge.set(pruAgencyPlanTypes[index], false);
                  }
                });
            } else {
              showDependentBadge.set(planHead.planType, false);
            }
          }
        }
      }
      let hasSpouse = false;
      this.props.user.dependencies.map(dependent => {
        if (dependent.dependent === "SPOUSE") {
          hasSpouse = true;
        }
      });
      if (
        planHead.planType ===
        "Group Accidental Death & Dismemberment (GADD) Benefits"
      ) {
        if (
          mySelectMap &&
          mySelectMap.get(planHead.planType) &&
          hasSpouse === false
        ) {
          showDependentBadge.set(planHead.planType, true);
        } else if (hasSpouse === true) {
          if (
            mySelectMap &&
            mySelectMap.has(planHead.planType) &&
            mySelectMap.get(planHead.planType) !== "" &&
            myDependentSelectMap.has(planHead.planType)
          ) {
            myDependentSelectMap.get(planHead.planType).filter(plan => {
              if (plan.planDep !== []) {
                showDependentBadge.set(planHead.planType, true);
              } else {
                showDependentBadge.set(planHead.planType, false);
              }
            });
          }
        }
      } else if (
        planHead.planType !==
        "Group Accidental Death & Dismemberment (GADD) Benefits"
      ) {
        if (
          mySelectMap &&
          mySelectMap.has(
            "Group Accidental Death & Dismemberment (GADD) Benefits"
          )
        ) {
          mySelectMap.set(
            "Group Accidental Death & Dismemberment (GADD) Benefits",
            ""
          );

          if (hasSpouse) {
            myDependentSelectMap.set(
              "Group Accidental Death & Dismemberment (GADD) Benefits",
              []
            );
          }

          showDependentBadge.set(
            "Group Accidental Death & Dismemberment (GADD) Benefits",
            false
          );
        }
      }

      if (
        this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
      ) {
        let pruAgencyMedPlanTypes = [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits",
          "Group Accidental Death & Dismemberment (GADD) Benefits"
        ];
        let hasDependent =
          this.props.user.dependencies.length > 0 ? true : false;

        for (let index = 0; index < pruAgencyMedPlanTypes.length; index++) {
          if (
            mySelectMap &&
            mySelectMap.has(pruAgencyMedPlanTypes[index]) &&
            hasDependent === false
          ) {
            if (mySelectMap.get(pruAgencyMedPlanTypes[index]) === "") {
              showDependentBadge.set(pruAgencyMedPlanTypes[index], false);
            } else {
              showDependentBadge.set(pruAgencyMedPlanTypes[index], true);
            }
          }
        }
      }

      if (this.props.user.client_name.config.isPruAgencyEnrolment === "True") {
        let hasDependent =
          this.props.user.dependencies.length > 0 ? true : false;
        for (let index = 0; index < pruAgencyPlanTypes.length; index++) {
          if (
            mySelectMap &&
            mySelectMap.has(pruAgencyPlanTypes[index]) &&
            hasDependent === false
          ) {
            if (mySelectMap.get(pruAgencyPlanTypes[index]) === "") {
              showDependentBadge.set(pruAgencyPlanTypes[index], false);
            } else {
              showDependentBadge.set(pruAgencyPlanTypes[index], true);
            }
          } else if (
            myDependentSelectMap &&
            myDependentSelectMap.has(pruAgencyPlanTypes[index]) &&
            hasDependent === true
          ) {
            if (
              mySelectMap.get(pruAgencyPlanTypes[index]) === "" ||
              myDependentSelectMap.get(pruAgencyPlanTypes[index]) === "" ||
              myDependentSelectMap.get(pruAgencyPlanTypes[index]) === []
            ) {
              showDependentBadge.set(pruAgencyPlanTypes[index], false);
            } else {
              showDependentBadge.set(pruAgencyPlanTypes[index], true);
            }
          }
        }
      }
    }
    // if independant selection enabled do not reset their selection
    if (
      this.props.user.depRule[planHead.coverageId]
        .independantDependantSelection !== "true"
    ) {
      myDependentSelectMap.set(planHead.planType, "");
      showDependentBadge.set(planHead.planType, false);
    }
    if (
      this.props.user.dependencies.length === 0 ||
      (this.props.user.dependencies.filter(
        dependent => dependent.dependent === "SPOUSE"
      ).length <= 0 &&
        this.props.user.dependencies.filter(
          dependent => dependent.dependent === "CHILD"
        ).length > 0) ||
      this.dependentEnrolmentCheck(planHead.coverageId) === "false" ||
      this.spouseEnrolmentCheck(planHead.coverageId) === "false" ||
      this.childrenEnrolmentCheck(planHead.coverageId) === "false"
    ) {
      myDependentSelectMap.set(planHead.planType, "");
      showDependentBadge.set(planHead.planType, true);
    }
    if (
      this.props.user.depRule[planHead.coverageId].selectionMethod ===
        "cluster" &&
      this.props.user.dependencies.length > 0 &&
      this.props.user.depRule[planHead.coverageId].childOnlyOnePlan == undefined
    ) {
      // override the spouse and child selection if it was cluster
      if (
        this.props.user.dependencies.filter(
          dependent => dependent.dependent === "SPOUSE"
        ).length > 0 &&
        this.props.user.dependencies.filter(
          dependent => dependent.dependent === "CHILD"
        ).length > 0
      ) {
        if (
          myDependentSelectMap.get(
            "Group Hospital & Surgical (GHS) Benefits"
          ) !== undefined &&
          myDependentSelectMap.get(
            "Group Hospital & Surgical (GHS) Benefits"
          ) !== "" &&
          myDependentSelectMap
            .get("Group Hospital & Surgical (GHS) Benefits")
            .filter(
              dependentSelection => dependentSelection.planDep === "Opt Out"
            ).length == 2
        ) {
          // do nothing
        } else {
          myDependentSelectMap.delete(planHead.planType);
          showDependentBadge.set(planHead.planType, false);
        }
      } else if (
        (this.props.user.dependencies.filter(
          dependent => dependent.dependent === "SPOUSE"
        ).length <= 0 &&
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "CHILD"
          ).length > 0) ||
        (this.props.user.dependencies.filter(
          dependent => dependent.dependent === "SPOUSE"
        ).length > 0 &&
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "CHILD"
          ).length <= 0)
      ) {
        // child/ spouse only case
        if (
          myDependentSelectMap.get(
            "Group Hospital & Surgical (GHS) Benefits"
          ) !== undefined &&
          myDependentSelectMap.get(
            "Group Hospital & Surgical (GHS) Benefits"
          ) !== "" &&
          myDependentSelectMap
            .get("Group Hospital & Surgical (GHS) Benefits")
            .filter(
              dependentSelection => dependentSelection.planDep === "Opt Out"
            ).length == 1
        ) {
          // do nothing
        } else {
          myDependentSelectMap.delete(planHead.planType);
          showDependentBadge.set(planHead.planType, false);
        }
      }
    }
    if (
      this.props.user.depRule[planHead.coverageId].selectionMethod ===
        "cluster" &&
      this.props.user.dependencies.length > 0 &&
      this.props.user.depRule[planHead.coverageId].childOnlyOnePlan !==
        undefined
    ) {
      // gadd case
      myDependentSelectMap.delete(planHead.planType);
      showDependentBadge.set(planHead.planType, false);
    }
    //master opt out check
    if (
      this.props.user.depRule[planHead.coverageId].masterOptOut !== undefined &&
      this.props.user.depRule[planHead.coverageId].masterOptOut
    ) {
      let categoryRule = this.props.user.client_name.config.categoryRules[
        this.props.user.category
      ];
      this.state.dependentOptOut.set(
        planHead.planType + "Child(ren) Only",
        false
      );
      this.state.dependentOptOut.set(planHead.planType + "Spouse Only", false);
      this.state.masterSpouse = false;
      this.state.masterChild = false;
      this.props.user.currentPlans.map(otherPlan => {
        if (otherPlan.coverageId != planHead.coverageId) {
          categoryRule[[otherPlan.coverageId]].spouseEnrole = "true";
          categoryRule[[otherPlan.coverageId]].childrenEnrole = "true";
        }
      });
    }
    // set auto select here
    // check if any hidden items pending to be address for
    if (this.props.user.depRule[plan.coverageId].autoEnrollRelation) {
      if (
        this.props.user.depRule[plan.coverageId].autoEnrollRelation &&
        this.props.user.dependencies.length > 0 &&
        !this.state.isPruAgencyMedEnrolment
      ) {
        let dependentAutoInfo = this.handleDependentCardClick(
          planHead.planType,
          plan,
          this.state.dependent
        );
        myDependentSelectMap = dependentAutoInfo[0];
        showDependentBadge = dependentAutoInfo[1];
      } else {
        if (this.props.user.dependencies.length > 0) {
          for (let count = 0; count < pruAgencyPlanTypes.length; count++) {
            if (
              pruAgencyPlanTypes[count] === planHead.planType &&
              pruAgencyPlanTypes[count] !==
                "Group Accidental Death & Dismemberment (GADD) Benefits"
            ) {
              if (
                pruAgencyPlanTypes[count] !==
                  "Group Hospital & Surgical (GHS) Benefits" &&
                !this.state.optOutDependent
              ) {
                let dependentAutoInfo = this.handleDependentCardClick(
                  planHead.planType,
                  plan,
                  this.state.dependent
                );
                myDependentSelectMap = dependentAutoInfo[0];
                showDependentBadge = dependentAutoInfo[1];
              } else if (
                pruAgencyPlanTypes[count] ===
                "Group Hospital & Surgical (GHS) Benefits"
              ) {
                let dependentAutoInfo = this.handleDependentCardClick(
                  planHead.planType,
                  plan,
                  this.state.dependent
                );
                myDependentSelectMap = dependentAutoInfo[0];
                showDependentBadge = dependentAutoInfo[1];
              } else if (
                planHead.planType ===
                "Group Extended Major Medical Rider (GMM) Benefits"
              ) {
                if (
                  mySelectMap &&
                  mySelectMap.get("Group Hospital & Surgical (GHS) Benefits") &&
                  mySelectMap.get(
                    "Group Hospital & Surgical (GHS) Benefits"
                  ) !== "Opt Out"
                ) {
                  if (
                    myDependentSelectMap.has(
                      "Group Hospital & Surgical (GHS) Benefits"
                    )
                  ) {
                    let employeeSelectedPlan = plan;
                    myDependentSelectMap
                      .get("Group Hospital & Surgical (GHS) Benefits")
                      ?.filter(plan => {
                        if (plan.planDep !== "Opt Out") {
                          let dependentAutoInfo = this.handleDependentCardClick(
                            planHead.planType,
                            employeeSelectedPlan,
                            this.state.dependent
                          );
                          myDependentSelectMap = dependentAutoInfo[0];
                          showDependentBadge = dependentAutoInfo[1];
                        } else {
                          let planObj1 = {
                            planDep: "Opt Out",
                            dependentType: this.state.dependent
                          };
                          myDependentSelectMap.set(
                            "Group Extended Major Medical Rider (GMM) Benefits",
                            [planObj1]
                          );
                          showDependentBadge.set(
                            pruAgencyPlanTypes[count],
                            true
                          );
                        }
                      });
                  }
                }
              }
            }
          }
        }
      }
    }
    if (
      mySelectMap &&
      mySelectMap.has(
        "Group Accidental Death & Dismemberment (GADD) Benefits"
      ) &&
      mySelectMap.get(
        "Group Accidental Death & Dismemberment (GADD) Benefits"
      ) === ""
    ) {
      showDependentBadge.set(
        "Group Accidental Death & Dismemberment (GADD) Benefits",
        false
      );
    }

    let hasSpouse = false;

    this.props.user.dependencies &&
      this.props.user.dependencies.map(dependent => {
        if (dependent.dependent === "SPOUSE") {
          hasSpouse = true;
        }
      });

    if (
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True" &&
      hasSpouse === true
    ) {
      myDependentSelectMap.set(
        "Group Accidental Death & Dismemberment (GADD) Benefits",
        []
      );
      showDependentBadge.set(
        "Group Accidental Death & Dismemberment (GADD) Benefits",
        false
      );
    }

    let dependentOptOutName = new Map();
    if (this.props.user.dependentOptOutName !== undefined) {
      dependentOptOutName = new Map(this.props.user.dependentOptOutName);
    }
    dependentOptOutName = new Map();
    this.setState(
      {
        ...this.state,
        planSelected: mySelectMap,
        dependendentPlanSelected: myDependentSelectMap,
        showDependentBadge: showDependentBadge,
        dependentOptOutName: dependentOptOutName
      },
      () => {
        this.props.selectPlan(mySelectMap);
        this.props.premiumCalculationEmp(this.premiumCalculation(mySelectMap));
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(
            myDependentSelectMap,
            showDependentBadge
          ) === true
            ? true
            : false
        );
        this.props.premiumCalculationDep(
          this.premiumCalculationDep(myDependentSelectMap)
        );
        this.props.setDependentOptOutName(dependentOptOutName);
      }
    );
  };

  handleCardClickAllIn = (selectNew, planDep, coverageDep) => {
    let tempMap = { "001": "004", "002": "005", "006": "009", "007": "010" };
    let reverseTempMap = {
      "004": "001",
      "005": "002",
      "009": "006",
      "010": "007"
    };
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    let mySelectMap = new Map();
    if (this.props.user.planSelected !== undefined) {
      mySelectMap = new Map(this.props.user.planSelected);
    }
    let myDependentSelectMap = new Map();
    let dependentOptOutName = new Map(this.props.user.dependentOptOutName);
    let planObjList = [];
    let newDependentSelectMap = new Map();

    if (selectNew === "Select") {
      mySelectMap.set(coverageDep, planDep);
      myDependentSelectMap = new Map();
      if (this.props.user.planSelectedDependent !== undefined) {
        myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
      }

      this.props.user.dependencies.map((dependentType, key) => {
        let planObj = { planDep: planDep, dependentType: dependentType };
        planObjList.push(planObj);
        myDependentSelectMap.set(coverageDep, planObjList);
      });
      if (
        myDependentSelectMap.get(coverageDep)?.length ===
        this.state.planMatrix.get(coverageDep)
      ) {
        showDependentBadge.set(coverageDep, true);
      }
      dependentOptOutName = new Map();
    } else {
      myDependentSelectMap = selectNew;
    }

    const selectedDependant = myDependentSelectMap.get(coverageDep);

    if (selectedDependant) {
      selectedDependant
        .filter(tempPlans => tempPlans.planDep != "Opt Out")
        .sort(
          (a, b) =>
            b.dependentType.dependent.localeCompare(
              a.dependentType.dependent
            ) ||
            a.dependentType.full_name.localeCompare(b.dependentType.full_name)
        )
        .map((plans, key) => {
          let planObj = {};
          if (key > 3) {
            let planDepNew = {};
            planDepNew.option = tempMap[plans.planDep.option]
              ? tempMap[plans.planDep.option]
              : plans.planDep.option;
            planDepNew.coverageId = plans.planDep.coverageId;
            planDepNew.header = plans.planDep.header;
            planDepNew.details = plans.planDep.details;
            planObj = {
              planDep: planDepNew,
              dependentType: plans.dependentType
            };
          } else {
            let planDepNew = {};

            planDepNew.option = reverseTempMap[plans.planDep.option]
              ? reverseTempMap[plans.planDep.option]
              : plans.planDep.option;
            planDepNew.coverageId = plans.planDep.coverageId;
            planDepNew.header = plans.planDep.header;
            planDepNew.details = plans.planDep.details;
            planObj = {
              planDep: planDepNew,
              dependentType: plans.dependentType
            };
          }

          let index = planObjList.findIndex(
            x => x.dependentType === plans.dependentType
          );
          let depRelation = planObjList.find(
            x => x.dependentType === "Dependants"
          );
          if (depRelation && depRelation.dependentType === "Dependants") {
            planObjList.splice(index, 1, planObj);
          } else if (index === -1) {
            planObjList.push(planObj);
          } else {
            planObjList.splice(index, 1, planObj);
          }
        });

      selectedDependant
        .filter(tempPlans => tempPlans.planDep === "Opt Out")
        .map((plans, key) => {
          let planObj = {
            planDep: "Opt Out",
            dependentType: plans.dependentType
          };
          let index = planObjList.findIndex(
            x => x.dependentType === plans.dependentType
          );
          let depRelation = planObjList.find(
            x => x.dependentType === "Dependants"
          );
          if (depRelation && depRelation.dependentType === "Dependants") {
            planObjList.splice(index, 1, planObj);
          } else if (index === -1) {
            planObjList.push(planObj);
          } else {
            planObjList.splice(index, 1, planObj);
          }
        });
    }

    newDependentSelectMap.set(coverageDep, planObjList);

    if (selectNew != "Select") {
      return newDependentSelectMap;
    }

    this.setState(
      {
        ...this.state,
        planSelected: mySelectMap,
        dependendentPlanSelected: newDependentSelectMap,
        showDependentBadge: showDependentBadge,
        dependentOptOutName: dependentOptOutName
      },
      () => {
        this.props.selectPlan(mySelectMap);
        this.props.selectDependentPlan(newDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculationDep(newDependentSelectMap)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(newDependentSelectMap, showDependentBadge)
            ? true
            : false
        );
        this.props.setDependentOptOutName(dependentOptOutName);
      }
    );
  };

  handleDependentCardClick = (coverageDep, planDep, dependentType) => {
    this.props.handleIsPlanChanged(true);
    // debugger;
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    const planType = ["VTLB", "VCCA", "VECI"];
    const planName = isPruAgencyEnrolment
      ? [
          "Group Term Life (TPD up to 65 YO, death benefit)",
          "Group Crisis Cover Accelerated (37 Critical illness)",
          "Group Pre-crisis Guard Accelerated (Early CI)"
        ]
      : [
          "Group Hospital & Surgical (GHS) Benefits",
          "Group Extended Major Medical Rider (GMM) Benefits",
          "Group Accidental Death & Dismemberment (GADD) Benefits"
        ];
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    let myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }
    let mySelectMap = new Map();
    if (this.props.user.planSelected !== undefined) {
      mySelectMap = new Map(this.props.user.planSelected);
    }

    let planObjList = [];
    planObjList = myDependentSelectMap.get(coverageDep) || [];
    let planObj = { planDep: planDep, dependentType: dependentType };
    let index = planObjList.findIndex(x => x.dependentType === dependentType);
    let depRelation = planObjList.find(x => x.dependentType === "Dependants");
    if (depRelation && depRelation.dependentType === "Dependants") {
      planObjList.splice(index, 1, planObj);
    } else if (index === -1) {
      if (isPruAgencyEnrolment === true || this.state.isPruAgencyMedEnrolment) {
        for (let count = 0; count < planName.length; count++) {
          if (coverageDep === planName[count]) {
            planObjList.push(planObj);
            if (count + 1 < planName.length) {
              myDependentSelectMap.set(planName[count + 1], []);
            }
          }
        }
      } else {
        planObjList.push(planObj);
      }
    } else {
      planObjList.splice(index, 1, planObj);
    }
    myDependentSelectMap.set(coverageDep, planObjList);
    if (isPruAgencyEnrolment) {
      for (let count = 0; count < planName.length; count++) {
        if (coverageDep === planName[count]) {
          myDependentSelectMap.set(coverageDep, planObjList);
          for (let count1 = count + 1; count1 < planName.length; count1++) {
            if (
              count1 < planName.length &&
              mySelectMap.get(planName[count1]) !== ""
            ) {
              mySelectMap.set(planName[count1], "");
              myDependentSelectMap.set(planName[count1], []);
              showDependentBadge.set(planName[count1], false);
            }
          }
        }
      }
    } else if (this.state.isPruAgencyMedEnrolment) {
      for (let count = 0; count < planName.length; count++) {
        if (coverageDep === planName[count]) {
          if (
            myDependentSelectMap &&
            myDependentSelectMap.get(planName[count - 1]) &&
            myDependentSelectMap.get(planName[count - 1]).planDep === "Opt Out"
          ) {
            planObj = { planDep: "Opt Out", dependentType: dependentType };
            myDependentSelectMap.set(coverageDep, planObjList);

            if (count + 1 < 3) {
              myDependentSelectMap.set(planName[count + 1], planObjList);
            }
          }

          for (let count1 = count + 1; count1 < planName.length; count1++) {
            if (
              count1 < planName.length &&
              count1 + 1 < planName.length &&
              mySelectMap.get(planName[count1]) !== ""
            ) {
              mySelectMap.set(planName[count1], "");
              myDependentSelectMap.set(planName[count1], []);
              showDependentBadge.set(planName[count1], false);
            }
          }
        }

        if (
          mySelectMap &&
          mySelectMap.get(planName[count]) &&
          mySelectMap.get(planName[count]) !== "" &&
          myDependentSelectMap &&
          myDependentSelectMap.get(planName[count]) &&
          myDependentSelectMap.get(planName[count]).planDep !== ""
        ) {
          showDependentBadge.set(planName[count], true);
        }
      }
    } else {
      myDependentSelectMap.set(coverageDep, planObjList);
    }

    if (
      myDependentSelectMap.get(coverageDep).length ===
      this.state.planMatrix.get(coverageDep)
    ) {
      showDependentBadge.set(coverageDep, true);
    }
    if (
      this.props.user.depRule[planDep.coverageId].autoEnrollRelation &&
      this.props.user.dependencies.length > 0
    ) {
      // manually set dependent badge to true
      showDependentBadge.set(coverageDep, true);
      // this.state.planMatrix.set(coverageDep,true);
      return [myDependentSelectMap, showDependentBadge];
    }
    if (this.props.user.depRule[planDep.coverageId].childFollowSpouse) {
      // if the plan is chil;d follow spouse clear child selection whenver spouse is being selected
      if (planObj.dependentType === "Spouse Only") {
        let childIndex = planObjList.findIndex(
          x => x.dependentType === "Child(ren) Only"
        );
        if (childIndex > -1) {
          if (planObjList[childIndex].planDep !== "Opt Out") {
            planObjList.splice(childIndex, 1);
            showDependentBadge.set(coverageDep, false);
          }
        }
      }
    }
    this.setState(
      {
        ...this.state,
        planSelected: mySelectMap,
        dependendentPlanSelected: myDependentSelectMap,
        showDependentBadge: showDependentBadge
      },
      () => {
        this.props.selectDependentPlan(myDependentSelectMap);
        this.props.premiumCalculationDep(
          this.premiumCalculationDep(myDependentSelectMap)
        );
        this.props.handleBadgeOnDependent(showDependentBadge);
        this.props.handleNextEnable(
          this.handleNextOnDependent(myDependentSelectMap, showDependentBadge)
            ? true
            : false
        );
      }
    );
  };

  getCoverageIdByName = coverageName => {
    return this.state.plans.find(plan => plan.planType === coverageName)
      .coverageId;
  };

  dependentEnrolmentCheck = coverage => {
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules =
      categoryRules && categoryRules[coverage].dependentEnrole;
    return coverageRules;
  };

  spouseEnrolmentCheck = coverage => {
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules = categoryRules && categoryRules[coverage].spouseEnrole;
    return coverageRules;
  };

  childrenEnrolmentCheck = coverage => {
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules = categoryRules && categoryRules[coverage].childrenEnrole;
    return coverageRules;
  };

  employeeEnrolmentCheck = coverage => {
    let categoryRules =
      this.props.user.category === null
        ? this.props.user.client_name.config.categoryRules["All Employees"]
        : this.props.user.client_name.config.categoryRules[
            this.props.user.category
          ];
    let coverageRules = categoryRules && categoryRules[coverage];
    return coverageRules;
  };

  premiumCalculation = planSelected => {
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let pruAgencyPlanTypes = [
      "Group Hospital & Surgical (GHS) Benefits",
      "Group Extended Major Medical Rider (GMM) Benefits",
      "Group Accidental Death & Dismemberment (GADD) Benefits"
    ];

    let sumPremium = "";
    let coverageName;
    let categorySub = 0;
    let tempPremium = 0;
    let tempData = [];
    let occupationClass = this.props.occupationToClassValue(
      this.props.user.occupation
    );
    let empPremiumSA1 = new Map(this.props.user.empPremiumSA);
    planSelected.forEach((value, key, map) => {
      let isVerticalPlan = this.state.plans.find(plan => plan.planType === key);
      if (isVerticalPlan && isVerticalPlan.isVertical === "true") {
        if (value === "Opt Out" || value === "") return sumPremium;
        var a = moment(this.props.user.dob, "D/M/YYYY");
        var b = moment();
        if (
          this.props.user.client_name.config.dobValidationByPolicyStartDate ===
          "true"
        ) {
          b = moment(
            this.props.user.client_name.policy_start_date,
            "YYYY-MM-DD"
          );
        }
        let diffDays = b.diff(a, "years");
        let diffYears = b.diff(a, "years");

        // Pru Agency Enrolment
        if (isPruAgencyEnrolment === true) {
          let user = this.props.user;
          let gender = this.props.user.gender;
          this.state.rateMatrics.map(rateMatric => {
            if (Array.isArray(rateMatric.details)) {
              rateMatric.details.map(detail => {
                if (detail.coverageId === value.coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let previousPlan = user.coverage_details.previous;
                      let rate =
                        gender === "M" ? rule.maleRate : rule.femaleRate;
                      let SA = "0";
                      let healthLoading = "0";
                      let existingSumAssured = "0";

                      if (value.header === "Existing") {
                        previousPlan.map(plan => {
                          if (plan.code === value.coverageId) {
                            existingSumAssured = plan.sum_assured;
                            SA = plan.sum_assured;
                            healthLoading = plan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                          }
                        });
                      } else if (value.header === "VECI") {
                        previousPlan.map(plan => {
                          if (plan.code === "VCCA") {
                            existingSumAssured = plan.sum_assured;
                            SA = plan.sum_assured;
                          }
                        });
                      } else {
                        previousPlan.map(plan => {
                          if (value.coverageId === plan.code) {
                            existingSumAssured = plan.sum_assured;
                            healthLoading = plan.extra_premium.replace(
                              /,/g,
                              ""
                            );
                          }
                        });
                        SA = value.details[0].overview.details[0].details[0];
                      }

                      if (value.header === "VECI") {
                        sumPremium =
                          parseFloat(healthLoading) +
                          ((((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            0.3) /
                            1000) *
                            parseFloat(rate).toFixed(2)) /
                            365) *
                            212;

                        SA = Number(SA.replace(/,/g, "")) * 0.3;
                      } else {
                        sumPremium =
                          parseFloat(healthLoading) +
                          (((parseFloat(SA.replace(/,/g, "")).toFixed(2) /
                            1000) *
                            parseFloat(rate).toFixed(2)) /
                            365) *
                            212;
                      }

                      SA = SA.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      tempPremium = tempPremium + sumPremium;
                      let premiumValue = new Map(
                        empPremiumSA1 && empPremiumSA1.get(key)
                      );
                      empPremiumSA1.set(this.props.user.id, {
                        SA: SA,
                        premium:
                          parseFloat(sumPremium).toFixed(2) -
                          parseFloat(healthLoading).toFixed(2)
                      });
                      premiumValue.set(this.props.user.id, {
                        SA: SA,
                        existingSumAssured: existingSumAssured
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        premium: parseFloat(
                          parseFloat(sumPremium).toFixed(2) -
                            parseFloat(healthLoading).toFixed(2)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        extraPremium: parseFloat(healthLoading)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      });
                      empPremiumSA1.set(key, premiumValue);
                      this.props.setSAandPremium(empPremiumSA1);
                    }
                    sumPremium = tempPremium;
                  });
                }
              });
            }
          });
        } else if (this.state.isPruAgencyMedEnrolment === true) {
          // Pru Agency Med Enrolment
          let empPremiumSA2 = new Map(this.props.user.empPremiumSA);

          if (
            this.state.planSelected.has(
              "Group Hospital & Surgical (GHS) Benefits"
            ) &&
            this.state.planSelected.get(
              "Group Hospital & Surgical (GHS) Benefits"
            ) === "Opt Out"
          ) {
            empPremiumSA1.delete("Group Hospital & Surgical (GHS) Benefits");
            empPremiumSA2.delete("Group Hospital & Surgical (GHS) Benefits");
          } else if (
            this.state.planSelected.has(
              "Group Extended Major Medical Rider (GMM) Benefits"
            ) &&
            this.state.planSelected.get(
              "Group Extended Major Medical Rider (GMM) Benefits"
            ) === "Opt Out"
          ) {
            empPremiumSA1.delete(
              "Group Extended Major Medical Rider (GMM) Benefits"
            );
            empPremiumSA2.delete(
              "Group Extended Major Medical Rider (GMM) Benefits"
            );
          }

          let ghsPlan =
            empPremiumSA2 &&
            empPremiumSA2.has("Group Hospital & Surgical (GHS) Benefits")
              ? new Map(
                  empPremiumSA2.get("Group Hospital & Surgical (GHS) Benefits")
                )
              : new Map();
          let ghsPremium =
            empPremiumSA2 &&
            empPremiumSA2.has("Group Hospital & Surgical (GHS) Benefits")
              ? ghsPlan.get(this.props.user.id).premium
              : parseFloat(0).toFixed(2);
          let gmmPlan =
            empPremiumSA2 &&
            empPremiumSA2.has(
              "Group Extended Major Medical Rider (GMM) Benefits"
            )
              ? new Map(
                  empPremiumSA2.get(
                    "Group Extended Major Medical Rider (GMM) Benefits"
                  )
                )
              : new Map();
          let gmmPremium =
            empPremiumSA2 &&
            empPremiumSA2.has(
              "Group Extended Major Medical Rider (GMM) Benefits"
            )
              ? parseFloat(gmmPlan.get(this.props.user.id).premium).toFixed(2)
              : parseFloat(0).toFixed(2);
          let user = this.props.user;
          this.state.rateMatrics.map(rateMatric => {
            if (Array.isArray(rateMatric.details)) {
              rateMatric.details.map(detail => {
                if (detail.coverageId === value.coverageId) {
                  detail.rules.map(rule => {
                    if (
                      rule.ageBand[0] <= diffDays &&
                      diffDays <= rule.ageBand[1]
                    ) {
                      let previousPlan = user.coverage_details.previous;
                      let rate = rule.rate;
                      let SA = "0";
                      let existingSumAssured = "0";

                      if (value.header === "Existing") {
                        previousPlan.map(plan => {
                          if (plan.code === value.coverageId) {
                            existingSumAssured = plan.sum_assured;
                            SA = plan.sum_assured;
                          }
                        });
                      } else {
                        previousPlan.map(plan => {
                          if (plan.code === value.coverageId) {
                            existingSumAssured = plan.sum_assured;
                          }
                        });
                        SA = value.details[0].overview.details[0].details[0];
                      }

                      sumPremium =
                        (parseFloat(SA.replace(/,/g, "")).toFixed(2) * rate) /
                        1000;

                      for (let index = 0; index < 2; index++) {
                        this.state.dependentPlans &&
                          this.state.dependentPlans.map(premiumPlans => {
                            coverageName = pruAgencyPlanTypes[index];
                            if (
                              this.state.planSelected.get(coverageName) &&
                              premiumPlans.option ===
                                this.state.planSelected.get(coverageName).option
                            ) {
                              let premiumDetails = premiumPlans.details.find(
                                plan => plan.header === coverageName
                              );
                              let premiumDetailsSub =
                                premiumDetails &&
                                premiumDetails.details.find(
                                  obj => obj.header === "Employee"
                                );
                              let subsidy =
                                premiumDetailsSub && premiumDetailsSub.subsidy;
                              let categorySubTemp = this.getCategorySubsidy(
                                this.getCoverageIdByName(coverageName)
                              );
                              let tempSumPremium = 0;
                              if (Array.isArray(premiumDetails.multiplier)) {
                                for (
                                  let count = 0;
                                  count < premiumDetails.multiplier.length;
                                  count++
                                ) {
                                  let tempPremium =
                                    premiumDetailsSub.premium[count] *
                                      premiumDetails.multiplier[count] -
                                    premiumDetailsSub.subsidy;
                                  tempSumPremium = tempSumPremium + tempPremium;
                                }
                              } else {
                                let tempPremium =
                                  diffYears >= 71 &&
                                  this.state.isPruAgencyMedEnrolment
                                    ? (premiumDetailsSub &&
                                        premiumDetailsSub.premium71 *
                                          premiumDetails.multiplier) - subsidy
                                    : (premiumDetailsSub &&
                                        premiumDetailsSub.premium *
                                          premiumDetails.multiplier) - subsidy;
                                tempSumPremium = tempSumPremium + tempPremium;
                              }
                              tempSumPremium = tempSumPremium - categorySubTemp;
                              sumPremium = Number(sumPremium) + tempSumPremium;
                            }
                          });
                      }

                      tempPremium = tempPremium + sumPremium;
                      let premiumValue = new Map(
                        empPremiumSA1 && empPremiumSA1.get(key)
                      );
                      // empPremiumSA1.set(this.props.user.id, {
                      //   SA: SA,
                      //   premium: sumPremium
                      // });
                      premiumValue.set(this.props.user.id, {
                        SA: SA,
                        existingSumAssured: existingSumAssured
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        premium: (
                          parseFloat(sumPremium).toFixed(2) -
                          ghsPremium -
                          gmmPremium
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      });
                      empPremiumSA1.set(key, premiumValue);
                      this.props.setSAandPremium(empPremiumSA1);
                    }
                    if (
                      this.state.planSelected.get(
                        "Group Extended Major Medical Rider (GMM) Benefits"
                      ) !== "Opt Out"
                    ) {
                      sumPremium = tempPremium - gmmPremium;
                    } else {
                      sumPremium = tempPremium - ghsPremium - gmmPremium;
                    }
                  });
                }
              });
            }
          });
        } else {
          // UOB Enrolment
          this.state.rateMatrics.map(rateMatric => {
            if (
              isPruAgencyEnrolment !== true &&
              rateMatric.value === occupationClass.value
            ) {
              rateMatric.details.map(detail => {
                detail.rules.map(rule => {
                  if (
                    rule.ageBand[0] <= diffDays &&
                    diffDays <= rule.ageBand[1]
                  ) {
                    let rate = rule.employeeRate;
                    let SA = value.details[0].overview.details[0].details[0];
                    sumPremium =
                      (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                        parseFloat(rate).toFixed(2)) /
                      1000;
                    let empPremiumSA = new Map(this.props.user.empPremiumSA);
                    let premiumValue = new Map(
                      empPremiumSA && empPremiumSA.get(key)
                    );
                    empPremiumSA.set(this.props.user.id, {
                      SA: SA,
                      premium: parseFloat(sumPremium).toFixed(2)
                    });
                    premiumValue.set(this.props.user.id, {
                      SA: SA,
                      premium: parseFloat(sumPremium).toFixed(2)
                    });
                    empPremiumSA.set(key, premiumValue);
                    this.props.setSAandPremium(empPremiumSA);
                  }
                });
              });
            }
          });
        }
      } else {
        let isPruAgencyMedEnrolment =
          this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
            ? true
            : false;
        var a = moment(this.props.user.dob, "DD/MM/YYYY");
        var b = moment();
        if (
          this.props.user.client_name.config.dobValidationByPolicyStartDate ===
          "true"
        ) {
          b = moment(
            this.props.user.client_name.policy_start_date,
            "YYYY-MM-DD"
          );
        }
        let diffYears = b.diff(a, "years");

        this.state.dependentPlans &&
          this.state.dependentPlans.map(premiumPlans => {
            coverageName = key;
            const isGccePlan =
              coverageName === "Group Crisis Cover Extra (GCCE) Benefits"
                ? true
                : false;

            if (premiumPlans.option === value.option) {
              let premiumDetails = premiumPlans.details.find(
                plan => plan.header === key
              );
              let premiumDetailsSub =
                premiumDetails &&
                premiumDetails.details.find(obj => obj.header === "Employee");
              let subsidy = premiumDetailsSub && premiumDetailsSub.subsidy;
              let categorySubTemp = this.getCategorySubsidy(
                this.getCoverageIdByName(coverageName)
              );
              let tempSumPremium = 0;
              let geecHealthLoading = "0";
              let geecExistingSumAssured = "0";
              if (Array.isArray(premiumDetails.multiplier)) {
                for (
                  let count = 0;
                  count < premiumDetails.multiplier.length;
                  count++
                ) {
                  let tempPremium =
                    premiumDetailsSub.premium[count] *
                      premiumDetails.multiplier[count] -
                    premiumDetailsSub.subsidy;
                  tempSumPremium = tempSumPremium + tempPremium;
                }
              } else {
                if (diffYears >= 71) {
                  tempPremium =
                    (premiumDetailsSub &&
                      premiumDetailsSub.premium71 * premiumDetails.multiplier) -
                    subsidy;
                  tempSumPremium = tempSumPremium + tempPremium;
                } else if (isGccePlan) {
                  // to calculate GCCE premium here
                  var a = moment(this.props.user.dob, "D/M/YYYY");
                  var b = moment();
                  if (
                    this.props.user.client_name.config
                      .dobValidationByPolicyStartDate === "true"
                  ) {
                    b = moment(
                      this.props.user.client_name.policy_start_date,
                      "YYYY-MM-DD"
                    );
                  }
                  let diffDays = b.diff(a, "years");

                  this.state.rateMatrics.map(rateMatric => {
                    rateMatric.details.map(detail => {
                      if (detail.header === coverageName) {
                        detail.rules.map(rule => {
                          if (
                            rule.ageBand[0] <= diffDays &&
                            diffDays <= rule.ageBand[1]
                          ) {
                            let rate = rule.rate;
                            let SA = "25000";
                            let previousPlan = this.props.user.coverage_details
                              .previous;
                            let isExistingGcceInsured = false;

                            if (previousPlan) {
                              previousPlan.map(prevPlan => {
                                if (prevPlan.code === "GCCE") {
                                  geecExistingSumAssured = prevPlan.sum_assured;
                                  geecHealthLoading = prevPlan.extra_premium;
                                  isExistingGcceInsured = true;
                                }
                              });
                            }

                            tempPremium = isExistingGcceInsured
                              ? (parseFloat(rate) / 1000) * parseFloat(SA) +
                                parseFloat(geecHealthLoading)
                              : 0;

                            tempSumPremium = tempSumPremium + tempPremium;
                          }
                        });
                      }
                    });
                  });
                } else {
                  const validCategories = ["Associate1", "Associate2"];
                  const isValidCategory = validCategories.includes(
                    this.props.user.category
                  );
                  let additionalPremium =
                    isValidCategory &&
                    premiumDetails.additionalPremium !== undefined
                      ? premiumDetails.additionalPremium
                      : 0.0;

                  tempPremium =
                    (premiumDetailsSub &&
                      premiumDetailsSub.premium * premiumDetails.multiplier) -
                    subsidy -
                    additionalPremium;
                  tempSumPremium = tempSumPremium + tempPremium;
                }
              }
              tempSumPremium = tempSumPremium - categorySubTemp;
              sumPremium = Number(sumPremium) + tempSumPremium;
              let empPremiumSA = new Map(this.props.user.empPremiumSA);
              let premiumValue = new Map(empPremiumSA && empPremiumSA.get(key));

              if (isPruAgencyMedEnrolment) {
                premiumValue.set(this.props.user.id, {
                  premium: parseFloat(tempSumPremium).toFixed(2),
                  option: value.option
                });
              } else if (isGccePlan) {
                premiumValue.set(this.props.user.id, {
                  premium: parseFloat(sumPremium).toFixed(2),
                  option: value.option,
                  SA: "25000",
                  existingSumAssured: geecExistingSumAssured,
                  extraPremium: geecHealthLoading
                });
              } else {
                premiumValue.set(this.props.user.id, {
                  premium: parseFloat(sumPremium).toFixed(2),
                  option: value.option
                });
              }
              empPremiumSA.set(key, premiumValue);
              this.props.setSAandPremium(empPremiumSA);
            }
          });
      }
    });
    return Number(sumPremium);
  };

  premiumCalculationDep = myDependentSelectMap => {
    // debugger;
    let isPruAgencyEnrolment =
      this.props.user.client_name.config.isPruAgencyEnrolment === "True"
        ? true
        : false;
    let sumPremium = 0;
    let masterOptOutVal = 0;
    let spouseVal = 0;
    let childVal = 0;
    let tempPremium = "";
    let depPremiumSA = new Map(this.props.user.depPremiumSA);
    myDependentSelectMap.forEach((value, key, map) => {
      let isVerticalPlan = this.state.plans.find(plan => plan.planType === key);
      if (isVerticalPlan && isVerticalPlan.isVertical === "true") {
        value &&
          value.map(val => {
            if (val.planDep === "Opt Out") return sumPremium;
            var a = moment(val && val.dependentType.dob, "D/M/YYYY");
            var b = moment();
            if (
              this.props.user.client_name.config
                .dobValidationByPolicyStartDate === "true"
            ) {
              b = moment(
                this.props.user.client_name.policy_start_date,
                "YYYY-MM-DD"
              );
            }
            let diffDays = b.diff(a, "years");
            let occupationClass = this.props.occupationToClassValue(
              val.dependentType.occupation
            );
            let gender = val.dependentType.gender;
            this.state.rateMatrics.map(rateMatric => {
              if (isPruAgencyEnrolment === true) {
                let previousPlan = this.props.user.dependencies[0]
                  .coverage_details.previous;
                rateMatric.details.map(detail => {
                  if (detail.coverageId === val.planDep.coverageId) {
                    detail.rules.map(rule => {
                      if (
                        rule.ageBand[0] <= diffDays &&
                        diffDays <= rule.ageBand[1]
                      ) {
                        let spouse = this.props.user.dependencies[0];
                        let rate;
                        let SA = "0";
                        let healthLoading = "0";
                        let existingSumAssured = "0";
                        rate = gender === "M" ? rule.maleRate : rule.femaleRate;

                        if (val.planDep.header === "Existing") {
                          previousPlan.map(plan => {
                            if (plan.code === val.planDep.coverageId) {
                              SA = plan.sum_assured;
                              existingSumAssured = plan.sum_assured;
                              healthLoading = plan.extra_premium.replace(
                                /,/g,
                                ""
                              );
                            }
                          });
                        } else if (val.planDep.header === "VECI") {
                          previousPlan.map(plan => {
                            if (plan.code === "VCCA") {
                              SA = plan.sum_assured;
                              existingSumAssured = plan.sum_assured;
                            }
                          });
                        } else {
                          previousPlan.map(plan => {
                            if (val.planDep.coverageId === plan.code) {
                              existingSumAssured = plan.sum_assured;
                              healthLoading = plan.extra_premium.replace(
                                /,/g,
                                ""
                              );
                            }
                          });
                          SA =
                            val.planDep.details[0].overview.details[0]
                              .details[0];
                        }

                        if (val.planDep.header === "VECI") {
                          tempPremium =
                            parseFloat(healthLoading) +
                            ((((parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                              0.3) /
                              1000) *
                              parseFloat(rate).toFixed(2)) /
                              365) *
                              212;

                          SA = Number(SA.replace(/,/g, "")) * 0.3;
                        } else {
                          tempPremium =
                            parseFloat(healthLoading) +
                            (((parseFloat(SA.replace(/,/g, "")).toFixed(2) /
                              1000) *
                              parseFloat(rate).toFixed(2)) /
                              365) *
                              212;
                        }

                        SA = SA.toString().replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                        sumPremium = sumPremium + tempPremium;
                        let premiumValue = new Map(
                          depPremiumSA && depPremiumSA.get(key)
                        );
                        // depPremiumSA.set(val.dependentType.id,{SA:SA,premium:parseFloat(tempPremium).toFixed(2)})
                        premiumValue.set(val.dependentType.id, {
                          SA: SA,
                          existingSumAssured: existingSumAssured
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                          premium: parseFloat(
                            parseFloat(tempPremium).toFixed(2) -
                              parseFloat(healthLoading).toFixed(2)
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                          extraPremium: parseFloat(healthLoading)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        });
                        depPremiumSA.set(key, premiumValue);
                      }
                    });
                  }
                });
              } else if (this.state.isPruAgencyMedEnrolment === true) {
                // Pru Agency Med Enrolment
                rateMatric.details.map(detail => {
                  if (detail.coverageId === val.planDep.coverageId) {
                    detail.rules.map(rule => {
                      if (
                        rule.ageBand[0] <= diffDays &&
                        diffDays <= rule.ageBand[1]
                      ) {
                        let rate = rule.rate;
                        let SA = "0";
                        let existingSumAssured = "0";
                        if (val.planDep.header === "Existing") {
                          val.dependentType.coverage_details.previous.map(
                            plan => {
                              if (plan.code === val.planDep.coverageId) {
                                SA = plan.sum_assured;
                                existingSumAssured = plan.sum_assured;
                              }
                            }
                          );
                        } else {
                          SA =
                            val.planDep.details[0].overview.details[0]
                              .details[0];
                          val.dependentType.coverage_details.previous.map(
                            plan => {
                              if (plan.code === val.planDep.coverageId) {
                                existingSumAssured = plan.sum_assured;
                              }
                            }
                          );
                        }

                        tempPremium =
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) * rate) /
                          1000;
                        SA = SA.toString().replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                        sumPremium = sumPremium + tempPremium;
                        let premiumValue = new Map(
                          depPremiumSA && depPremiumSA.get(key)
                        );

                        premiumValue.set(val.dependentType.id, {
                          SA: SA,
                          existingSumAssured: existingSumAssured
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                          premium: parseFloat(
                            parseFloat(tempPremium).toFixed(2)
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        });
                        depPremiumSA.set(key, premiumValue);
                      }
                    });
                  }
                });
              } else {
                if (rateMatric.value === occupationClass.value) {
                  rateMatric.details.map(detail => {
                    detail.rules.map(rule => {
                      if (
                        rule.ageBand[0] <= diffDays &&
                        diffDays <= rule.ageBand[1]
                      ) {
                        let rate;
                        if (val.dependentType.dependent === "CHILD")
                          rate = rule.childRate;
                        if (val.dependentType.dependent === "SPOUSE")
                          rate = rule.spouseRate;
                        let SA =
                          val.planDep.details[0].overview.details[0].details[0];

                        let tempPremium =
                          (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                            parseFloat(rate).toFixed(2)) /
                          1000;
                        sumPremium = sumPremium + tempPremium;
                        let premiumValue = new Map(
                          depPremiumSA && depPremiumSA.get(key)
                        );
                        // depPremiumSA.set(val.dependentType.id,{SA:SA,premium:parseFloat(tempPremium).toFixed(2)})
                        premiumValue.set(val.dependentType.id, {
                          SA: SA,
                          premium: parseFloat(tempPremium).toFixed(2)
                        });
                        depPremiumSA.set(key, premiumValue);
                      }
                    });
                  });
                }
              }
            });
          });
        this.props.setDepSAandPremium(depPremiumSA);
      } else {
        let tempAltVal = 0;
        this.state.dependentPlans &&
          this.state.dependentPlans.map(premiumPlans => {
            value &&
              value.map(val => {
                let dob = this.props.user.dob;

                var a = moment(dob, "DD/MM/YYYY");
                var b = moment();
                if (
                  this.props.user.client_name.config
                    .dobValidationByPolicyStartDate === "true"
                ) {
                  b = moment(
                    this.props.user.client_name.policy_start_date,
                    "YYYY-MM-DD"
                  );
                }
                let diffYears = b.diff(a, "years");

                let categoryRule = this.props.user.client_name.config
                  .categoryRules[this.props.user.category];
                let nonMasterId = "";
                if (val.planDep === "Opt Out") {
                  if (val.optOutValue !== 0 && val.masterOptOut == false) {
                    nonMasterId = val.coverageId;
                    if (nonMasterId !== "") {
                      tempAltVal = parseFloat(
                        categoryRule[nonMasterId].dependentOptOutRates
                      );
                      if (
                        val.dependentType === "Spouse Only" &&
                        spouseVal == 0
                      ) {
                        spouseVal = parseFloat(tempAltVal);
                      }
                      if (
                        val.dependentType === "Child(ren) Only" &&
                        childVal == 0
                      ) {
                        childVal = parseFloat(tempAltVal);
                      }
                    }
                  }
                  if (val.masterOptOut) {
                    masterOptOutVal = parseFloat(val.optOutValue);
                    let premiumValue = new Map(
                      depPremiumSA && depPremiumSA.get(key)
                    );
                    if (val.dependentType === "Spouse Only") {
                      spouseVal = masterOptOutVal;
                    }
                    if (val.dependentType === "Child(ren) Only") {
                      childVal = masterOptOutVal;
                    }
                    if (premiumValue.get(val.dependentType) !== undefined) {
                      let currentPremium = parseFloat(
                        premiumValue.get(val.dependentType).premium
                      );
                      currentPremium = masterOptOutVal;
                      premiumValue.set(val.dependentType, {
                        premium: parseFloat(currentPremium).toFixed(2)
                      });
                      depPremiumSA.delete(key);
                      depPremiumSA.set(key, premiumValue);
                    } else {
                      premiumValue.set(val.dependentType, {
                        premium: parseFloat(masterOptOutVal).toFixed(2)
                      });
                      depPremiumSA.delete(key);
                      depPremiumSA.set(key, premiumValue);
                    }
                    if (
                      val.masterOptOut &&
                      this.state.masterChild &&
                      this.state.masterSpouse
                    ) {
                      let currentDep = new Map(
                        depPremiumSA && depPremiumSA.get(key)
                      );
                      if (currentDep.get("Spouse Only") !== undefined) {
                        spouseVal = currentDep.get("Spouse Only").premium;
                      }
                      if (currentDep.get("Child(ren) Only") !== undefined) {
                        childVal = currentDep.get("Child(ren) Only").premium;
                      }
                    } else if (val.masterOptOut && this.state.masterChild) {
                      let currentDep = new Map(
                        depPremiumSA && depPremiumSA.get(key)
                      );
                      if (currentDep.get("Child(ren) Only") !== undefined) {
                        childVal = currentDep.get("Child(ren) Only").premium;
                      }
                    } else if (val.masterOptOut && this.state.masterSpouse) {
                      let currentDep = new Map(
                        depPremiumSA && depPremiumSA.get(key)
                      );
                      if (currentDep.get("Spouse Only") !== undefined) {
                        spouseVal = currentDep.get("Spouse Only").premium;
                      }
                    }
                  }
                }
                if (premiumPlans.option === val.planDep.option) {
                  let tempPremium = 0;
                  let subsidy = 0;
                  let premiumDetails = premiumPlans.details.find(
                    plan => plan.header === key
                  );
                  if (val.dependentType && val.dependentType.id) {
                    if (Array.isArray(premiumDetails.multiplier)) {
                      let premiumDetailsSub =
                        premiumDetails &&
                        premiumDetails.details.find(
                          obj => obj.header === "Individual"
                        );
                      subsidy = premiumDetailsSub.subsidy;
                      for (
                        let count = 0;
                        count < premiumDetails.multiplier.length;
                        count++
                      ) {
                        let tempPremiumArr =
                          premiumDetailsSub.premium[count] *
                            premiumDetails.multiplier[count] -
                          subsidy;
                        tempPremium = tempPremium + tempPremiumArr;
                      }
                      let premiumValue = new Map(
                        depPremiumSA && depPremiumSA.get(key)
                      );
                      premiumValue.set(val.dependentType.id, {
                        premium: parseFloat(tempPremium).toFixed(2)
                      });
                      depPremiumSA.set(key, premiumValue);
                    } else {
                      let premiumDetailsSub =
                        premiumDetails &&
                        premiumDetails.details.find(
                          obj => obj.header === "Individual"
                        );
                      subsidy = premiumDetailsSub.subsidy;
                      tempPremium =
                        premiumDetailsSub.premium * premiumDetails.multiplier -
                        subsidy;
                      let premiumValue = new Map(
                        depPremiumSA && depPremiumSA.get(key)
                      );
                      premiumValue.set(val.dependentType.id, {
                        premium: parseFloat(tempPremium).toFixed(2)
                      });
                      depPremiumSA.set(key, premiumValue);
                    }
                  } else {
                    if (
                      premiumDetails !== undefined &&
                      Array.isArray(premiumDetails.multiplier)
                    ) {
                      let premiumDetailsSub =
                        premiumDetails &&
                        premiumDetails.details.find(
                          obj => obj.header === val.dependentType
                        );
                      subsidy = premiumDetailsSub.subsidy;
                      for (
                        let count = 0;
                        count < premiumDetails.multiplier.length;
                        count++
                      ) {
                        let tempPremiumArr =
                          premiumDetailsSub.premium[count] *
                            premiumDetails.multiplier[count] -
                          subsidy;
                        tempPremium = tempPremium + tempPremiumArr;
                      }
                      let premiumValue = new Map(
                        depPremiumSA && depPremiumSA.get(key)
                      );
                      premiumValue.set(val.dependentType, {
                        premium: parseFloat(tempPremium).toFixed(2)
                      });
                      depPremiumSA.delete(key);
                      depPremiumSA.set(key, new Map(premiumValue));
                    } else {
                      if (premiumDetails !== undefined) {
                        let premiumDetailsSub =
                          premiumDetails &&
                          premiumDetails.details.find(
                            obj => obj.header === val.dependentType
                          );
                        subsidy = premiumDetailsSub.subsidy;
                        this.props.user.currentPlans.map(currentPlan => {
                          if (
                            this.props.user.depRule[currentPlan.coverageId]
                              .categoryDeduction !== undefined &&
                            this.props.user.depRule[currentPlan.coverageId]
                              .categoryDeduction
                          ) {
                            let coverageName = this.state.dependentPlans[0]
                              .details[0].header;
                            if (premiumDetails.header == coverageName) {
                              let categorySubTemp = this.getCategorySubsidy(
                                this.getCoverageIdByName(coverageName)
                              );
                              if (subsidy == 0) {
                                subsidy = categorySubTemp;
                              }
                            }
                          }
                        });
                        if (
                          val.dependentType === "Child(ren) Only" &&
                          premiumDetails.header ===
                            "Group Accidental Death & Dismemberment/Group Personal Accident (GADD/GPA) Benefits"
                        ) {
                          tempPremium =
                            diffYears >= 71 &&
                            this.state.isPruAgencyMedEnrolment
                              ? (premiumDetailsSub.premium71 *
                                  premiumDetails.multiplier -
                                  subsidy) *
                                this.getNumberOfChildren()
                              : (premiumDetailsSub.premium *
                                  premiumDetails.multiplier -
                                  subsidy) *
                                this.getNumberOfChildren();
                        } else {
                          const validCategories = ["Associate1", "Associate2"];
                          const isValidCategory = validCategories.includes(
                            this.props.user.category
                          );
                          let additionalPremium =
                            isValidCategory &&
                            premiumDetails.additionalPremium !== undefined
                              ? premiumDetails.additionalPremium
                              : 0.0;

                          tempPremium =
                            diffYears >= 71 &&
                            this.state.isPruAgencyMedEnrolment
                              ? premiumDetailsSub.premium71 *
                                  premiumDetails.multiplier -
                                subsidy
                              : premiumDetailsSub.premium *
                                  premiumDetails.multiplier -
                                subsidy -
                                additionalPremium;
                        }
                        // tempPremium =
                        //   premiumDetailsSub.premium * premiumDetails.multiplier -
                        let premiumValue = new Map(
                          depPremiumSA && depPremiumSA.get(key)
                        );
                        premiumValue.set(val.dependentType, {
                          premium: parseFloat(tempPremium).toFixed(2)
                        });
                        depPremiumSA.delete(key);
                        depPremiumSA.set(key, new Map(premiumValue));
                      }
                    }
                  }
                  sumPremium = sumPremium + tempPremium;
                }
              });
          });
      }
    });
    sumPremium = sumPremium + parseFloat(spouseVal) + parseFloat(childVal);
    this.props.setDepSAandPremium(new Map(depPremiumSA));
    return sumPremium;
  };

  getCategorySubsidy = coverage => {
    let categotyPlans =
      this.props.user.client_name.config.categoryRules[
        this.props.user.category
      ] || this.props.user.client_name.config.categoryRules["All Employees"];
    let categorySubsidyArr = categotyPlans[coverage].subsidy;
    let categorySubsidy = 0;
    categorySubsidyArr.map(subArray => {
      categorySubsidy = categorySubsidy + parseInt(subArray); //tempSubsidy;
    });
    return categorySubsidy;
  };

  premiumInfo = (planHead, planSelected) => {
    const isGccePlan =
      planHead.planType === "Group Crisis Cover Extra (GCCE) Benefits"
        ? true
        : false;
    const isPruAgencyMedEnrolment =
      this.props.user.client_name.config.isPruAgencyMedEnrolment === "True"
        ? true
        : false;
    var a = moment(this.props.user.dob, "DD/MM/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    let diffYears = b.diff(a, "years");

    let tempPremium = 0;
    this.state.dependentPlans.map(plan => {
      if (planSelected.option === plan.option) {
        plan.details.map(planType => {
          if (planType.header === planHead.planType) {
            planType.details.map(dependent => {
              if (Array.isArray(planType.multiplier)) {
                if (dependent.header === "Employee") {
                  for (
                    let count = 0;
                    count < planType.multiplier.length;
                    count++
                  ) {
                    let tempPremium1 =
                      dependent.premium[count] * planType.multiplier[count] -
                      dependent.subsidy;
                    tempPremium = tempPremium + tempPremium1;
                  }
                }
              } else {
                if (dependent.header === "Employee") {
                  if (diffYears >= 71 && isPruAgencyMedEnrolment) {
                    tempPremium =
                      dependent.premium71 * planType.multiplier -
                      dependent.subsidy;
                  } else if (isGccePlan) {
                    // show GCCE premium here
                    let geecHealthLoading = "0";

                    this.state.rateMatrics.map(rateMatric => {
                      rateMatric.details.map(detail => {
                        if (detail.header === planHead.planType) {
                          detail.rules.map(rule => {
                            if (
                              rule.ageBand[0] <= diffYears &&
                              diffYears <= rule.ageBand[1]
                            ) {
                              let rate = rule.rate;
                              let SA = "25000";
                              let previousPlan = this.props.user
                                .coverage_details.previous;

                              if (previousPlan) {
                                previousPlan.map(prevPlan => {
                                  if (prevPlan.code === "GCCE") {
                                    geecHealthLoading = prevPlan.extra_premium;
                                  }
                                });
                              }

                              tempPremium =
                                (parseFloat(rate) / 1000) * parseFloat(SA);
                            }
                          });
                        }
                      });
                    });
                  } else {
                    const validCategories = ["Associate1", "Associate2"];
                    const isValidCategory = validCategories.includes(
                      this.props.user.category
                    );
                    let additionalPremium =
                      isValidCategory &&
                      planType.additionalPremium !== undefined
                        ? planType.additionalPremium
                        : 0.0;

                    tempPremium =
                      dependent.premium * planType.multiplier -
                      dependent.subsidy -
                      additionalPremium;
                  }
                }
              }
            });
          }
        });
      }
    });
    let categorySubsidy = this.getCategorySubsidy(planHead.coverageId);
    return tempPremium - categorySubsidy;
  };

  premiumInfoAllIn = (planHead, planSelected) => {
    let tempPremium = 0;
    this.state.dependentPlans.map(plan => {
      if (planSelected.option === plan.option) {
        plan.details.map(planType => {
          if (planType.header === planHead.planType) {
            planType.details.map(dependent => {
              if (dependent.header === "Individual") {
                tempPremium =
                  dependent.premium * planType.multiplier - dependent.subsidy;
              }
            });
          }
        });
      }
    });
    let categorySubsidy = this.getCategorySubsidy(planHead.coverageId);
    return tempPremium - categorySubsidy;
  };

  premiumInfoVertical = (user, planSelected, insuree, isPruAgencyEnrolment) => {
    let rateMatrics = this.state.rateMatrics;
    let tempPremium = "";
    let diffDays;
    var a = moment(user.dob, "D/M/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    diffDays = b.diff(a, "years");

    let occupationClass = this.props.occupationToClassValue(user.occupation);
    let gender = user.gender;

    if (isPruAgencyEnrolment) {
      // PruAgency Life Enrolment
      rateMatrics.map(rateMatric => {
        if (Array.isArray(rateMatric.details)) {
          rateMatric.details.map(detail => {
            if (detail.coverageId === planSelected.coverageId) {
              detail.rules.map(rule => {
                if (
                  rule.ageBand[0] <= diffDays &&
                  diffDays <= rule.ageBand[1]
                ) {
                  let rate = gender === "M" ? rule.maleRate : rule.femaleRate;
                  let SA = "0";
                  let previousPlan = user.coverage_details.previous;

                  if (planSelected.header === "Existing") {
                    previousPlan.map(plan => {
                      if (plan.code === planSelected.coverageId) {
                        SA = plan.sum_assured;
                      }
                    });
                  } else if (planSelected.header === "VECI") {
                    previousPlan.map(plan => {
                      if (plan.code === "VCCA") {
                        SA = plan.sum_assured;
                      }
                    });
                  } else {
                    SA = planSelected.details[0].overview.details[0].details[0];
                  }

                  if (planSelected.header === "VECI") {
                    tempPremium =
                      ((parseFloat(SA.replace(/,/g, "")).toFixed(2) * 0.3) /
                        1000) *
                      parseFloat(rate).toFixed(2);
                  } else {
                    tempPremium =
                      (parseFloat(SA.replace(/,/g, "")).toFixed(2) / 1000) *
                      parseFloat(rate).toFixed(2);
                  }
                  //7month change request
                  let tempPremiumSevenMonthRequest = parseFloat(tempPremium);
                  let sevenMonthChangeResult = (
                    (tempPremiumSevenMonthRequest / 365) *
                    212
                  ).toFixed(2);

                  tempPremium = sevenMonthChangeResult.toString();
                }
              });
            }
          });
        }
      });
    } else if (this.state.isPruAgencyMedEnrolment) {
      // PruAgency Med Enrolment
      rateMatrics.map(rateMatric => {
        if (Array.isArray(rateMatric.details)) {
          rateMatric.details.map(detail => {
            if (detail.coverageId === planSelected.coverageId) {
              detail.rules.map(rule => {
                if (
                  rule.ageBand[0] <= diffDays &&
                  diffDays <= rule.ageBand[1]
                ) {
                  let rate = rule.rate;
                  let SA = "0";
                  let previousPlan = user.coverage_details.previous;

                  if (planSelected.header === "Existing") {
                    previousPlan.map(plan => {
                      if (plan.code === planSelected.coverageId) {
                        SA = plan.sum_assured;
                      }
                    });
                  } else {
                    SA = planSelected.details[0].overview.details[0].details[0];
                  }

                  tempPremium =
                    (parseFloat(SA.replace(/,/g, "")).toFixed(2) * rate) / 1000;
                }
              });
            }
          });
        }
      });
    }

    rateMatrics.map(rateMatric => {
      // UOB Enrolment
      if (
        isPruAgencyEnrolment !== true &&
        rateMatric.value == occupationClass.value
      ) {
        rateMatric.details.map(detail => {
          detail.rules.map(rule => {
            if (rule.ageBand[0] <= diffDays && diffDays <= rule.ageBand[1]) {
              let rate = "";
              if (insuree === "Employee") rate = rule.employeeRate;
              else if (insuree === "SPOUSE") rate = rule.spouseRate;
              else if (insuree === "CHILD") rate = rule.childRate;
              let SA = planSelected.details[0].overview.details[0].details[0];
              tempPremium =
                (parseFloat(SA.replace(/,/g, "")).toFixed(2) *
                  parseFloat(rate).toFixed(2)) /
                1000;
            }
          });
        });
      }
    });
    return tempPremium;
  };

  premiumInfoDep = (coverage, plan, dependent) => {
    // debugger;
    let multiplier = 0;
    let subsidy = 0;
    let dob = this.props.user.dob;

    var a = moment(dob, "DD/MM/YYYY");
    var b = moment();
    if (
      this.props.user.client_name.config.dobValidationByPolicyStartDate ===
      "true"
    ) {
      b = moment(this.props.user.client_name.policy_start_date, "YYYY-MM-DD");
    }
    let diffYears = b.diff(a, "years");

    if (dependent === undefined || dependent === "Dependants") return 0.0;
    if (dependent && dependent.id) {
      let multiplierArr = this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage).multiplier;
      if (Array.isArray(multiplierArr)) {
        let premiumDetailsSub = 0;
        for (let count = 0; count < multiplierArr.length; count++) {
          let premiumDetailsSubArr = this.state.dependentPlans
            .find(planObj => planObj.option === plan.option)
            .details.find(
              detail =>
                detail.header === coverage &&
                (multiplier = detail.multiplier[count])
            )
            .details.find(detail1 => detail1.header === "Individual");
          subsidy = premiumDetailsSubArr.subsidy;
          premiumDetailsSub =
            premiumDetailsSub +
            premiumDetailsSubArr.premium[count] * multiplier -
            subsidy;
        }
        return premiumDetailsSub;
      } else {
        let premiumDetailsSub = this.state.dependentPlans
          .find(planObj => planObj.option === plan.option)
          .details.find(
            detail =>
              detail.header === coverage && (multiplier = detail.multiplier)
          )
          .details.find(detail1 => detail1.header === "Individual");
        subsidy = premiumDetailsSub.subsidy;
        return premiumDetailsSub.premium * multiplier - subsidy;
      }
    } else {
      let multiplierArr = this.state.dependentPlans
        .find(planObj => planObj.option === plan.option)
        .details.find(detail => detail.header === coverage).multiplier;
      if (Array.isArray(multiplierArr)) {
        let premiumDetailsSub = 0;
        for (let count = 0; count < multiplierArr.length; count++) {
          let premiumDetailsSubArr = this.state.dependentPlans
            .find(planObj => planObj.option === plan.option)
            .details.find(
              detail =>
                detail.header === coverage &&
                (multiplier = detail.multiplier[count])
            )
            .details.find(detail1 => detail1.header === dependent);
          subsidy = premiumDetailsSubArr.subsidy;
          premiumDetailsSub =
            premiumDetailsSub +
            premiumDetailsSubArr.premium[count] * multiplier -
            subsidy;
        }
        return premiumDetailsSub;
      } else {
        let premiumDetails = this.state.dependentPlans
          .find(planObj => planObj.option === plan.option)
          .details.find(
            detail =>
              detail.header === coverage && (multiplier = detail.multiplier)
          );

        let premiumDetailsSub = this.state.dependentPlans
          .find(planObj => planObj.option === plan.option)
          .details.find(
            detail =>
              detail.header === coverage && (multiplier = detail.multiplier)
          )
          .details.find(detail1 => detail1.header === dependent);
        subsidy = premiumDetailsSub.subsidy;
        let coverageName = coverage;
        let categorySubTemp = this.getCategorySubsidy(
          this.getCoverageIdByName(coverageName)
        );
        this.props.user.currentPlans.map(currentPlan => {
          if (
            this.props.user.depRule[currentPlan.coverageId]
              .categoryDeduction !== undefined &&
            this.props.user.depRule[currentPlan.coverageId].categoryDeduction
          ) {
            subsidy = categorySubTemp;
          }
        });
        const validCategories = ["Associate1", "Associate2"];
        const isValidCategory = validCategories.includes(
          this.props.user.category
        );
        let additionalPremium =
          isValidCategory && premiumDetails.additionalPremium !== undefined
            ? premiumDetails.additionalPremium
            : 0.0;

        return diffYears >= 71 && this.state.isPruAgencyMedEnrolment
          ? premiumDetailsSub.premium71 * multiplier - subsidy
          : premiumDetailsSub.premium * multiplier -
              subsidy -
              additionalPremium;
      }
    }
  };

  defaultPlanSelection = () => {
    let reverseTempMap = {
      "004": "001",
      "005": "002",
      "009": "006",
      "010": "007",
      "003": "003",
      "008": "008",
      "001": "001",
      "002": "002",
      "006": "006",
      "007": "007"
    };
    // let myDependentSelectMap = new Map(
    //   this.props.user.planSelectedDependent &&
    //     this.props.user.planSelectedDependent
    // );
    // let mySelectMap = new Map(
    //   this.props.user.planSelected && this.props.user.planSelected
    // );
    let defaultPlan = { code: "", option: "" };

    this.props.user.dependencies.map(dependent => {
      dependent.coverage_details.previous &&
        dependent.coverage_details.previous.map(previous => {
          if (previous.code) {
            if (defaultPlan.option === "") {
              defaultPlan.code = previous.code;
              defaultPlan.option = reverseTempMap[previous.option];
            } else if (defaultPlan.option != reverseTempMap[previous.option]) {
              defaultPlan.code = "";
              defaultPlan.option = "";
            }
          }
        });
    });
    this.setState({
      defaultPlan
    });
  };

  componentDidMount() {
    this.props.user.currentPlans.map(currentPlan => {
      if (
        this.props.user.depRule[currentPlan.coverageId].selectionMethod ===
        "ALLIN"
      ) {
        this.defaultPlanSelection();
      }
      if (this.props.user.depRule[currentPlan.coverageId].autoEnrollRelation) {
        this.setState({ relationReadOnly: true });
        if (
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "SPOUSE"
          ).length > 0 &&
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "CHILD"
          ).length > 0
        ) {
          this.setState({ dependent: "Spouse & Child(ren)" });
        } else if (
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "SPOUSE"
          ).length > 0 &&
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "CHILD"
          ).length == 0
        ) {
          this.setState({ dependent: "Spouse Only" });
        } else if (
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "SPOUSE"
          ).length == 0 &&
          this.props.user.dependencies.filter(
            dependent => dependent.dependent === "CHILD"
          ).length > 0
        ) {
          this.setState({ dependent: "Child(ren) Only" });
        }
      }
    });
  }

  componentWillMount() {
    this.props.handleNextEnable(
      this.props.state.user.planSelected != "" ? true : false
    );
    let myDependentSelectMap = new Map();
    let showDependentBadge = new Map();
    if (this.props.user.showDependentBadge !== undefined) {
      showDependentBadge = new Map(this.props.user.showDependentBadge);
    }
    myDependentSelectMap = new Map();
    if (this.props.user.planSelectedDependent !== undefined) {
      myDependentSelectMap = new Map(this.props.user.planSelectedDependent);
    }
    if (myDependentSelectMap.size === 0) {
      this.state.plans.map(planHead => {
        myDependentSelectMap.set(planHead.planType, "");
      });
    }
    let dependent = this.props.user.dependentByRelation;
    let dependentOptOutName = new Map();
    if (this.props.user.dependentOptOutName !== undefined) {
      dependentOptOutName = new Map(this.props.user.dependentOptOutName);
    }
    let dependentOptOut = new Map();
    if (this.props.user.dependentOptOut !== undefined) {
      dependentOptOut = new Map(this.props.user.dependentOptOut);
    }
    let employeeOptOutName = new Map(this.props.user.employeeOptOutName);
    let employeeOptOut = new Map();
    if (this.props.user.employeeOptOut !== undefined) {
      employeeOptOut = new Map(this.props.user.employeeOptOut);
    }
    if (
      this.state.planSelected !== "" &&
      this.state.planSelected !== undefined
    ) {
      let mySelectMap = new Map();
      if (this.props.user.planSelected !== undefined) {
        mySelectMap = new Map(this.props.user.planSelected);
      }
      this.setState(
        {
          ...this.state,
          dependent: dependent,
          planSelected: mySelectMap,
          dependendentPlanSelected: myDependentSelectMap,
          showDependentBadge: showDependentBadge,
          dependentOptOut: dependentOptOut,
          dependentOptOutName: dependentOptOutName,
          employeeOptOut: employeeOptOut,
          employeeOptOutName: employeeOptOutName
        },
        () => {
          this.props.selectPlan(mySelectMap);
          this.props.selectDependentPlan(myDependentSelectMap);
          this.props.premiumCalculationEmp(
            this.premiumCalculation(mySelectMap)
          );
          this.props.premiumCalculationDep(
            this.premiumCalculationDep(myDependentSelectMap)
          );
          this.props.handleBadgeOnDependent(showDependentBadge);
          this.props.handleNextEnable(
            this.handleNextOnDependent(
              myDependentSelectMap,
              showDependentBadge
            ) === true
              ? true
              : false
          );
          this.props.setDependentByRelation(dependent);
          this.props.setDependentOptOut(dependentOptOut);
          this.props.setDependentOptOutName(dependentOptOutName);
          this.props.setEmployeeOptOut(employeeOptOut);
          this.props.setEmployeeOptOutName(employeeOptOutName);
        }
      );
    }
  }

  createImage = obj => {
    var elements = [];
    for (var i = 0; i < obj.number; i++) {
      elements.push(<img key={i} src={obj.image} style={{ margin: "5px" }} />);
    }
    return elements;
  };

  formatContent = (string, argList) => {
    var year = moment(this.props.user.policy_start_date).format("YYYY");
    argList.forEach(arg => {
      string = string.replace(arg[0], arg[1]);
    });
    return string;
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  handleBadgeOnDependent = (planHead, countDep) => {
    let planMatrix = new Map();
    if (this.state.planMatrix !== undefined) {
      planMatrix = new Map(this.state.planMatrix);
    }
    if (countDep && planMatrix.get(planHead.planType) != countDep) {
      planMatrix.set(planHead.planType, countDep);
    }
    this.state.planMatrix = planMatrix;
  };

  handleNext = (mySelectMap, showDependentBadge) => {
    let dependentCheck = true;

    this.state.plans.some(planHead => {
      if (
        this.props.user.depRule[planHead.coverageId].selectionMethod === "name"
      ) {
        if (mySelectMap !== undefined) {
          if (mySelectMap.get(planHead.planType) === "") {
            dependentCheck = false;
          }
        }
      }
    });
    return dependentCheck;
  };

  handleNextOnDependent = (myDependentSelectMap, showDependentBadge) => {
    let dependentCheck = true;
    let hasDependent = this.props.user.dependencies.length > 0 ? true : false;
    let hasSpouse = false;

    this.props.user.dependencies.map(dependent => {
      if (dependent.dependent === "SPOUSE") {
        hasSpouse = true;
      }
    });

    this.state.plans.some(planHead => {
      if (
        this.props.user.depRule[planHead.coverageId].selectionMethod === "name"
      ) {
        let dependenciesLength = this.props.user.dependencies.length;
        let myDependentSelectMapLength;
        if (myDependentSelectMap !== undefined) {
          myDependentSelectMapLength =
            (myDependentSelectMap &&
              myDependentSelectMap.get(planHead.planType) &&
              myDependentSelectMap.get(planHead.planType).length) ||
            0;
        } else {
          myDependentSelectMapLength = 0;
        }

        if (
          this.state.isPruAgencyMedEnrolment &&
          hasDependent &&
          myDependentSelectMapLength > 0
        ) {
          dependentCheck = true;
        } else if (
          this.state.isPruAgencyMedEnrolment &&
          hasSpouse === false &&
          myDependentSelectMapLength === 0
        ) {
          dependentCheck = true;
        } else if (dependenciesLength != myDependentSelectMapLength) {
          dependentCheck = false;
          return;
        }
      }
    });
    if (
      dependentCheck &&
      this.state.plans.length === showDependentBadge.size &&
      myDependentSelectMap &&
      myDependentSelectMap.size === showDependentBadge.size
    ) {
      let tempVal = true;
      showDependentBadge.forEach((value, key, map) => {
        if (!value) {
          tempVal = false;
          return tempVal;
        }
      });
      return tempVal;
    } else if (
      this.state.isPruAgencyMedEnrolment &&
      dependentCheck &&
      this.state.plans.length === showDependentBadge.size &&
      (!hasDependent || !hasSpouse)
    ) {
      let tempVal = true;
      showDependentBadge.forEach((value, key, map) => {
        if (!value) {
          tempVal = !hasSpouse ? true : false;
          return tempVal;
        }
      });
      return tempVal;
    } else {
      return false;
    }
  };

  capitalizeFLetter = str => {
    return str.toLowerCase()[0].toUpperCase() + str.toLowerCase().slice(1);
  };

  // isEmployeeCheck = (planHead, plan) => {
  //   this.state.planSelected &&
  //     this.state.planSelected.has(planHead.planType) &&
  //     plan.header ===
  //       (this.state.planSelected &&
  //         this.state.planSelected.get(planHead.planType) &&
  //         this.state.planSelected.get(planHead.planType).header);
  // };

  // isDependentCheck = (coverageSelected, plan, dependent) => {
  //   this.state.dependendentPlanSelected &&
  //     this.state.dependendentPlanSelected.get(coverageSelected.planType) &&
  //     this.state.dependendentPlanSelected
  //       .get(coverageSelected.planType)
  //       .filter(
  //         planObj =>
  //           planObj.dependentType.id === dependent.id &&
  //           planObj.planDep.header === plan.header
  //       ).length > 0;
  // };

  handleCloseHDFClearMsg = () => {
    this.props.setHasHDF(false);
  };

  render() {
    const { classes, intl, user, hdf } = this.props;
    const { labels } = this.props.user.content;
    const { content, placeholderMap } = this.state;
    const mf = new MessageFormat("en");
    const enrolmentYear = mf.compile(labels["step2.header"]);
    const enrolmentStartEnd = mf.compile(labels["step2.description"]);
    const empVTLB = mf.compile(labels["step2.employee.vtlb"]);
    const empVCCA = mf.compile(labels["step2.employee.vcca"]);
    const empVECI = mf.compile(labels["step2.employee.veci"]);
    const empHL = mf.compile(labels["step2.employee.healthloading"]);
    const empExclusion = mf.compile(labels["step2.employee.exclusion"]);
    const spsVTLB = mf.compile(labels["step2.spouse.vtlb"]);
    const spsVCCA = mf.compile(labels["step2.spouse.vcca"]);
    const spsVECI = mf.compile(labels["step2.spouse.veci"]);
    const spsHL = mf.compile(labels["step2.spouse.healthloading"]);
    const spsExclusion = mf.compile(labels["step2.spouse.exclusion"]);
    const note = mf.compile(labels["step2.note"]);
    const policyPeriod = labels && mf.compile(labels["step3.note"]);
    const { depRule } = this.props.user;
    const { planSelected } = this.props.user;
    const placeholderMapTemp = !_.isEmpty(this.props.user.placeholderMap)
      ? new Map(this.props.user.placeholderMap)
      : new Map();
    var year = moment(this.props.user.policy_start_date).format("YYYY");
    const { value, plans } = this.state;
    var year = moment(this.props.user.policy_start_date).format("YYYY");
    const isPruAgencyEnrolment =
      user.client_name.config.isPruAgencyEnrolment === "True" ? true : false;
    let isEmpHealthLoading = false;
    let isDepHealthLoading = false;
    let employeeHealthLoading = "";
    let spouseHealthLoading = "";
    let dependentGHSOptOut = false;
    let dependentHasGhsPlan = false;
    const pruAgencyPlanTypes = [
      "Group Term Life (TPD up to 65 YO, death benefit)",
      "Group Crisis Cover Accelerated (37 Critical illness)",
      "Group Pre-crisis Guard Accelerated (Early CI)"
    ];
    const pruAgencyPlanCodes = ["GHS", "GMM", "GADD"];

    if (isPruAgencyEnrolment) {
      const hasNonZeroExtraPremium = this.props.user.coverage_details.previous.some(
        prevPlan =>
          prevPlan.extra_premium !== "0.0" && prevPlan.extra_premium !== "0.00"
      );

      const hasDepNonZeroExtraPremium =
        this.props.user.dependencies.length > 0 &&
        this.props.user.dependencies[0].coverage_details.previous.some(
          prevPlan =>
            prevPlan.extra_premium !== "0.0" &&
            prevPlan.extra_premium !== "0.00"
        );

      if (hasNonZeroExtraPremium || hasDepNonZeroExtraPremium) {
        isEmpHealthLoading = true;
        let empVTLBHealthLoading = "";
        let empVCCAHealthLoading = "";
        let empVECIHealthLoading = "";
        let depVTLBHealthLoading = "";
        let depVCCAHealthLoading = "";
        let depVECIHealthLoading = "";

        this.props.user.coverage_details.previous.forEach(prevPlan => {
          if (
            prevPlan.extra_premium !== "0.0" &&
            prevPlan.extra_premium !== "0.00"
          ) {
            switch (prevPlan.code) {
              case "VTLB":
                empVTLBHealthLoading = "$" + prevPlan.extra_premium + " (GTL)";
                break;
              case "VCCA":
                empVCCAHealthLoading = "$" + prevPlan.extra_premium + " (GCCA)";
                break;
              case "VECI":
                empVECIHealthLoading = "$" + prevPlan.extra_premium + " (GEAC)";
                break;
              default:
                break;
            }
          }
        });

        this.props.user.dependencies.length > 0 &&
          this.props.user.dependencies[0].coverage_details.previous.forEach(
            prevPlan => {
              if (
                prevPlan.extra_premium !== "0.0" &&
                prevPlan.extra_premium !== "0.00"
              ) {
                switch (prevPlan.code) {
                  case "VTLB":
                    depVTLBHealthLoading =
                      "$" + prevPlan.extra_premium + " (GTL)";
                    break;
                  case "VCCA":
                    depVCCAHealthLoading =
                      "$" + prevPlan.extra_premium + " (GCCA)";
                    break;
                  case "VECI":
                    depVECIHealthLoading =
                      "$" + prevPlan.extra_premium + " (GEAC)";
                    break;
                  default:
                    break;
                }
              }
            }
          );

        employeeHealthLoading = [
          empVTLBHealthLoading,
          empVCCAHealthLoading,
          empVECIHealthLoading
        ]
          .filter(Boolean)
          .join(", ");

        spouseHealthLoading = [
          depVTLBHealthLoading,
          depVCCAHealthLoading,
          depVECIHealthLoading
        ]
          .filter(Boolean)
          .join(", ");
      }

      if (this.props.user.dependencies.length > 0) {
        this.props.user.dependencies[0].coverage_details.previous.map(
          prevPlan => {
            if (
              prevPlan.extra_premium !== "0.0" &&
              prevPlan.extra_premium !== "0.00"
            ) {
              isDepHealthLoading = true;
            }
          }
        );
      }
    }
    if (this.state.isPruAgencyMedEnrolment) {
      let myDependentPlanMap = new Map();
      if (this.props.user.planSelectedDependent !== undefined) {
        myDependentPlanMap = new Map(this.props.user.planSelectedDependent);
      }
      if (
        myDependentPlanMap.has("Group Hospital & Surgical (GHS) Benefits") &&
        myDependentPlanMap.get("Group Hospital & Surgical (GHS) Benefits") !==
          ""
      ) {
        myDependentPlanMap
          .get("Group Hospital & Surgical (GHS) Benefits")
          .filter(plan => {
            if (plan.planDep === "Opt Out") {
              dependentGHSOptOut = true;
            } else if (plan.planDep !== "") {
              dependentHasGhsPlan = true;
            }
          });
      }
    }

    return (
      <div className={classes.rootInfo}>
        <div className={classes.contentInfo}>
          <div className={classes.headerInfo}>
            <Modal open={hdf} onClose={this.handleCloseHDFClearMsg}>
              <Grid container direction="row">
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <div className={classes.popup}>
                    <div className={classes.containerPopup}>
                      <span style={{ fontWeight: "bold" }}>
                        Warning: Making any changes in Plan Selection <br />
                        will reset Health Declaration Form.
                      </span>
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={this.handleCloseHDFClearMsg}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} style={{ paddingTop: "2%" }}>
                  <Button
                    variant="fab"
                    mini
                    onClick={this.handleCloseHDFClearMsg}
                  >
                    <Icon className={classes.leftIcon}>close</Icon>
                  </Button>
                </Grid>
              </Grid>
            </Modal>

            <Typography
              component={"span"}
              className={classes.headerText}
              dangerouslySetInnerHTML={{
                __html: enrolmentYear({
                  year: placeholderMapTemp.get("{year}")
                })
              }}
            />
            <Typography
              component={"span"}
              className={classes.infoText}
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: enrolmentStartEnd({
                  policyStart: moment(
                    placeholderMapTemp.get("{policyStart}")
                  ).format("D MMM YYYY"),
                  policyEnd: moment(
                    placeholderMapTemp.get("{policyEnd}")
                  ).format("D MMM YYYY"),
                  year: placeholderMapTemp.get("{year}"),
                  enrolmentStart: moment(
                    placeholderMapTemp.get("{enrolmentStart}")
                  ).format("D MMM YYYY"),
                  enrolmentEnd: moment(
                    placeholderMapTemp.get("{enrolmentEnd}")
                  ).format("D MMM YYYY"),
                  policyYear: moment(
                    placeholderMapTemp.get("{policyStart}")
                  ).format("YYYY")
                })
              }}
            />
            {this.props.user.client_name.config.isPruAgencyEnrolment === "True"
              ? this.props.user.coverage_details.previous.map(function(plan) {
                  return plan.code === "VTLB" ? (
                    <Typography
                      component={"span"}
                      className={classes.infoText}
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: empVTLB({
                          vtlbSumInsured: parseFloat(
                            plan.sum_assured.replace(/,/g, "")
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                      }}
                    />
                  ) : (
                    ""
                  );
                })
              : ""}
            {this.props.user.client_name.config.isPruAgencyEnrolment === "True"
              ? this.props.user.coverage_details.previous.map(function(plan) {
                  return plan.code === "VCCA" ? (
                    <Typography
                      component={"span"}
                      className={classes.infoText}
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: empVCCA({
                          vccaSumInsured: parseFloat(
                            plan.sum_assured.replace(/,/g, "")
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                      }}
                    />
                  ) : (
                    ""
                  );
                })
              : ""}
            {this.props.user.client_name.config.isPruAgencyEnrolment === "True"
              ? this.props.user.coverage_details.previous.map(function(plan) {
                  return plan.code === "VECI" ? (
                    <Typography
                      component={"span"}
                      className={classes.infoText}
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: empVECI({
                          veciSumInsured: parseFloat(
                            plan.sum_assured.replace(/,/g, "")
                          )
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                      }}
                    />
                  ) : (
                    ""
                  );
                })
              : ""}
            {this.props.user.client_name.config.isPruAgencyEnrolment ===
              "True" && this.props.user.dependencies.length > 0
              ? this.props.user.dependencies[0].coverage_details.previous.map(
                  function(plan) {
                    return plan.code === "VTLB" ? (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: spsVTLB({
                            sVtlbSumInsured: parseFloat(
                              plan.sum_assured.replace(/,/g, "")
                            )
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          })
                        }}
                      />
                    ) : (
                      ""
                    );
                  }
                )
              : ""}
            {this.props.user.client_name.config.isPruAgencyEnrolment ===
              "True" && this.props.user.dependencies.length > 0
              ? this.props.user.dependencies[0].coverage_details.previous.map(
                  function(plan) {
                    return plan.code === "VCCA" ? (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: spsVCCA({
                            sVccaSumInsured: parseFloat(
                              plan.sum_assured.replace(/,/g, "")
                            )
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          })
                        }}
                      />
                    ) : (
                      ""
                    );
                  }
                )
              : ""}
            {this.props.user.client_name.config.isPruAgencyEnrolment ===
              "True" && this.props.user.dependencies.length > 0
              ? this.props.user.dependencies[0].coverage_details.previous.map(
                  function(plan) {
                    return plan.code === "VECI" ? (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: spsVECI({
                            sVeciSumInsured: parseFloat(
                              plan.sum_assured.replace(/,/g, "")
                            )
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          })
                        }}
                      />
                    ) : (
                      ""
                    );
                  }
                )
              : ""}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.coverage_details.previous.map(function(plan) {
                return plan.code === "GADD" ? (
                  <Typography
                    component={"span"}
                    className={classes.infoText}
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{
                      __html: mf.compile(labels["step2.employee.gadd"])({
                        gaddSumAssured: plan.sum_assured
                      })
                    }}
                  />
                ) : (
                  ""
                );
              })}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.dependencies &&
              this.props.user.dependencies.map(function(dependent) {
                if (dependent.dependent === "SPOUSE") {
                  return dependent.coverage_details.previous.map(function(
                    plan
                  ) {
                    return plan.code === "GADD" ? (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: mf.compile(labels["step2.dependent.gadd"])({
                            gaddSumAssured: plan.sum_assured
                          })
                        }}
                      />
                    ) : (
                      ""
                    );
                  });
                }
              })}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.coverage_details.planName && (
                <Typography
                  component={"span"}
                  className={classes.infoText}
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: mf.compile(labels["step2.planname"])({
                      personType: "Your",
                      planName: this.props.user.coverage_details.planName
                    })
                  }}
                />
              )}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.dependencies &&
              this.props.user.dependencies.map(function(dependent) {
                if (dependent.dependent === "SPOUSE") {
                  return (
                    dependent.coverage_details.planName && (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: mf.compile(labels["step2.planname"])({
                            personType: "Your spouse's",
                            planName: dependent.coverage_details.planName
                          })
                        }}
                      />
                    )
                  );
                }
              })}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.dependencies &&
              (() => {
                let content = [];
                for (
                  let index = 0;
                  index < this.props.user.dependencies.length;
                  index++
                ) {
                  let dependent = this.props.user.dependencies[index];
                  if (dependent.dependent === "CHILD") {
                    dependent.coverage_details.planName &&
                      content.push(
                        <Typography
                          component={"span"}
                          className={classes.infoText}
                          style={{ whiteSpace: "pre-wrap" }}
                          dangerouslySetInnerHTML={{
                            __html: mf.compile(labels["step2.planname"])({
                              personType: "Your child(ren)'s",
                              planName: dependent.coverage_details.planName
                            })
                          }}
                        />
                      );
                    break;
                  }
                }
                return content;
              })()}
            {<br />}
            {this.state.isPruAgencyMedEnrolment && this.props.user.exclusion && (
              <Typography
                component={"span"}
                className={classes.infoText}
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: mf.compile(labels["step2.employee.exclusion"])({
                    exclusion: this.props.user.exclusion
                  })
                }}
              />
            )}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.dependencies &&
              this.props.user.dependencies.map(function(dependent) {
                if (dependent.dependent === "SPOUSE") {
                  return (
                    dependent.exclusion && (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: mf.compile(labels["step2.spouse.exclusion"])({
                            exclusion: dependent.exclusion
                          })
                        }}
                      />
                    )
                  );
                }
              })}
            {this.state.isPruAgencyMedEnrolment &&
              this.props.user.dependencies &&
              this.props.user.dependencies.map(function(dependent) {
                if (dependent.dependent === "CHILD") {
                  return (
                    dependent.exclusion && (
                      <Typography
                        component={"span"}
                        className={classes.infoText}
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: mf.compile(labels["step2.child.exclusion"])({
                            childName: dependent.full_name,
                            exclusion: dependent.exclusion
                          })
                        }}
                      />
                    )
                  );
                }
              })}
            {isEmpHealthLoading && (
              <Typography
                component={"span"}
                className={classes.infoText}
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: empHL({
                    healthLoading: employeeHealthLoading
                  })
                }}
              />
            )}
            {isDepHealthLoading && (
              <Typography
                component={"span"}
                className={classes.infoText}
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: spsHL({
                    healthLoading: spouseHealthLoading
                  })
                }}
              />
            )}
            {this.props.user.client_name.config.isPruAgencyEnrolment ===
            "True" ? (
              this.props.user.exclusion !== "" &&
              this.props.user.exclusion !== null ? (
                <Typography
                  component={"span"}
                  className={classes.infoText}
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: empExclusion({
                      exclusion: this.props.user.exclusion
                    })
                  }}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {this.props.user.client_name.config.isPruAgencyEnrolment ===
              "True" && this.props.user.dependencies.length > 0 ? (
              this.props.user.dependencies[0].exclusion !== "" &&
              this.props.user.dependencies[0].exclusion !== null ? (
                <Typography
                  component={"span"}
                  className={classes.infoText}
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: spsExclusion({
                      sExclusion: this.props.user.dependencies[0].exclusion
                    })
                  }}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
          <div className={classes.gridStyle1}>
            <AppBar
              position="static"
              color="default"
              className={classes.stickyCard}
              style={
                this.state.plans[0].isVertical === "true" &&
                this.props.user.dependencies.length > 3
                  ? {
                      boxShadow: "none",
                      width: `${this.props.user.dependencies.length * 300 +
                        800}px`
                    }
                  : this.state.plans[0].isVertical === "true" &&
                    this.props.user.dependencies.length <= 3
                  ? {
                      boxShadow: "none",
                      minWidth: "100%",
                      width: `${this.props.user.dependencies.length * 300 +
                        800}px`
                    }
                  : { boxShadow: "none", width: "100%" }
              }
            >
              <Tabs
                value={value}
                onChange={this.handleChange}
                scrollable
                scrollButtons="auto"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator
                }}
              >
                {this.state.plans.map(function(planHead, keyplanHead) {
                  return (
                    <Tab
                      label={planHead.planType}
                      key={keyplanHead}
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.selected
                      }}
                      style={{ height: "90px" }}
                      icon={
                        user.showDependentBadge &&
                        user.showDependentBadge.size > 0 &&
                        user.showDependentBadge.get(planHead.planType) ? (
                          <CheckCircle color="primary" />
                        ) : (
                          ""
                        )
                      }
                    />
                  );
                })}
              </Tabs>
            </AppBar>
            {this.state.plans.map(function(planHead, keyplanHead) {
              let disableEmployeeSwitch = false;
              let countDep = 0;
              const coverageHeader =
                (labels[`${"step2." + planHead.coverageId + ".header"}`] &&
                  mf.compile(
                    labels[`${"step2." + planHead.coverageId + ".header"}`]
                  )) ||
                "";
              const coverageDesc =
                (labels[`${"step2." + planHead.coverageId + ".description"}`] &&
                  mf.compile(
                    labels[`${"step2." + planHead.coverageId + ".description"}`]
                  )) ||
                "";

              for (let index = 1; index < pruAgencyPlanTypes.length; index++) {
                if (
                  isPruAgencyEnrolment &&
                  this.state.planSelected !== "" &&
                  this.state.planSelected.has(pruAgencyPlanTypes[index]) &&
                  this.state.planSelected.get(pruAgencyPlanTypes[index]) !== ""
                ) {
                  if (planHead.planType === pruAgencyPlanTypes[index]) {
                    if (
                      this.state.planSelected.get(
                        pruAgencyPlanTypes[index - 1]
                      ) === "Opt Out"
                    ) {
                      disableEmployeeSwitch = true;
                    }
                  }
                }
              }
              return (
                value === keyplanHead &&
                (planHead.isVertical === "true" ? (
                  <Paper
                    className={classes.expanded}
                    key={keyplanHead}
                    style={{ background: "#FFFFFF" }}
                  >
                    <TabContainer>
                      <div className={classes.root}>
                        <div>
                          <div
                            style={{ padding: "10px", paddingBottom: "30px" }}
                          >
                            <Typography
                              component={"span"}
                              className={classes.subHeader1Text}
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  coverageHeader &&
                                  coverageHeader({
                                    year: placeholderMapTemp.get("{year}")
                                  })
                              }}
                            />
                            <Typography
                              component={"span"}
                              className={classes.bottomNote}
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  coverageDesc &&
                                  coverageDesc({
                                    year: placeholderMapTemp.get("{year}")
                                  })
                              }}
                            />
                          </div>
                          <Grid container>
                            <Grid item xs={4} sm={4} md={4}>
                              <div
                                className={classes.stickyCard}
                                style={{ top: "90px" }}
                              >
                                <Grid container style={{ height: "120px" }}>
                                  <Grid item xs sm md>
                                    <Card
                                      className={classes.cardTopVertical}
                                      style={{ background: "#FFFFFF" }}
                                    >
                                      <CardContent
                                        classes={{ root: classes.cardRoot }}
                                        style={{ padding: "20px 0px" }}
                                      >
                                        <Typography
                                          component={"span"}
                                          className={classes.contentHeader}
                                          color="textSecondary"
                                        >
                                          <b>Plan Type</b>
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                  <Grid item xs sm md>
                                    <Card
                                      className={classes.cardTopVertical}
                                      style={{ background: "#FFFFFF" }}
                                    >
                                      <CardContent
                                        classes={{ root: classes.cardRoot }}
                                        style={{
                                          padding: "20px 0px",
                                          paddingLeft: "0px",
                                          paddingRight: "0px"
                                        }}
                                      >
                                        <Typography
                                          component={"span"}
                                          className={classes.contentHeader}
                                          color="textSecondary"
                                        >
                                          <b>Sum Assured (S$)</b>
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </Grid>
                              </div>
                              {planHead.planDetails.map(function(plan, keyTop) {
                                let height = {
                                  background: "#FFFFFF",
                                  height: ""
                                };
                                keyTop === planHead.planDetails.length - 1
                                  ? (height.height = "95%")
                                  : (height.height = "100%");
                                return (
                                  <Grid container key={keyTop}>
                                    <Grid item xs sm md>
                                      <Card
                                        className={classes.cardTopVertical}
                                        style={height}
                                      >
                                        <CardContent
                                          classes={{ root: classes.cardRoot }}
                                          style={{
                                            paddingLeft: "0px",
                                            paddingRight: "0px",
                                            paddingTop: "22px"
                                          }}
                                        >
                                          <Typography
                                            component={"span"}
                                            className={classes.content}
                                            color="textSecondary"
                                          >
                                            {
                                              plan.details[0].overview
                                                .details[0].header[0]
                                            }
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                    <Grid item xs sm md>
                                      <Card
                                        className={classes.cardTopVertical}
                                        style={height}
                                      >
                                        <CardContent
                                          classes={{ root: classes.cardRoot }}
                                          style={{
                                            paddingLeft: "0px",
                                            paddingRight: "0px",
                                            paddingTop: "22px"
                                          }}
                                        >
                                          <Typography
                                            component={"span"}
                                            className={classes.content}
                                            color="textSecondary"
                                          >
                                            <b>
                                              {
                                                plan.details[0].overview
                                                  .details[0].details[0]
                                              }
                                            </b>
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  </Grid>
                                );
                              }, this)}
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                              <div
                                style={{
                                  width: "100%"
                                  // overflowX: "auto",
                                  // overflowY: "hidden"
                                }}
                              >
                                <div
                                  style={{
                                    width: `${this.props.user.dependencies
                                      .length *
                                      300 +
                                      300}px`
                                  }}
                                >
                                  <Grid container>
                                    <Grid item xs sm md>
                                      <Grid container>
                                        <div
                                          className={classes.stickyCard}
                                          style={{ top: "90px", width: "100%" }}
                                        >
                                          <Grid container>
                                            <Grid
                                              item
                                              xs
                                              sm
                                              md
                                              style={{ height: "120px" }}
                                            >
                                              <Card
                                                className={
                                                  classes.cardTopVerticalHeader
                                                }
                                              >
                                                <CardContent
                                                  classes={{
                                                    root: classes.cardRoot
                                                  }}
                                                  style={{
                                                    paddingLeft: "0px",
                                                    paddingRight: "0px"
                                                  }}
                                                >
                                                  <Typography
                                                    component={"span"}
                                                    className={
                                                      classes.contentHeader
                                                    }
                                                    color="textSecondary"
                                                  >
                                                    <b>{user.fullName} </b>{" "}
                                                    <br />
                                                    (Self)
                                                    <br />
                                                  </Typography>
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        checked={
                                                          this.props.user
                                                            .client_name.config
                                                            .isPruAgencyEnrolment ===
                                                            "True" ||
                                                          this.props.user
                                                            .client_name.config
                                                            .isPruAgencyMedEnrolment ===
                                                            "True"
                                                            ? this.state
                                                                .planSelected !==
                                                              ""
                                                              ? planHead.planType &&
                                                                this.state.planSelected.get(
                                                                  planHead.planType
                                                                ) === "Opt Out"
                                                              : false
                                                            : this.state
                                                                .employeeOptOutName &&
                                                              this.state.employeeOptOutName.get(
                                                                user.id
                                                              )
                                                        }
                                                        onChange={this.handleChangeEmployeeSwitch(
                                                          "name",
                                                          user,
                                                          planHead.planType
                                                        )}
                                                        disabled={
                                                          disableEmployeeSwitch
                                                        }
                                                        value="checkedB"
                                                        color="primary"
                                                      />
                                                    }
                                                    label="Opt Out"
                                                  />
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        {planHead.planDetails.map(function(
                                          plan,
                                          keyTop
                                        ) {
                                          let pruAgencyPlanTypes = [
                                            "Group Term Life (TPD up to 65 YO, death benefit)",
                                            "Group Crisis Cover Accelerated (37 Critical illness)",
                                            "Group Pre-crisis Guard Accelerated (Early CI)"
                                          ];
                                          let pruAgencyPlanCode = [
                                            "VTLB",
                                            "VCCA",
                                            "VECI"
                                          ];
                                          let height = { height: "" };
                                          let noPreviousCoverage = false;
                                          let isEmployeeOptOut = false;
                                          let gccaMoreThanGTL = false;
                                          let veciNotEqualsGCCA = false;
                                          let exceedMaxAgeEmp = false;
                                          let isGTL = false;
                                          let isExceedAge64 = false;
                                          let isPruAgencyEnrolment =
                                            this.props.user.client_name.config
                                              .isPruAgencyEnrolment === "True"
                                              ? true
                                              : false;
                                          let SATemp =
                                            plan.details[0].overview.details[0]
                                              .details[0];

                                          if (plan.header === "Existing") {
                                            noPreviousCoverage = true;
                                            user.coverage_details.previous.map(
                                              prevPlan => {
                                                if (
                                                  plan.coverageId ===
                                                    prevPlan.code ||
                                                  plan.coverageId ===
                                                    pruAgencyPlanCode[2]
                                                ) {
                                                  noPreviousCoverage = false;
                                                }
                                              }
                                            );
                                          }

                                          if (this.state.planSelected !== "") {
                                            if (
                                              planHead.planType &&
                                              this.state.planSelected.get(
                                                planHead.planType
                                              ) === "Opt Out"
                                            ) {
                                              isEmployeeOptOut = true;
                                            }
                                          }

                                          // To disable VCCA SA greater than VTLB SA
                                          if (
                                            isPruAgencyEnrolment &&
                                            plan.coverageId ===
                                              pruAgencyPlanCode[1]
                                          ) {
                                            let isPreviousPlanOptOut =
                                              this.state.planSelected === "" ||
                                              this.state.planSelected.get(
                                                pruAgencyPlanTypes[0]
                                              ) === undefined ||
                                              this.state.planSelected.get(
                                                pruAgencyPlanTypes[0]
                                              ) === "Opt Out" ||
                                              this.state.planSelected.get(
                                                pruAgencyPlanTypes[0]
                                              ) === ""
                                                ? true
                                                : false;
                                            let vccaSA = "";
                                            let vtlbSA = "";

                                            if (!isPreviousPlanOptOut) {
                                              if (
                                                this.state.planSelected.get(
                                                  pruAgencyPlanTypes[0]
                                                ).details[0].overview.details[0]
                                                  .details[0] ===
                                                "Keep Existing"
                                              ) {
                                                this.props.user.coverage_details.previous.map(
                                                  prevPlan => {
                                                    if (
                                                      prevPlan.code ===
                                                      pruAgencyPlanCode[0]
                                                    ) {
                                                      vtlbSA =
                                                        prevPlan.sum_assured;
                                                    }
                                                  }
                                                );
                                              } else {
                                                vtlbSA = this.state.planSelected.get(
                                                  pruAgencyPlanTypes[0]
                                                ).details[0].overview.details[0]
                                                  .details[0];
                                              }
                                            }

                                            if (plan.header === "Existing") {
                                              this.props.user.coverage_details.previous.map(
                                                prevPlan => {
                                                  if (
                                                    prevPlan.code ===
                                                    pruAgencyPlanCode[1]
                                                  ) {
                                                    vccaSA =
                                                      prevPlan.sum_assured;
                                                  }
                                                }
                                              );
                                            } else {
                                              vccaSA =
                                                plan.details[0].overview
                                                  .details[0].details[0];
                                            }

                                            if (
                                              Number(vccaSA.replace(/,/g, "")) >
                                              Number(vtlbSA.replace(/,/g, ""))
                                            ) {
                                              gccaMoreThanGTL = true;
                                            }
                                          }

                                          // To disable VECI SA not equals to (VCCA SA * 0.3)
                                          if (
                                            isPruAgencyEnrolment &&
                                            plan.coverageId ===
                                              pruAgencyPlanCode[2]
                                          ) {
                                            let isPreviousPlanOptOut =
                                              this.state.planSelected === "" ||
                                              this.state.planSelected.get(
                                                pruAgencyPlanTypes[1]
                                              ) === undefined ||
                                              this.state.planSelected.get(
                                                pruAgencyPlanTypes[1]
                                              ) === "Opt Out" ||
                                              this.state.planSelected.get(
                                                pruAgencyPlanTypes[1]
                                              ) === ""
                                                ? true
                                                : false;
                                            let veciSA = "";
                                            let vccaSA = "";

                                            if (!isPreviousPlanOptOut) {
                                              if (
                                                this.state.planSelected.get(
                                                  pruAgencyPlanTypes[1]
                                                ).details[0].overview.details[0]
                                                  .details[0] ===
                                                "Keep Existing"
                                              ) {
                                                this.props.user.coverage_details.previous.map(
                                                  prevPlan => {
                                                    if (
                                                      prevPlan.code ===
                                                      pruAgencyPlanCode[1]
                                                    ) {
                                                      vccaSA =
                                                        prevPlan.sum_assured;
                                                    }
                                                  }
                                                );
                                              } else {
                                                vccaSA = this.state.planSelected.get(
                                                  pruAgencyPlanTypes[1]
                                                ).details[0].overview.details[0]
                                                  .details[0];
                                              }
                                            }

                                            if (plan.header === "VECI") {
                                              this.props.user.coverage_details.previous.map(
                                                prevPlan => {
                                                  if (
                                                    prevPlan.code ===
                                                    pruAgencyPlanCode[1]
                                                  ) {
                                                    veciSA =
                                                      Number(
                                                        prevPlan.sum_assured.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      ) * 0.3;
                                                  }
                                                }
                                              );
                                            } else {
                                              let tempVeciSA =
                                                plan.details[0].overview
                                                  .details[0].details[0];
                                              veciSA = Number(
                                                tempVeciSA.replace(/,/g, "")
                                              );
                                            }

                                            if (
                                              Number(veciSA) !==
                                              Number(vccaSA.replace(/,/g, "")) *
                                                0.3
                                            ) {
                                              veciNotEqualsGCCA = true;
                                            }
                                          }

                                          if (isPruAgencyEnrolment) {
                                            var a = moment(
                                              this.props.user.dob,
                                              "DD/MM/YYYY"
                                            );
                                            var b = moment();
                                            if (
                                              this.props.user.client_name.config
                                                .dobValidationByPolicyStartDate ===
                                              "true"
                                            ) {
                                              b = moment(
                                                this.props.user.client_name
                                                  .policy_start_date,
                                                "YYYY-MM-DD"
                                              );
                                            }
                                            let diffYears = b.diff(a, "years");

                                            if (
                                              this.props.user
                                                .existing_insured === true
                                            ) {
                                              if (
                                                diffYears < 16 ||
                                                diffYears > 74
                                              ) {
                                                exceedMaxAgeEmp = true;
                                              }
                                            } else {
                                              if (
                                                diffYears < 16 ||
                                                diffYears > 65
                                              ) {
                                                exceedMaxAgeEmp = true;
                                              }
                                            }

                                            if (diffYears > 69) {
                                              isExceedAge64 = true;
                                            }

                                            if (
                                              planHead.planType ===
                                              pruAgencyPlanTypes[0]
                                            ) {
                                              isGTL = true;
                                            }
                                          }

                                          keyTop ===
                                          planHead.planDetails.length - 1
                                            ? (height.height = "95%")
                                            : (height.height = "100%");
                                          return (
                                            <Grid container key={keyTop}>
                                              <Grid item xs sm md>
                                                <Card
                                                  className={
                                                    classes.cardTopVertical
                                                  }
                                                  style={height}
                                                >
                                                  <CardContent
                                                    classes={{
                                                      root: classes.cardRoot
                                                    }}
                                                    style={{
                                                      paddingLeft: "0px",
                                                      paddingRight: "0px"
                                                    }}
                                                  >
                                                    <Typography
                                                      component={"span"}
                                                      className={
                                                        classes.content
                                                      }
                                                      style={
                                                        isPruAgencyEnrolment ||
                                                        this.state
                                                          .isPruAgencyMedEnrolment
                                                          ? noPreviousCoverage ||
                                                            isEmployeeOptOut ||
                                                            gccaMoreThanGTL ||
                                                            veciNotEqualsGCCA ||
                                                            exceedMaxAgeEmp ||
                                                            (isExceedAge64 &&
                                                              !isGTL) ||
                                                            this.state
                                                              .disallowNextPlanSelection
                                                            ? { color: "grey" }
                                                            : {
                                                                color: "#000000"
                                                              }
                                                          : { color: "#000000" }
                                                      }
                                                      color="textSecondary"
                                                    >
                                                      <Radio
                                                        checked={
                                                          this.state
                                                            .planSelected &&
                                                          this.state.planSelected.has(
                                                            planHead.planType
                                                          ) &&
                                                          plan.header ===
                                                            (this.state
                                                              .planSelected &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ) &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).header)
                                                        }
                                                        onChange={this.handleCardClick.bind(
                                                          this,
                                                          plan,
                                                          planHead
                                                        )}
                                                        value={
                                                          plan.details[0]
                                                            .overview.details[0]
                                                            .header[0]
                                                        }
                                                        name="radio-button-demo"
                                                        aria-label="A"
                                                        color="primary"
                                                        disabled={
                                                          isPruAgencyEnrolment
                                                            ? noPreviousCoverage ||
                                                              isEmployeeOptOut ||
                                                              gccaMoreThanGTL ||
                                                              veciNotEqualsGCCA ||
                                                              exceedMaxAgeEmp ||
                                                              (isExceedAge64 &&
                                                                !isGTL) ||
                                                              this.state
                                                                .disallowNextPlanSelection
                                                            : this.state
                                                                .isPruAgencyMedEnrolment
                                                            ? (this.state
                                                                .planSelected &&
                                                                this.state.planSelected.has(
                                                                  planHead.planType
                                                                ) &&
                                                                this.state.planSelected.get(
                                                                  planHead.planType
                                                                ) ===
                                                                  "Opt Out") ||
                                                              noPreviousCoverage
                                                            : this.state
                                                                .employeeOptOutName &&
                                                              this.state.employeeOptOutName.get(
                                                                user.id
                                                              )
                                                        }
                                                      />
                                                      {isNaN(
                                                        parseFloat(
                                                          this.premiumInfoVertical(
                                                            user,
                                                            plan,
                                                            "Employee",
                                                            isPruAgencyEnrolment
                                                          )
                                                        ).toFixed(2)
                                                      )
                                                        ? `N/A`
                                                        : `S$` +
                                                          parseFloat(
                                                            this.premiumInfoVertical(
                                                              user,
                                                              plan,
                                                              "Employee",
                                                              isPruAgencyEnrolment
                                                            )
                                                          )
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            )}
                                                      /year
                                                    </Typography>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          );
                                        },
                                        this)}
                                      </Grid>
                                    </Grid>
                                    {user.dependencies
                                      .filter(
                                        dependent =>
                                          dependent.dependent === "SPOUSE"
                                      )
                                      .map((dependent, dependentKey2) => {
                                        let isPruAgencyEnrolment =
                                          this.props.user.client_name.config
                                            .isPruAgencyEnrolment === "True"
                                            ? true
                                            : false;
                                        let pruAgencyPlanTypes = [
                                          "Group Term Life (TPD up to 65 YO, death benefit)",
                                          "Group Crisis Cover Accelerated (37 Critical illness)",
                                          "Group Pre-crisis Guard Accelerated (Early CI)"
                                        ];
                                        let disableSwitch = false;
                                        let isDependentOptOut = false;
                                        var coverageSelected = plans.filter(
                                          plan =>
                                            plan.planType === planHead.planType
                                        )[0];
                                        countDep = countDep + 1;
                                        this.handleBadgeOnDependent(
                                          planHead,
                                          countDep
                                        );
                                        let isEmployeeOptOut = false;
                                        let dependentSelection =
                                          this.state.dependendentPlanSelected &&
                                          this.state.dependendentPlanSelected.get(
                                            planHead.planType
                                          ) &&
                                          this.state.dependendentPlanSelected
                                            .get(planHead.planType)
                                            .filter(
                                              planObj =>
                                                planObj.dependentType.id ===
                                                dependent.id
                                            )[0];

                                        if (
                                          (this.state.isPruAgencyMedEnrolment ||
                                            isPruAgencyEnrolment) &&
                                          this.state.planSelected !== ""
                                        ) {
                                          if (
                                            planHead.planType &&
                                            this.state.planSelected.get(
                                              planHead.planType
                                            ) === "Opt Out"
                                          ) {
                                            isEmployeeOptOut = true;
                                          }
                                        }

                                        if (
                                          this.state
                                            .dependendentPlanSelected !== "" &&
                                          this.state.dependendentPlanSelected
                                            .size > 0
                                        ) {
                                          if (
                                            planHead.planType &&
                                            this.state.dependendentPlanSelected.get(
                                              planHead.planType
                                            ) !== "" &&
                                            this.state.dependendentPlanSelected.get(
                                              planHead.planType
                                            ) !== undefined
                                          ) {
                                            this.state.dependendentPlanSelected
                                              .get(planHead.planType)
                                              .filter(dependent => {
                                                if (
                                                  dependent.planDep ===
                                                  "Opt Out"
                                                ) {
                                                  isDependentOptOut = true;
                                                }
                                              });
                                          }
                                        }

                                        for (
                                          let index = 1;
                                          index < pruAgencyPlanTypes.length;
                                          index++
                                        ) {
                                          if (
                                            isPruAgencyEnrolment &&
                                            this.state
                                              .dependendentPlanSelected !==
                                              "" &&
                                            this.state.dependendentPlanSelected.has(
                                              pruAgencyPlanTypes[index]
                                            ) &&
                                            this.state.dependendentPlanSelected.get(
                                              pruAgencyPlanTypes[index]
                                            ) !== ""
                                          ) {
                                            if (
                                              planHead.planType ===
                                              pruAgencyPlanTypes[index]
                                            ) {
                                              this.state.dependendentPlanSelected.get(
                                                pruAgencyPlanTypes[index - 1]
                                              ) &&
                                                this.state.dependendentPlanSelected
                                                  .get(
                                                    pruAgencyPlanTypes[
                                                      index - 1
                                                    ]
                                                  )
                                                  .filter(plan => {
                                                    if (
                                                      plan.planDep === "Opt Out"
                                                    ) {
                                                      disableSwitch = true;
                                                    }
                                                  });
                                            }
                                          }
                                        }

                                        return (
                                          <Grid
                                            item
                                            xs
                                            sm
                                            md
                                            key={dependentKey2}
                                          >
                                            <Grid container>
                                              <div
                                                className={classes.stickyCard}
                                                style={{
                                                  top: "90px",
                                                  width: "100%"
                                                }}
                                              >
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    xs
                                                    sm
                                                    md
                                                    style={{ height: "120px" }}
                                                  >
                                                    <Card
                                                      className={
                                                        classes.cardTopVerticalHeader
                                                      }
                                                      style={{
                                                        overflow: "auto"
                                                      }}
                                                    >
                                                      <CardContent
                                                        classes={{
                                                          root: classes.cardRoot
                                                        }}
                                                        style={{
                                                          paddingLeft: "0px",
                                                          paddingRight: "0px",
                                                          height: "100px"
                                                        }}
                                                      >
                                                        <Typography
                                                          component={"span"}
                                                          className={
                                                            classes.contentHeader
                                                          }
                                                          color="textSecondary"
                                                        >
                                                          <b>
                                                            {
                                                              dependent.full_name
                                                            }
                                                          </b>{" "}
                                                          <br />(
                                                          {this.capitalizeFLetter(
                                                            dependent.dependent
                                                          )}
                                                          )
                                                        </Typography>
                                                        <FormControlLabel
                                                          control={
                                                            <Switch
                                                              checked={
                                                                this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .isPruAgencyEnrolment ===
                                                                "True"
                                                                  ? this.state
                                                                      .planSelected !==
                                                                      "" &&
                                                                    dependentSelection !==
                                                                      undefined
                                                                    ? planHead.planType &&
                                                                      (this.state.planSelected.get(
                                                                        planHead.planType
                                                                      ) ===
                                                                        "Opt Out" ||
                                                                        dependentSelection.planDep ===
                                                                          "Opt Out")
                                                                    : false
                                                                  : this.state
                                                                      .isPruAgencyMedEnrolment
                                                                  ? isDependentOptOut ||
                                                                    isEmployeeOptOut
                                                                  : this.state
                                                                      .dependentOptOutName &&
                                                                    this.state
                                                                      .dependentOptOutName
                                                                      .size
                                                                  ? this.state.dependentOptOutName.get(
                                                                      dependent.id
                                                                    )
                                                                  : false
                                                              }
                                                              onChange={this.handleChangeSwitch(
                                                                "name",
                                                                dependent,
                                                                planHead.planType
                                                              )}
                                                              value="checkedB"
                                                              color="primary"
                                                              disabled={
                                                                isPruAgencyEnrolment &&
                                                                (isEmployeeOptOut ||
                                                                  this.state
                                                                    .disallowNextPlanSelection ||
                                                                  disableSwitch)
                                                                  ? true
                                                                  : this.state
                                                                      .isPruAgencyMedEnrolment
                                                                  ? (this.state
                                                                      .planSelected &&
                                                                      this.state.planSelected.get(
                                                                        planHead.planType
                                                                      )) === ""
                                                                    ? true
                                                                    : false
                                                                  : this.state
                                                                      .planSelected &&
                                                                    this.state.planSelected.get(
                                                                      planHead.planType
                                                                    )
                                                                  ? false
                                                                  : true
                                                              }
                                                            />
                                                          }
                                                          label="Opt Out"
                                                        />
                                                      </CardContent>
                                                    </Card>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                              {planHead.planDetails.map(
                                                function(plan, keyTop) {
                                                  let isPruAgencyEnrolment =
                                                    this.props.user.client_name
                                                      .config
                                                      .isPruAgencyEnrolment ===
                                                    "True"
                                                      ? true
                                                      : false;
                                                  let pruAgencyPlanTypes = [
                                                    "Group Term Life (TPD up to 65 YO, death benefit)",
                                                    "Group Crisis Cover Accelerated (37 Critical illness)",
                                                    "Group Pre-crisis Guard Accelerated (Early CI)"
                                                  ];
                                                  let pruAgencyPlanCode = [
                                                    "VTLB",
                                                    "VCCA",
                                                    "VECI"
                                                  ];
                                                  let disablePlan = false;
                                                  let spouseLimitDisbaled = false;
                                                  let disableEmployeeNonSelect = false;
                                                  let noPreviousCoverage = false;
                                                  let isEmployeeOptOut = false;
                                                  let isDependentOptOut = false;
                                                  let gccaMoreThanGTL = false;
                                                  let veciNotEqualsGCCA = false;
                                                  let spouseSAMoreThanEmployee = false;
                                                  let exceedMaxAgeDep = false;
                                                  let isGTL = false;
                                                  let isDependentGHSOptOut = false;
                                                  let isExceedAge64 = false;
                                                  let SATemp =
                                                    plan.details[0].overview
                                                      .details[0].details[0];
                                                  let outsideSpousePlan = false;
                                                  let rateTemp = parseFloat(
                                                    this.premiumInfoVertical(
                                                      dependent,
                                                      plan,
                                                      dependent.dependent,
                                                      isPruAgencyEnrolment
                                                    )
                                                  ).toFixed(2);

                                                  if (
                                                    this.state
                                                      .dependendentPlanSelected &&
                                                    this.state.dependendentPlanSelected.get(
                                                      "Group Hospital & Surgical (GHS) Benefits"
                                                    )
                                                  ) {
                                                    this.state.dependendentPlanSelected
                                                      .get(
                                                        "Group Hospital & Surgical (GHS) Benefits"
                                                      )
                                                      .filter(dependent => {
                                                        if (
                                                          dependent.planDep ===
                                                          "Opt Out"
                                                        ) {
                                                          isDependentGHSOptOut = true;
                                                        }
                                                      });
                                                  }

                                                  if (
                                                    dependent.dependent ===
                                                      "SPOUSE" &&
                                                    parseFloat(
                                                      SATemp.replace(/,/g, "")
                                                    ).toFixed(2) > 1000000
                                                  )
                                                    spouseLimitDisbaled = true;
                                                  if (
                                                    isNaN(rateTemp) &&
                                                    isPruAgencyEnrolment !==
                                                      true
                                                  ) {
                                                    outsideSpousePlan = true;
                                                  }
                                                  if (
                                                    depRule[planHead.coverageId]
                                                      .spouseCoverage ===
                                                    "downgrade"
                                                  ) {
                                                    plan.option <=
                                                    (this.state.planSelected &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ) &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ).option)
                                                      ? (disablePlan = false)
                                                      : (disablePlan = true);
                                                  } else if (
                                                    depRule[planHead.coverageId]
                                                      .spouseCoverage === "same"
                                                  ) {
                                                    plan.option ==
                                                    (this.state.planSelected &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ) &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ).option)
                                                      ? (disablePlan = false)
                                                      : (disablePlan = true);
                                                  } else if (
                                                    depRule[planHead.coverageId]
                                                      .spouseCoverage === "any"
                                                  ) {
                                                    disablePlan = false;
                                                  }

                                                  if (
                                                    plan.header === "Existing"
                                                  ) {
                                                    noPreviousCoverage = true;
                                                    dependent.coverage_details.previous.map(
                                                      prevPlan => {
                                                        if (
                                                          plan.coverageId ===
                                                          prevPlan.code
                                                        ) {
                                                          noPreviousCoverage = false;
                                                        }
                                                      }
                                                    );
                                                  }

                                                  // Spouse opt out when employee is opt out
                                                  if (
                                                    (this.state
                                                      .isPruAgencyMedEnrolment ||
                                                      isPruAgencyEnrolment) &&
                                                    this.state.planSelected !==
                                                      ""
                                                  ) {
                                                    if (
                                                      planHead.planType &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ) === "Opt Out"
                                                    ) {
                                                      isEmployeeOptOut = true;
                                                    }
                                                  }

                                                  if (
                                                    this.state
                                                      .dependendentPlanSelected !==
                                                      "" &&
                                                    this.state
                                                      .dependendentPlanSelected
                                                      .size > 0
                                                  ) {
                                                    if (
                                                      planHead.planType &&
                                                      this.state.dependendentPlanSelected.get(
                                                        planHead.planType
                                                      ) !== "" &&
                                                      this.state.dependendentPlanSelected.get(
                                                        planHead.planType
                                                      ) !== undefined
                                                    ) {
                                                      this.state.dependendentPlanSelected
                                                        .get(planHead.planType)
                                                        .filter(dependent => {
                                                          if (
                                                            dependent.planDep ===
                                                            "Opt Out"
                                                          ) {
                                                            isDependentOptOut = true;
                                                          }
                                                        });
                                                    }
                                                  }

                                                  // To disable VCCA SA greater than VTLB SA
                                                  if (
                                                    isPruAgencyEnrolment &&
                                                    plan.coverageId ===
                                                      pruAgencyPlanCode[1]
                                                  ) {
                                                    let isPreviousPlanOptOut = true;
                                                    let vccaSA = "";
                                                    let vtlbSA = "";

                                                    if (
                                                      planHead.planType &&
                                                      this.state
                                                        .dependendentPlanSelected !==
                                                        "" &&
                                                      this.state
                                                        .dependendentPlanSelected !==
                                                        undefined &&
                                                      this.state.dependendentPlanSelected.get(
                                                        pruAgencyPlanTypes[0]
                                                      ) !== ""
                                                    ) {
                                                      if (
                                                        this.state
                                                          .dependendentPlanSelected
                                                          .size > 0
                                                      ) {
                                                        this.state.dependendentPlanSelected
                                                          .get(
                                                            pruAgencyPlanTypes[0]
                                                          )
                                                          .filter(dependent => {
                                                            isPreviousPlanOptOut =
                                                              dependent.planDep ===
                                                                "Opt Out" ||
                                                              dependent.planDep ===
                                                                ""
                                                                ? true
                                                                : false;
                                                          });
                                                      }
                                                    }

                                                    if (!isPreviousPlanOptOut) {
                                                      this.state.dependendentPlanSelected
                                                        .get(
                                                          pruAgencyPlanTypes[0]
                                                        )
                                                        .filter(dependent => {
                                                          if (
                                                            dependent.planDep
                                                              .details[0]
                                                              .overview
                                                              .details[0]
                                                              .details[0] ===
                                                            "Keep Existing"
                                                          ) {
                                                            this.props.user.dependencies[0].coverage_details.previous.map(
                                                              prevPlan => {
                                                                if (
                                                                  prevPlan.code ===
                                                                  pruAgencyPlanCode[0]
                                                                ) {
                                                                  vtlbSA =
                                                                    prevPlan.sum_assured;
                                                                }
                                                              }
                                                            );
                                                          } else {
                                                            vtlbSA =
                                                              dependent.planDep
                                                                .details[0]
                                                                .overview
                                                                .details[0]
                                                                .details[0];
                                                          }

                                                          if (
                                                            plan.header ===
                                                            "Existing"
                                                          ) {
                                                            this.props.user.dependencies[0].coverage_details.previous.map(
                                                              prevPlan => {
                                                                if (
                                                                  prevPlan.code ===
                                                                  pruAgencyPlanCode[1]
                                                                ) {
                                                                  vccaSA =
                                                                    prevPlan.sum_assured;
                                                                }
                                                              }
                                                            );
                                                          } else {
                                                            vccaSA =
                                                              plan.details[0]
                                                                .overview
                                                                .details[0]
                                                                .details[0];
                                                          }
                                                        });
                                                    }

                                                    if (
                                                      Number(
                                                        vccaSA.replace(/,/g, "")
                                                      ) >
                                                      Number(
                                                        vtlbSA.replace(/,/g, "")
                                                      )
                                                    ) {
                                                      gccaMoreThanGTL = true;
                                                    }
                                                  }

                                                  // To disable VECI SA not equals to (VCCA SA * 0.3)

                                                  if (
                                                    isPruAgencyEnrolment &&
                                                    plan.coverageId ===
                                                      pruAgencyPlanCode[2]
                                                  ) {
                                                    let isPreviousPlanOptOut = true;
                                                    let veciSA = "";
                                                    let vccaSA = "";

                                                    if (
                                                      planHead.planType &&
                                                      this.state
                                                        .dependendentPlanSelected !==
                                                        "" &&
                                                      this.state
                                                        .dependendentPlanSelected !==
                                                        undefined &&
                                                      this.state.dependendentPlanSelected.get(
                                                        pruAgencyPlanTypes[1]
                                                      ) !== ""
                                                    ) {
                                                      if (
                                                        this.state
                                                          .dependendentPlanSelected
                                                          .size > 1
                                                      ) {
                                                        this.state.dependendentPlanSelected
                                                          .get(
                                                            pruAgencyPlanTypes[1]
                                                          )
                                                          .filter(dependent => {
                                                            isPreviousPlanOptOut =
                                                              dependent.planDep ===
                                                                "Opt Out" ||
                                                              dependent.planDep ===
                                                                ""
                                                                ? true
                                                                : false;
                                                          });
                                                      }
                                                    }

                                                    if (!isPreviousPlanOptOut) {
                                                      this.state.dependendentPlanSelected
                                                        .get(
                                                          pruAgencyPlanTypes[1]
                                                        )
                                                        .filter(dependent => {
                                                          if (
                                                            dependent.planDep
                                                              .details[0]
                                                              .overview
                                                              .details[0]
                                                              .details[0] ===
                                                            "Keep Existing"
                                                          ) {
                                                            this.props.user.dependencies[0].coverage_details.previous.map(
                                                              prevPlan => {
                                                                if (
                                                                  prevPlan.code ===
                                                                  pruAgencyPlanCode[1]
                                                                ) {
                                                                  vccaSA =
                                                                    prevPlan.sum_assured;
                                                                }
                                                              }
                                                            );
                                                          } else {
                                                            vccaSA =
                                                              dependent.planDep
                                                                .details[0]
                                                                .overview
                                                                .details[0]
                                                                .details[0];
                                                          }

                                                          if (
                                                            plan.header ===
                                                            "VECI"
                                                          ) {
                                                            this.props.user.dependencies[0].coverage_details.previous.map(
                                                              prevPlan => {
                                                                if (
                                                                  prevPlan.code ===
                                                                  pruAgencyPlanCode[1]
                                                                ) {
                                                                  veciSA =
                                                                    Number(
                                                                      prevPlan.sum_assured.replace(
                                                                        /,/g,
                                                                        ""
                                                                      )
                                                                    ) * 0.3;
                                                                }
                                                              }
                                                            );
                                                          } else {
                                                            let tempVeciSA =
                                                              plan.details[0]
                                                                .overview
                                                                .details[0]
                                                                .details[0];
                                                            veciSA = Number(
                                                              tempVeciSA.replace(
                                                                /,/g,
                                                                ""
                                                              )
                                                            );
                                                          }
                                                        });
                                                    }

                                                    if (
                                                      Number(veciSA) !==
                                                      Number(
                                                        vccaSA.replace(/,/g, "")
                                                      ) *
                                                        0.3
                                                    ) {
                                                      veciNotEqualsGCCA = true;
                                                    }
                                                  }

                                                  // Spouse's SA cannot more than employee's SA
                                                  if (
                                                    (isPruAgencyEnrolment ||
                                                      this.state
                                                        .isPruAgencyMedEnrolment) &&
                                                    this.state.planSelected !==
                                                      "" &&
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ) !== undefined &&
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ) !== ""
                                                  ) {
                                                    let employeeSA = "";
                                                    let previousPlan = this
                                                      .props.user
                                                      .coverage_details
                                                      .previous;
                                                    if (
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ).header === "Existing"
                                                    ) {
                                                      previousPlan.map(
                                                        prevPlan => {
                                                          if (
                                                            plan.coverageId ===
                                                            prevPlan.code
                                                          ) {
                                                            employeeSA =
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ) !== "Opt Out"
                                                                ? prevPlan.sum_assured
                                                                : "";
                                                          }
                                                        }
                                                      );
                                                    } else {
                                                      employeeSA =
                                                        this.state.planSelected.get(
                                                          planHead.planType
                                                        ) !== "Opt Out"
                                                          ? this.state.planSelected.get(
                                                              planHead.planType
                                                            ).details[0]
                                                              .overview
                                                              .details[0]
                                                              .details[0]
                                                          : "";
                                                    }
                                                    if (
                                                      plan.header === "Existing"
                                                    ) {
                                                      previousPlan.map(
                                                        prevPlan => {
                                                          if (
                                                            plan.coverageId ===
                                                            prevPlan.code
                                                          ) {
                                                            SATemp =
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ) !== "Opt Out"
                                                                ? prevPlan.sum_assured
                                                                : "";
                                                          }
                                                        }
                                                      );
                                                    } else {
                                                      SATemp =
                                                        plan.details[0].overview
                                                          .details[0]
                                                          .details[0];
                                                    }

                                                    if (
                                                      Number(
                                                        SATemp.replace(/,/g, "")
                                                      ) >
                                                      Number(
                                                        employeeSA.replace(
                                                          /,/g,
                                                          ""
                                                        )
                                                      )
                                                    ) {
                                                      spouseSAMoreThanEmployee = true;
                                                    }
                                                  }
                                                  if (isPruAgencyEnrolment) {
                                                    var a = moment(
                                                      this.props.user
                                                        .dependencies[0].dob,
                                                      "DD/MM/YYYY"
                                                    );
                                                    var b = moment();
                                                    if (
                                                      this.props.user
                                                        .client_name.config
                                                        .dobValidationByPolicyStartDate ===
                                                      "true"
                                                    ) {
                                                      b = moment(
                                                        this.props.user
                                                          .client_name
                                                          .policy_start_date,
                                                        "YYYY-MM-DD"
                                                      );
                                                    }
                                                    let diffYears = b.diff(
                                                      a,
                                                      "years"
                                                    );

                                                    if (
                                                      this.props.user
                                                        .dependencies[0]
                                                        .existing_insured ===
                                                      true
                                                    ) {
                                                      if (
                                                        diffYears < 16 ||
                                                        diffYears > 74
                                                      ) {
                                                        exceedMaxAgeDep = true;
                                                      }
                                                    } else {
                                                      if (
                                                        diffYears < 16 ||
                                                        diffYears > 65
                                                      ) {
                                                        exceedMaxAgeDep = true;
                                                      }
                                                    }

                                                    if (diffYears > 69) {
                                                      isExceedAge64 = true;
                                                    }

                                                    if (
                                                      planHead.planType ===
                                                      pruAgencyPlanTypes[0]
                                                    ) {
                                                      isGTL = true;
                                                    }
                                                  }

                                                  this.state.planSelected ===
                                                    "" ||
                                                  (this.state.planSelected &&
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ) &&
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ).option === "") ||
                                                  this.state.planSelected.get(
                                                    planHead.planType
                                                  ) === ""
                                                    ? (disableEmployeeNonSelect = true)
                                                    : (disableEmployeeNonSelect = false);

                                                  let height = { height: "" };
                                                  keyTop ===
                                                  planHead.planDetails.length -
                                                    1
                                                    ? (height.height = "95%")
                                                    : (height.height = "100%");
                                                  return (
                                                    <Grid
                                                      container
                                                      key={keyTop}
                                                    >
                                                      <Grid item xs sm md>
                                                        <Card
                                                          className={
                                                            classes.cardTopVertical
                                                          }
                                                          style={height}
                                                        >
                                                          <CardContent
                                                            classes={{
                                                              root:
                                                                classes.cardRoot
                                                            }}
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                              paddingRight:
                                                                "0px"
                                                            }}
                                                          >
                                                            <Typography
                                                              component={"span"}
                                                              className={
                                                                classes.content
                                                              }
                                                              style={
                                                                isPruAgencyEnrolment
                                                                  ? this.state
                                                                      .disallowNextPlanSelection ||
                                                                    noPreviousCoverage ||
                                                                    isEmployeeOptOut ||
                                                                    isDependentOptOut ||
                                                                    spouseSAMoreThanEmployee ||
                                                                    gccaMoreThanGTL ||
                                                                    veciNotEqualsGCCA ||
                                                                    exceedMaxAgeDep ||
                                                                    (isExceedAge64 &&
                                                                      !isGTL) ||
                                                                    !(
                                                                      this.state
                                                                        .planSelected &&
                                                                      this.state.planSelected.get(
                                                                        planHead.planType
                                                                      )
                                                                    )
                                                                    ? {
                                                                        color:
                                                                          "grey"
                                                                      }
                                                                    : {
                                                                        color:
                                                                          "#000000"
                                                                      }
                                                                  : (this.state
                                                                      .dependentOptOutName &&
                                                                      this.state.dependentOptOutName.get(
                                                                        dependent.id
                                                                      )) ||
                                                                    disablePlan ||
                                                                    spouseLimitDisbaled ||
                                                                    outsideSpousePlan ||
                                                                    isEmployeeOptOut ||
                                                                    spouseSAMoreThanEmployee ||
                                                                    isDependentGHSOptOut ||
                                                                    dependentGHSOptOut
                                                                  ? {
                                                                      color:
                                                                        "grey"
                                                                    }
                                                                  : {
                                                                      color:
                                                                        "#000000"
                                                                    }
                                                              }
                                                            >
                                                              {!(
                                                                spouseLimitDisbaled ||
                                                                outsideSpousePlan
                                                              ) && (
                                                                <Radio
                                                                  checked={
                                                                    this.state
                                                                      .isPruAgencyEnrolment
                                                                      ? this
                                                                          .state
                                                                          .dependendentPlanSelected &&
                                                                        this.state.dependendentPlanSelected.get(
                                                                          coverageSelected.planType
                                                                        ) &&
                                                                        this.state.dependendentPlanSelected
                                                                          .get(
                                                                            coverageSelected.planType
                                                                          )[0]
                                                                          .filter(
                                                                            planObj =>
                                                                              planObj
                                                                                .planDep
                                                                                .header ===
                                                                              plan.header
                                                                          )
                                                                          .length >
                                                                          0
                                                                      : this
                                                                          .state
                                                                          .dependendentPlanSelected &&
                                                                        this.state.dependendentPlanSelected.get(
                                                                          coverageSelected.planType
                                                                        ) &&
                                                                        this.state.dependendentPlanSelected
                                                                          .get(
                                                                            coverageSelected.planType
                                                                          )
                                                                          .filter(
                                                                            planObj =>
                                                                              planObj
                                                                                .dependentType
                                                                                .id ===
                                                                                dependent.id &&
                                                                              planObj
                                                                                .planDep
                                                                                .header ===
                                                                                plan.header
                                                                          )
                                                                          .length >
                                                                          0
                                                                  }
                                                                  onChange={e =>
                                                                    this.handleDependentCardClick(
                                                                      coverageSelected.planType,
                                                                      plan,
                                                                      dependent
                                                                    )
                                                                  }
                                                                  value={
                                                                    plan
                                                                      .details[0]
                                                                      .overview
                                                                      .details[0]
                                                                      .header[0]
                                                                  }
                                                                  name="radio-button-demo"
                                                                  aria-label="A"
                                                                  color="primary"
                                                                  disabled={
                                                                    isPruAgencyEnrolment
                                                                      ? this
                                                                          .state
                                                                          .disallowNextPlanSelection ||
                                                                        noPreviousCoverage ||
                                                                        isEmployeeOptOut ||
                                                                        isDependentOptOut ||
                                                                        spouseSAMoreThanEmployee ||
                                                                        gccaMoreThanGTL ||
                                                                        veciNotEqualsGCCA ||
                                                                        exceedMaxAgeDep ||
                                                                        (isExceedAge64 &&
                                                                          !isGTL) ||
                                                                        !(
                                                                          this
                                                                            .state
                                                                            .planSelected &&
                                                                          this.state.planSelected.get(
                                                                            planHead.planType
                                                                          )
                                                                        )
                                                                      : this
                                                                          .state
                                                                          .isPruAgencyMedEnrolment
                                                                      ? (this
                                                                          .state
                                                                          .planSelected &&
                                                                          this.state.planSelected.get(
                                                                            planHead.planType
                                                                          ) !==
                                                                            "Opt Out" &&
                                                                          this.state.planSelected.get(
                                                                            planHead.planType
                                                                          ) ===
                                                                            "") ||
                                                                        (this
                                                                          .state
                                                                          .dependendentPlanSelected &&
                                                                          this.state.dependendentPlanSelected.get(
                                                                            planHead.planType
                                                                          ) &&
                                                                          this.state.dependendentPlanSelected.get(
                                                                            planHead.planType
                                                                          )
                                                                            .length >
                                                                            0 &&
                                                                          this.state.dependendentPlanSelected.get(
                                                                            planHead.planType
                                                                          )[0]
                                                                            .planDep ===
                                                                            "Opt Out") ||
                                                                        isEmployeeOptOut ||
                                                                        spouseSAMoreThanEmployee ||
                                                                        noPreviousCoverage ||
                                                                        isDependentGHSOptOut
                                                                      : (this
                                                                          .state
                                                                          .dependentOptOutName &&
                                                                          this.state.dependentOptOutName.get(
                                                                            dependent.id
                                                                          )) ||
                                                                        disablePlan ||
                                                                        spouseLimitDisbaled ||
                                                                        disableEmployeeNonSelect ||
                                                                        this
                                                                          .state
                                                                          .disallowNextPlanSelection ||
                                                                        outsideSpousePlan ||
                                                                        !(
                                                                          this
                                                                            .state
                                                                            .planSelected &&
                                                                          this.state.planSelected.get(
                                                                            planHead.planType
                                                                          )
                                                                        )
                                                                  }
                                                                />
                                                              )}
                                                              {spouseLimitDisbaled ||
                                                              outsideSpousePlan
                                                                ? `N/A`
                                                                : isNaN(
                                                                    parseFloat(
                                                                      this.premiumInfoVertical(
                                                                        dependent,
                                                                        plan,
                                                                        dependent.dependent,
                                                                        isPruAgencyEnrolment
                                                                      )
                                                                    )
                                                                  )
                                                                ? `N/A`
                                                                : `S$${parseFloat(
                                                                    this.premiumInfoVertical(
                                                                      dependent,
                                                                      plan,
                                                                      dependent.dependent,
                                                                      isPruAgencyEnrolment
                                                                    )
                                                                  )
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(
                                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                                      ","
                                                                    )}/year`}
                                                            </Typography>
                                                          </CardContent>
                                                        </Card>
                                                      </Grid>
                                                    </Grid>
                                                  );
                                                },
                                                this
                                              )}
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    {user.dependencies
                                      .filter(
                                        dependent =>
                                          dependent.dependent != "SPOUSE"
                                      )
                                      .sort((a, b) =>
                                        a.full_name.localeCompare(b.full_name)
                                      )
                                      .map((dependent, dependentKey2) => {
                                        var coverageSelected = plans.filter(
                                          plan =>
                                            plan.planType === planHead.planType
                                        )[0];
                                        countDep = countDep + 1;
                                        this.handleBadgeOnDependent(
                                          planHead,
                                          countDep
                                        );
                                        return (
                                          <Grid
                                            item
                                            xs
                                            sm
                                            md
                                            key={dependentKey2}
                                          >
                                            {depRule[planHead.coverageId]
                                              .enableSpouseOnly != "true" && (
                                              <Grid container>
                                                <div
                                                  className={classes.stickyCard}
                                                  style={{
                                                    top: "90px",
                                                    width: "100%"
                                                  }}
                                                >
                                                  <Grid container>
                                                    <Grid
                                                      item
                                                      xs
                                                      sm
                                                      md
                                                      style={{
                                                        height: "120px"
                                                      }}
                                                    >
                                                      <Card
                                                        className={
                                                          classes.cardTopVerticalHeader
                                                        }
                                                        style={{
                                                          overflow: "auto"
                                                        }}
                                                      >
                                                        <CardContent
                                                          classes={{
                                                            root:
                                                              classes.cardRoot
                                                          }}
                                                          style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0px",
                                                            height: "100px"
                                                          }}
                                                        >
                                                          <Typography
                                                            component={"span"}
                                                            className={
                                                              classes.contentHeader
                                                            }
                                                            color="textSecondary"
                                                          >
                                                            <b>
                                                              {
                                                                dependent.full_name
                                                              }
                                                            </b>{" "}
                                                            <br />(
                                                            {this.capitalizeFLetter(
                                                              dependent.dependent
                                                            )}
                                                            )
                                                          </Typography>
                                                          <FormControlLabel
                                                            control={
                                                              <Switch
                                                                checked={
                                                                  this.state
                                                                    .dependentOptOutName &&
                                                                  this.state
                                                                    .dependentOptOutName
                                                                    .size != 0
                                                                    ? this.state.dependentOptOutName.get(
                                                                        dependent.id
                                                                      )
                                                                    : false
                                                                }
                                                                onChange={this.handleChangeSwitch(
                                                                  "name",
                                                                  dependent,
                                                                  planHead.planType
                                                                )}
                                                                value="checkedB"
                                                                color="primary"
                                                                disabled={
                                                                  this.state
                                                                    .planSelected &&
                                                                  this.state.planSelected.get(
                                                                    planHead.planType
                                                                  )
                                                                    ? false
                                                                    : true
                                                                }
                                                              />
                                                            }
                                                            label="Opt Out"
                                                          />
                                                        </CardContent>
                                                      </Card>
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                                {planHead.planDetails.map(
                                                  function(plan, keyTop) {
                                                    let disablePlan = false;
                                                    let childLimitDisbaled = false;
                                                    let disableEmployeeNonSelect = false;
                                                    let SATemp =
                                                      plan.details[0].overview
                                                        .details[0].details[0];
                                                    let outsideChildPlan = false;
                                                    let rateTemp = parseFloat(
                                                      this.premiumInfoVertical(
                                                        dependent,
                                                        plan,
                                                        dependent.dependent,
                                                        isPruAgencyEnrolment
                                                      )
                                                    ).toFixed(2);

                                                    if (isNaN(rateTemp)) {
                                                      outsideChildPlan = true;
                                                    }
                                                    if (
                                                      dependent.dependent ===
                                                        "CHILD" &&
                                                      parseFloat(
                                                        SATemp.replace(/,/g, "")
                                                      ).toFixed(2) > 100000
                                                    )
                                                      childLimitDisbaled = true;
                                                    if (
                                                      depRule[
                                                        planHead.coverageId
                                                      ].childCoverage &&
                                                      depRule[
                                                        planHead.coverageId
                                                      ].childCoverage ===
                                                        "downgrade"
                                                    ) {
                                                      plan.option <=
                                                      (this.state
                                                        .planSelected &&
                                                        this.state.planSelected.get(
                                                          planHead.planType
                                                        ) &&
                                                        this.state.planSelected.get(
                                                          planHead.planType
                                                        ).option)
                                                        ? (disablePlan = false)
                                                        : (disablePlan = true);
                                                    } else if (
                                                      depRule[
                                                        planHead.coverageId
                                                      ].childCoverage &&
                                                      depRule[
                                                        planHead.coverageId
                                                      ].childCoverage === "same"
                                                    ) {
                                                      plan.option ==
                                                      (this.state
                                                        .planSelected &&
                                                        this.state.planSelected.get(
                                                          planHead.planType
                                                        ) &&
                                                        this.state.planSelected.get(
                                                          planHead.planType
                                                        ).option)
                                                        ? (disablePlan = false)
                                                        : (disablePlan = true);
                                                    } else if (
                                                      depRule[
                                                        planHead.coverageId
                                                      ].childCoverage === "any"
                                                    ) {
                                                      disablePlan = false;
                                                    }

                                                    this.state.planSelected ===
                                                      "" ||
                                                    (this.state.planSelected &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ) &&
                                                      this.state.planSelected.get(
                                                        planHead.planType
                                                      ).option === "") ||
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ) === ""
                                                      ? (disableEmployeeNonSelect = true)
                                                      : (disableEmployeeNonSelect = false);
                                                    outsideChildPlan &&
                                                      this.handleChangeSwitch(
                                                        "name",
                                                        dependent,
                                                        planHead.planType
                                                      );
                                                    let height = { height: "" };
                                                    keyTop ===
                                                    planHead.planDetails
                                                      .length -
                                                      1
                                                      ? (height.height = "95%")
                                                      : (height.height =
                                                          "100%");
                                                    return (
                                                      <Grid
                                                        container
                                                        key={keyTop}
                                                      >
                                                        <Grid item xs sm md>
                                                          <Card
                                                            className={
                                                              classes.cardTopVertical
                                                            }
                                                            style={height}
                                                          >
                                                            <CardContent
                                                              classes={{
                                                                root:
                                                                  classes.cardRoot
                                                              }}
                                                              style={{
                                                                paddingLeft:
                                                                  "0px",
                                                                paddingRight:
                                                                  "0px"
                                                              }}
                                                            >
                                                              <Typography
                                                                component={
                                                                  "span"
                                                                }
                                                                className={
                                                                  classes.content
                                                                }
                                                                style={
                                                                  (this.state
                                                                    .dependentOptOutName &&
                                                                    this.state.dependentOptOutName.get(
                                                                      dependent.id
                                                                    )) ||
                                                                  disablePlan ||
                                                                  childLimitDisbaled ||
                                                                  outsideChildPlan
                                                                    ? {
                                                                        color:
                                                                          "grey"
                                                                      }
                                                                    : {
                                                                        color:
                                                                          "#000000"
                                                                      }
                                                                }
                                                              >
                                                                {!(
                                                                  childLimitDisbaled ||
                                                                  outsideChildPlan
                                                                ) && (
                                                                  <Radio
                                                                    checked={
                                                                      this.state
                                                                        .dependendentPlanSelected &&
                                                                      this.state.dependendentPlanSelected.get(
                                                                        coverageSelected.planType
                                                                      ) &&
                                                                      this.state.dependendentPlanSelected
                                                                        .get(
                                                                          coverageSelected.planType
                                                                        )
                                                                        .filter(
                                                                          planObj =>
                                                                            planObj
                                                                              .dependentType
                                                                              .id ===
                                                                              dependent.id &&
                                                                            planObj
                                                                              .planDep
                                                                              .header ===
                                                                              plan.header
                                                                        )
                                                                        .length >
                                                                        0
                                                                    }
                                                                    onChange={e =>
                                                                      this.handleDependentCardClick(
                                                                        coverageSelected.planType,
                                                                        plan,
                                                                        dependent
                                                                      )
                                                                    }
                                                                    value={
                                                                      plan
                                                                        .details[0]
                                                                        .overview
                                                                        .details[0]
                                                                        .header[0]
                                                                    }
                                                                    name="radio-button-demo"
                                                                    aria-label="A"
                                                                    color="primary"
                                                                    disabled={
                                                                      (this
                                                                        .state
                                                                        .dependentOptOutName &&
                                                                        this.state.dependentOptOutName.get(
                                                                          dependent.id
                                                                        )) ||
                                                                      disablePlan ||
                                                                      childLimitDisbaled ||
                                                                      disableEmployeeNonSelect ||
                                                                      outsideChildPlan
                                                                    }
                                                                  />
                                                                )}
                                                                {childLimitDisbaled ||
                                                                outsideChildPlan
                                                                  ? `N/A`
                                                                  : `S$${parseFloat(
                                                                      this.premiumInfoVertical(
                                                                        dependent,
                                                                        plan,
                                                                        dependent.dependent,
                                                                        isPruAgencyEnrolment
                                                                      )
                                                                    ).toFixed(
                                                                      2
                                                                    )}/year`}
                                                              </Typography>
                                                            </CardContent>
                                                          </Card>
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  },
                                                  this
                                                )}
                                              </Grid>
                                            )}
                                          </Grid>
                                        );
                                      })}
                                  </Grid>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </TabContainer>
                  </Paper>
                ) : (
                  <Paper
                    className={classes.expanded}
                    key={keyplanHead}
                    style={{ background: "#FFFFFF" }}
                  >
                    <TabContainer>
                      <div className={classes.root}>
                        <div>
                          <div
                            style={{ padding: "10px", paddingBottom: "30px" }}
                          >
                            <Typography
                              component={"span"}
                              className={classes.subHeader1Text}
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  coverageHeader &&
                                  coverageHeader({
                                    year: placeholderMapTemp.get("{year}")
                                  })
                              }}
                            />
                            <Typography
                              component={"span"}
                              className={classes.bottomNote}
                              style={{ whiteSpace: "pre-wrap" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  coverageDesc &&
                                  coverageDesc({
                                    year: placeholderMapTemp.get("{year}")
                                  })
                              }}
                            />
                          </div>
                          <div
                            className={classes.stickyCard}
                            style={{ top: "90px" }}
                          >
                            <Grid container>
                              <Grid item xs sm md>
                                <Card
                                  className={classes.cardTop}
                                  style={{ boxShadow: "none" }}
                                >
                                  <CardContent
                                    classes={{ root: classes.cardRoot }}
                                  >
                                    <Typography
                                      component={"span"}
                                      className={classes.title}
                                      color="textSecondary"
                                    />
                                  </CardContent>
                                </Card>
                              </Grid>
                              {this.props.user.previousPlans
                                .filter(
                                  plan =>
                                    plan.option ===
                                    this.props.user.previousSelectedPlan
                                )
                                .map(function(plan, keyTop) {
                                  return (
                                    <Grid item xs sm md key={keyTop}>
                                      <Card className={classes.cardTopDisabled}>
                                        <CardContent
                                          classes={{ root: classes.cardRoot }}
                                        >
                                          <Typography
                                            component={"span"}
                                            className={classes.cardTitle}
                                            color="textSecondary"
                                          >
                                            <b>
                                              Your{" "}
                                              {moment(
                                                this.props.user
                                                  .policy_start_date
                                              ).format("YYYY") - 1}{" "}
                                              Plan
                                            </b>
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  );
                                }, this)}
                              {planHead.planDetails.map(function(plan, keyTop) {
                                return (
                                  <Grid item xs sm md key={keyTop}>
                                    <Card
                                      className={
                                        this.state.planSelected &&
                                        this.state.planSelected.has(
                                          planHead.planType
                                        ) &&
                                        plan.header ===
                                          (this.state.planSelected &&
                                            this.state.planSelected.get(
                                              planHead.planType
                                            ) &&
                                            this.state.planSelected.get(
                                              planHead.planType
                                            ).header)
                                          ? classes.cardTopSelected
                                          : classes.cardTop
                                      }
                                      style={{ boxShadow: "none" }}
                                    >
                                      <CardContent
                                        classes={{ root: classes.cardRoot }}
                                      >
                                        <Typography
                                          component={"span"}
                                          className={classes.cardTitle}
                                          color="textSecondary"
                                          style={{
                                            fontWeight: "bold",
                                            display: "inline",
                                            color: `${
                                              this.state.planSelected &&
                                                this.state.planSelected.has(
                                                  planHead.planType
                                                ) &&
                                                plan.header ===
                                                  (this.state.planSelected &&
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ) &&
                                                    this.state.planSelected.get(
                                                      planHead.planType
                                                    ).header) &&
                                                "#000000"
                                              // : "#000000"
                                            }`
                                          }}
                                        >
                                          {plan.header}
                                        </Typography>
                                        <Typography
                                          component={"span"}
                                          style={{ color: "green" }}
                                        >
                                          {plan.coverageId ===
                                            this.state.defaultPlan.code &&
                                          this.state.defaultPlan.code &&
                                          plan.option ===
                                            this.state.defaultPlan.option &&
                                          this.state.defaultPlan.option
                                            ? "(Previous Year Plan)"
                                            : ""}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                );
                              }, this)}
                            </Grid>
                          </div>
                          <div className={classes.wrapper}>
                            {planHead.planDetails[0] &&
                              planHead.planDetails[0].details.map(function(
                                planDetails,
                                keyPlanDetails
                              ) {
                                return (
                                  <ExpansionPanel
                                    className={classes.expanded}
                                    key={keyPlanDetails}
                                    defaultExpanded={
                                      keyPlanDetails === 0 ? true : false
                                    }
                                  >
                                    <ExpansionPanelSummary
                                      className={classes.panelSummary}
                                      expandIcon={<ExpandMoreIcon />}
                                    >
                                      <Typography
                                        component={"span"}
                                        className={classes.summaryTitle}
                                      >
                                        <b>{planDetails.overview.header}</b>{" "}
                                      </Typography>
                                    </ExpansionPanelSummary>
                                    {planDetails.overview.details.map(function(
                                      overview,
                                      keyOverview
                                    ) {
                                      let styleGreen = false;
                                      if (overview.details[0] === "-") {
                                        styleGreen = true;
                                      }
                                      return (
                                        <ExpansionPanelDetails
                                          className={classes.root}
                                          key={keyOverview}
                                        >
                                          <Grid container>
                                            <Grid
                                              item
                                              xs
                                              sm
                                              md
                                              className={classes.gridBorder}
                                            >
                                              <Card
                                                className={classes.cardInner}
                                              >
                                                <CardContent
                                                  classes={{
                                                    root: classes.cardRoot
                                                  }}
                                                >
                                                  {overview.header.map(function(
                                                    header,
                                                    keyHeader
                                                  ) {
                                                    return (
                                                      <Typography
                                                        component={"span"}
                                                        className={
                                                          classes.content
                                                        }
                                                        style={{
                                                          color:
                                                            overview
                                                              .details[0] === ""
                                                              ? "green"
                                                              : "",
                                                          fontWeight:
                                                            overview
                                                              .details[0] === ""
                                                              ? 600
                                                              : ""
                                                        }}
                                                        color="textSecondary"
                                                        key={keyHeader}
                                                      >
                                                        {header}
                                                      </Typography>
                                                    );
                                                  },
                                                  this)}
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                            {this.props.user.previousPlans
                                              .filter(
                                                plan =>
                                                  plan.option ===
                                                  this.props.user
                                                    .previousSelectedPlan
                                              )
                                              .map(function(plan, keyTop) {
                                                return (
                                                  <Grid
                                                    item
                                                    xs
                                                    sm
                                                    md
                                                    className={
                                                      classes.gridBorder
                                                    }
                                                    key={keyTop}
                                                  >
                                                    <Card
                                                      className={
                                                        classes.cardDisabledInner
                                                      }
                                                    >
                                                      <CardContent
                                                        classes={{
                                                          root: classes.cardRoot
                                                        }}
                                                      >
                                                        {this.state
                                                          .previousPlan !== ""
                                                          ? this.state.previousPlan.details[
                                                              keyPlanDetails
                                                            ].overview.details[
                                                              keyOverview
                                                            ].details.map(
                                                              function(
                                                                prevOverview,
                                                                keyPrevOverview
                                                              ) {
                                                                return (
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    className={
                                                                      classes.content
                                                                    }
                                                                    color="textSecondary"
                                                                    key={
                                                                      keyPrevOverview
                                                                    }
                                                                  >
                                                                    {typeof prevOverview ===
                                                                    "string"
                                                                      ? prevOverview
                                                                      : this.createImage(
                                                                          prevOverview
                                                                        )}
                                                                  </Typography>
                                                                );
                                                              },
                                                              this
                                                            )
                                                          : planHead.planDetails[
                                                              keyplanHead
                                                            ].map(function(
                                                              plan,
                                                              keyPlan
                                                            ) {
                                                              return plan.details[
                                                                keyPlanDetails
                                                              ].overview.details[
                                                                keyOverview
                                                              ].details.map(
                                                                function(
                                                                  currOverview,
                                                                  keyCurrOverview
                                                                ) {
                                                                  return (
                                                                    <Typography
                                                                      key={
                                                                        keyCurrOverview
                                                                      }
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.content
                                                                      }
                                                                      color="textSecondary"
                                                                    />
                                                                  );
                                                                },
                                                                this
                                                              );
                                                            },
                                                            this)}
                                                      </CardContent>
                                                    </Card>
                                                  </Grid>
                                                );
                                              }, this)}
                                            {planHead.planDetails.map(function(
                                              plan,
                                              keyPlan
                                            ) {
                                              return (
                                                <Grid
                                                  item
                                                  xs
                                                  sm
                                                  md
                                                  className={classes.gridBorder}
                                                  key={keyPlan}
                                                >
                                                  <Card
                                                    className={
                                                      this.state.planSelected &&
                                                      this.state.planSelected.has(
                                                        planHead.planType
                                                      ) &&
                                                      plan.header ===
                                                        (this.state
                                                          .planSelected &&
                                                          this.state.planSelected.get(
                                                            planHead.planType
                                                          ) &&
                                                          this.state.planSelected.get(
                                                            planHead.planType
                                                          ).header)
                                                        ? classes.cardInnerSelected
                                                        : classes.cardInner
                                                    }
                                                  >
                                                    <CardContent
                                                      classes={{
                                                        root: classes.cardRoot
                                                      }}
                                                    >
                                                      {plan.details[
                                                        keyPlanDetails
                                                      ].overview.details[
                                                        keyOverview
                                                      ].details.map(function(
                                                        currOverview,
                                                        keyCurrOverview
                                                      ) {
                                                        return (
                                                          <Typography
                                                            key={
                                                              keyCurrOverview
                                                            }
                                                            component={"span"}
                                                            className={
                                                              classes.content
                                                            }
                                                            color="textSecondary"
                                                            style={{
                                                              color: `${
                                                                this.state
                                                                  .planSelected &&
                                                                  this.state.planSelected.has(
                                                                    planHead.planType
                                                                  ) &&
                                                                  plan.header ===
                                                                    (this.state
                                                                      .planSelected &&
                                                                      this.state.planSelected.get(
                                                                        planHead.planType
                                                                      ) &&
                                                                      this.state.planSelected.get(
                                                                        planHead.planType
                                                                      )
                                                                        .header) &&
                                                                  "#6B6A6D"
                                                                // : "#6B6A6D"
                                                              }`
                                                            }}
                                                          >
                                                            {typeof currOverview ===
                                                            "string"
                                                              ? currOverview
                                                              : this.createImage(
                                                                  currOverview
                                                                )}
                                                          </Typography>
                                                        );
                                                      },
                                                      this)}
                                                    </CardContent>
                                                  </Card>
                                                </Grid>
                                              );
                                            },
                                            this)}
                                          </Grid>
                                        </ExpansionPanelDetails>
                                      );
                                    },
                                    this)}
                                  </ExpansionPanel>
                                );
                              },
                              this)}
                          </div>
                          {this.employeeEnrolmentCheck(planHead.coverageId) &&
                          this.employeeEnrolmentCheck(planHead.coverageId)
                            .employeeEnrole !== "true" &&
                          this.employeeEnrolmentCheck(planHead.coverageId)
                            .planSelection === "ALLIN" ? (
                            <div>
                              <div style={{ marginTop: "25px" }}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs
                                    sm
                                    md
                                    style={{
                                      background: "rgba(228, 227, 228, 0.7)",
                                      padding: "15px"
                                    }}
                                  >
                                    <Typography
                                      component={"span"}
                                      style={{
                                        fontSize: "16px",
                                        color: "#6B6A6D"
                                      }}
                                    >
                                      {/* <b style={{color:'#000000'}}>{user.fullName}</b> (Employee) */}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  style={{ background: "#F6F6F6" }}
                                >
                                  <Grid
                                    item
                                    xs
                                    sm
                                    md
                                    style={{ borderRight: "1px solid #E4E3E4" }}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Typography
                                      component={"span"}
                                      style={{
                                        color: "#6B6A6D",
                                        fontSize: "16px",
                                        textAlign: "left",
                                        padding: "5px 20px"
                                      }}
                                    >
                                      {user.client_name.config.hideAnnual !=
                                      "True"
                                        ? "Annual "
                                        : ""}
                                      Premium Per Insured Member
                                    </Typography>
                                    <Typography
                                      component={"span"}
                                      style={{
                                        color: "#6B6A6D",
                                        fontSize: "13px",
                                        textAlign: "left",
                                        padding: "5px 20px"
                                      }}
                                    >
                                      (*** Premium is waived for 5th dependant
                                      onwards for Inpatient and Combo plans.)
                                    </Typography>
                                    <Typography
                                      component={"span"}
                                      style={{
                                        color: "#FF0000",
                                        fontSize: "14.5px",
                                        fontWeight: "bold",
                                        fontStyle: "italic",
                                        textAlign: "left",
                                        padding: "5px 20px"
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: policyPeriod({
                                          policyStartDate: placeholderMapTemp.get(
                                            "{policyStart}"
                                          ),
                                          policyEndDate: placeholderMapTemp.get(
                                            "{policyEnd}"
                                          )
                                        })
                                      }}
                                    />
                                    <Button
                                      size="small"
                                      disabled
                                      color="secondary"
                                    >
                                      {" "}
                                    </Button>
                                  </Grid>
                                  {planHead.planDetails.map(function(
                                    plan,
                                    keyBottom
                                  ) {
                                    return (
                                      <Grid
                                        item
                                        xs
                                        sm
                                        md
                                        key={keyBottom}
                                        style={{
                                          borderRight: "1px solid #E4E3E4",
                                          textAlign: "center"
                                        }}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                      >
                                        <Typography component={"span"}>
                                          <span className={classes.premiumText}>
                                            S$
                                            {parseFloat(
                                              this.premiumInfoAllIn(
                                                planHead,
                                                plan
                                              )
                                            ).toFixed(2)}
                                          </span>
                                          {user.client_name.config.hideAnnual !=
                                          "True"
                                            ? "/year "
                                            : ""}
                                        </Typography>
                                        <Button
                                          style={{ padding: "0px" }}
                                          onClick={this.handleCardClickAllIn.bind(
                                            this,
                                            "Select",
                                            plan,
                                            planHead.planType
                                          )}
                                        >
                                          {this.state.planSelected &&
                                          this.state.planSelected.has(
                                            planHead.planType
                                          ) &&
                                          plan.header ===
                                            (this.state.planSelected &&
                                              this.state.planSelected.get(
                                                planHead.planType
                                              ) &&
                                              this.state.planSelected.get(
                                                planHead.planType
                                              ).header) ? (
                                            <Typography
                                              component={"span"}
                                              className={classes.buttonSelect}
                                            >
                                              SELECTED
                                            </Typography>
                                          ) : (
                                            <Typography
                                              component={"span"}
                                              className={classes.buttonNormal}
                                            >
                                              SELECT
                                            </Typography>
                                          )}
                                        </Button>
                                      </Grid>
                                    );
                                  },
                                  this)}
                                </Grid>
                              </div>
                              {user.dependencies
                                .filter(
                                  dependent => dependent.dependent === "SPOUSE"
                                )
                                .map((dependent, dependentKey2) => {
                                  // var coverageSelected = plans.filter(plan => plan.planType === planHead.planType)[0];
                                  // let dependentSelection = this.state.dependendentPlanSelected && this.state.dependendentPlanSelected.get(planHead.planType) && this.state.dependendentPlanSelected.get(planHead.planType).filter(planObj => planObj.dependentType.id === dependent.id )[0];
                                  // let dependentOptOut = dependentSelection && dependentSelection.planDep === "Opt Out" ? true : false;
                                  countDep = countDep + 1;
                                  this.handleBadgeOnDependent(
                                    planHead,
                                    countDep
                                  );
                                  return (
                                    this.state.planSelected &&
                                    this.state.planSelected.size != 0 && (
                                      <div
                                        style={{ marginTop: "25px" }}
                                        key={dependentKey2}
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs
                                            sm
                                            md
                                            style={{
                                              background:
                                                "rgba(228, 227, 228, 0.7)"
                                            }}
                                          >
                                            <Typography
                                              component={"span"}
                                              style={{
                                                fontSize: "16px",
                                                color: "#6B6A6D",
                                                padding: "15px",
                                                float: "left"
                                              }}
                                            >
                                              <b style={{ color: "#000000" }}>
                                                {dependent.full_name}
                                              </b>{" "}
                                              (
                                              {this.capitalizeFLetter(
                                                dependent.dependent
                                              )}
                                              )
                                            </Typography>
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    this.state
                                                      .dependentOptOutName &&
                                                    this.state
                                                      .dependentOptOutName
                                                      .size != 0
                                                      ? this.state.dependentOptOutName.get(
                                                          dependent.id
                                                        )
                                                      : false
                                                  }
                                                  onChange={this.handleChangeSwitch(
                                                    "ALLIN",
                                                    dependent,
                                                    planHead.planType
                                                  )}
                                                  value=""
                                                  color="primary"
                                                  label="Opt Out"
                                                  classes={{
                                                    root: classes.switchRoot
                                                  }}
                                                />
                                              }
                                              classes={{
                                                root: classes.switchRoot
                                              }}
                                              label="Opt Out"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    )
                                  );
                                })}
                              {user.dependencies
                                .filter(
                                  dependent => dependent.dependent === "CHILD"
                                )
                                .sort((a, b) =>
                                  a.full_name.localeCompare(b.full_name)
                                )
                                .map((dependent, dependentKey2) => {
                                  countDep = countDep + 1;
                                  this.handleBadgeOnDependent(
                                    planHead,
                                    countDep
                                  );
                                  return (
                                    this.state.planSelected &&
                                    this.state.planSelected.size != 0 && (
                                      <div
                                        style={{ marginTop: "25px" }}
                                        key={dependentKey2}
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs
                                            sm
                                            md
                                            style={{
                                              background:
                                                "rgba(228, 227, 228, 0.7)"
                                            }}
                                          >
                                            <Typography
                                              component={"span"}
                                              style={{
                                                fontSize: "16px",
                                                color: "#6B6A6D",
                                                padding: "15px",
                                                float: "left"
                                              }}
                                            >
                                              <b style={{ color: "#000000" }}>
                                                {dependent.full_name}
                                              </b>{" "}
                                              (
                                              {this.capitalizeFLetter(
                                                dependent.dependent
                                              )}
                                              )
                                            </Typography>
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={
                                                    this.state
                                                      .dependentOptOutName &&
                                                    this.state
                                                      .dependentOptOutName
                                                      .size != 0
                                                      ? this.state.dependentOptOutName.get(
                                                          dependent.id
                                                        )
                                                      : false
                                                  }
                                                  onChange={this.handleChangeSwitch(
                                                    "ALLIN",
                                                    dependent,
                                                    planHead.planType
                                                  )}
                                                  value=""
                                                  color="primary"
                                                  label="Opt Out"
                                                  classes={{
                                                    root: classes.switchRoot
                                                  }}
                                                />
                                              }
                                              classes={{
                                                root: classes.switchRoot
                                              }}
                                              label="Opt Out"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    )
                                  );
                                })}
                            </div>
                          ) : (
                            <div style={{ marginTop: "25px" }}>
                              <Grid container>
                                <Grid
                                  item
                                  xs
                                  sm
                                  md
                                  style={{
                                    background: "rgba(228, 227, 228, 0.7)",
                                    padding: "15px"
                                  }}
                                >
                                  <Typography
                                    component={"span"}
                                    style={{
                                      fontSize: "16px",
                                      color: "#6B6A6D"
                                    }}
                                  >
                                    <b style={{ color: "#000000" }}>
                                      {user.fullName}
                                    </b>{" "}
                                    (Self)
                                    {(this.state.isPruAgencyMedEnrolment ||
                                      planHead.planType ===
                                        "Group Crisis Cover Extra (GCCE) Benefits") && (
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={
                                              this.props.user.client_name.config
                                                .isPruAgencyMedEnrolment ===
                                                "True" ||
                                              planHead.planType ===
                                                "Group Crisis Cover Extra (GCCE) Benefits"
                                                ? this.state.planSelected !==
                                                    "" &&
                                                  this.state.planSelected.has(
                                                    planHead.planType
                                                  ) &&
                                                  this.state.planSelected.get(
                                                    planHead.planType
                                                  ) === "Opt Out"
                                                : false
                                            }
                                            onChange={this.handleChangeEmployeeSwitch(
                                              planHead.planType ===
                                                "Group Crisis Cover Extra (GCCE) Benefits"
                                                ? "employeeONLY"
                                                : "name",
                                              user,
                                              planHead.planType
                                            )}
                                            disabled={
                                              this.state
                                                .disallowNextPlanSelection
                                            }
                                            value="checkedB"
                                            color="primary"
                                          />
                                        }
                                        classes={{
                                          root: classes.switchRoot
                                        }}
                                        label="Opt Out"
                                      />
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container style={{ background: "#F6F6F6" }}>
                                <Grid
                                  item
                                  xs
                                  sm
                                  md
                                  style={{ borderRight: "1px solid #E4E3E4" }}
                                >
                                  <Typography
                                    component={"span"}
                                    style={{
                                      color: "#6B6A6D",
                                      fontSize: "16px",
                                      textAlign: "left",
                                      padding: "20px"
                                    }}
                                  >
                                    Annual Premium
                                  </Typography>
                                  <Button
                                    size="small"
                                    disabled
                                    color="secondary"
                                  >
                                    {" "}
                                  </Button>
                                </Grid>
                                {planHead.planDetails.map(function(
                                  plan,
                                  keyBottom
                                ) {
                                  let disablePlan = false;
                                  let childOnlyPlan = this.props.user.depRule[
                                    planHead.coverageId
                                  ].childOnlyOnePlan;
                                  childOnlyPlan !== undefined &&
                                  childOnlyPlan == plan.option
                                    ? (disablePlan = true)
                                    : (disablePlan = false);
                                  let planOptOut =
                                    this.state.isPruAgencyMedEnrolment &&
                                    this.props.user.coverage_details
                                      .standAloneGMM === true
                                      ? this.state.planSelected &&
                                        this.state.planSelected.has(
                                          planHead.planType
                                        ) &&
                                        this.state.planSelected.get(
                                          planHead.planType
                                        ) === "Opt Out" &&
                                        planHead.planType !==
                                          "Group Extended Major Medical Rider (GMM) Benefits"
                                        ? true
                                        : false
                                      : this.state.planSelected &&
                                        this.state.planSelected.has(
                                          planHead.planType
                                        ) &&
                                        this.state.planSelected.get(
                                          planHead.planType
                                        ) === "Opt Out"
                                      ? true
                                      : false;
                                  let enableGMMAlone =
                                    this.state.planSelected &&
                                    this.state.planSelected.get(
                                      "Group Extended Major Medical Rider (GMM) Benefits"
                                    ) &&
                                    this.state.planSelected.get(
                                      "Group Hospital & Surgical (GHS) Benefits"
                                    ) === "Opt Out" &&
                                    this.props.user.coverage_details
                                      .standAloneGMM === true &&
                                    planHead.planType ===
                                      "Group Extended Major Medical Rider (GMM) Benefits"
                                      ? false
                                      : true;

                                  return disablePlan ||
                                    planOptOut ||
                                    (this.state.isPruAgencyMedEnrolment &&
                                      this.state.disallowNextPlanSelection &&
                                      enableGMMAlone) ? (
                                    <Grid
                                      item
                                      xs
                                      sm
                                      md
                                      key={keyBottom}
                                      style={{
                                        borderRight: "1px solid #E4E3E4",
                                        textAlign: "center"
                                      }}
                                    >
                                      <Typography
                                        component={"span"}
                                        style={{
                                          fontSize: "16px",
                                          textAlign: "center",
                                          padding: "20px",
                                          fontWeight: 600
                                        }}
                                      >
                                        N/A
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs
                                      sm
                                      md
                                      key={keyBottom}
                                      style={{
                                        borderRight: "1px solid #E4E3E4",
                                        textAlign: "center"
                                      }}
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                    >
                                      <Typography component={"span"}>
                                        <span className={classes.premiumText}>
                                          S$
                                          {parseFloat(
                                            this.premiumInfo(planHead, plan)
                                          ).toFixed(2)}
                                        </span>
                                        /year
                                      </Typography>
                                      <Button
                                        style={{ padding: "0px" }}
                                        disabled={
                                          this.state.disallowNextPlanSelection
                                        }
                                        onClick={this.handleCardClick.bind(
                                          this,
                                          plan,
                                          planHead
                                        )}
                                      >
                                        {this.state.planSelected &&
                                        this.state.planSelected.has(
                                          planHead.planType
                                        ) &&
                                        plan.header ===
                                          (this.state.planSelected &&
                                            this.state.planSelected.get(
                                              planHead.planType
                                            ) &&
                                            this.state.planSelected.get(
                                              planHead.planType
                                            ).header) ? (
                                          <Typography
                                            component={"span"}
                                            className={classes.buttonSelect}
                                          >
                                            SELECTED
                                          </Typography>
                                        ) : (
                                          <Typography
                                            component={"span"}
                                            className={classes.buttonNormal}
                                          >
                                            SELECT
                                          </Typography>
                                        )}
                                      </Button>
                                    </Grid>
                                  );
                                },
                                this)}
                              </Grid>
                            </div>
                          )}
                          {user.dependencies.length > 0 &&
                            this.state.planSelected &&
                            this.state.planSelected.get(planHead.planType) &&
                            (() => {
                              let ghsPlan =
                                planHead.planType ===
                                  "Group Hospital & Surgical (GHS) Benefits" &&
                                this.state.planSelected.get(
                                  "Group Hospital & Surgical (GHS) Benefits"
                                );
                              let isForeignPlan =
                                ghsPlan.option === "009" ? true : false;

                              switch (
                                depRule[planHead.coverageId].selectionMethod
                              ) {
                                case "relation":
                                  return (
                                    this.dependentEnrolmentCheck(
                                      planHead.coverageId
                                    ) === "true" && (
                                      <div
                                        key={
                                          depRule[planHead.coverageId]
                                            .selectionMethod
                                        }
                                      >
                                        {
                                          <div style={{ marginTop: "25px" }}>
                                            <Grid container>
                                              <Grid
                                                item
                                                xs
                                                sm
                                                md
                                                style={{
                                                  background:
                                                    "rgba(228, 227, 228, 0.7)"
                                                }}
                                              >
                                                <Typography
                                                  component={"span"}
                                                  style={{
                                                    fontSize: "16px",
                                                    color: "#6B6A6D",
                                                    padding: "20px",
                                                    float: "left"
                                                  }}
                                                >
                                                  Select Coverage for
                                                </Typography>
                                                <form
                                                  className={classes.root}
                                                  autoComplete="off"
                                                >
                                                  <FormControl
                                                    required
                                                    className={
                                                      classes.formControl
                                                    }
                                                    style={{ float: "left" }}
                                                  >
                                                    <Select
                                                      value={
                                                        this.state.dependent ||
                                                        "Dependants"
                                                      }
                                                      onChange={this.handleChangeSelect(
                                                        planHead
                                                      )}
                                                      name="dependent"
                                                      displayEmpty
                                                      inputProps={{
                                                        id: "dependent-required"
                                                      }}
                                                      className={
                                                        classes.selectEmpty
                                                      }
                                                      disabled={
                                                        (this.state
                                                          .dependentOptOut &&
                                                          this.state.dependentOptOut.get(
                                                            planHead.planType
                                                          )) ||
                                                        this.state
                                                          .relationReadOnly
                                                      }
                                                    >
                                                      <MenuItem value="Dependants">
                                                        <em>Dependants</em>
                                                      </MenuItem>
                                                      {user.dependencies.filter(
                                                        dependents =>
                                                          dependents.dependent ===
                                                          "SPOUSE"
                                                      ).length > 0 ? (
                                                        <MenuItem
                                                          value="Spouse Only"
                                                          style={{
                                                            fontWeight: 600,
                                                            paddingRight: "40px"
                                                          }}
                                                        >
                                                          Spouse Only
                                                          {this.props.user
                                                            .client_name.config
                                                            .toolTipShow ===
                                                            "True" &&
                                                          this.props.user
                                                            .client_name.config
                                                            .spouseTooltip !==
                                                            "" ? (
                                                            <Tooltip
                                                              classes={{
                                                                tooltip:
                                                                  classes.toolTip
                                                              }}
                                                              title={
                                                                this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .spouseTooltip !==
                                                                undefined
                                                                  ? this.props
                                                                      .user
                                                                      .client_name
                                                                      .config
                                                                      .spouseTooltip
                                                                  : ""
                                                              }
                                                              placement="top"
                                                            >
                                                              <InfoIcon
                                                                style={{
                                                                  color:
                                                                    "rgba(0, 0, 0, 0.54)"
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </MenuItem>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {user.dependencies.filter(
                                                        dependents =>
                                                          dependents.dependent ===
                                                          "CHILD"
                                                      ).length > 0 ? (
                                                        <MenuItem
                                                          value="Child(ren) Only"
                                                          style={{
                                                            fontWeight: 600
                                                          }}
                                                        >
                                                          Child(ren) Only
                                                          {this.props.user
                                                            .client_name.config
                                                            .toolTipShow ===
                                                            "True" &&
                                                          this.props.user
                                                            .client_name.config
                                                            .childTooltip !==
                                                            "" ? (
                                                            <Tooltip
                                                              classes={{
                                                                tooltip:
                                                                  classes.toolTip
                                                              }}
                                                              title={
                                                                this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .childTooltip !==
                                                                undefined
                                                                  ? this.props
                                                                      .user
                                                                      .client_name
                                                                      .config
                                                                      .childTooltip
                                                                  : ""
                                                              }
                                                              placement="top"
                                                            >
                                                              <InfoIcon
                                                                style={{
                                                                  color:
                                                                    "rgba(0, 0, 0, 0.54)"
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </MenuItem>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {user.dependencies.filter(
                                                        dependents =>
                                                          dependents.dependent ===
                                                          "CHILD"
                                                      ).length > 0 &&
                                                      user.dependencies.filter(
                                                        dependents =>
                                                          dependents.dependent ===
                                                          "SPOUSE"
                                                      ).length > 0 ? (
                                                        <MenuItem
                                                          value="Spouse & Child(ren)"
                                                          style={{
                                                            fontWeight: 600
                                                          }}
                                                        >
                                                          Spouse{" "}
                                                          {this.props.user
                                                            .client_name.config
                                                            .toolTipShow ===
                                                            "True" &&
                                                          this.props.user
                                                            .client_name.config
                                                            .spouseTooltip !==
                                                            "" ? (
                                                            <Tooltip
                                                              classes={{
                                                                tooltip:
                                                                  classes.toolTip
                                                              }}
                                                              title={
                                                                this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .spouseTooltip !==
                                                                undefined
                                                                  ? this.props
                                                                      .user
                                                                      .client_name
                                                                      .config
                                                                      .spouseTooltip
                                                                  : ""
                                                              }
                                                              placement="top"
                                                            >
                                                              <InfoIcon
                                                                style={{
                                                                  color:
                                                                    "rgba(0, 0, 0, 0.54)"
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          ) : (
                                                            ""
                                                          )}{" "}
                                                          & Child(ren){" "}
                                                          {this.props.user
                                                            .client_name.config
                                                            .toolTipShow ===
                                                            "True" &&
                                                          this.props.user
                                                            .client_name.config
                                                            .childTooltip !==
                                                            "" ? (
                                                            <Tooltip
                                                              classes={{
                                                                tooltip:
                                                                  classes.toolTip
                                                              }}
                                                              title={
                                                                this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .childTooltip !==
                                                                undefined
                                                                  ? this.props
                                                                      .user
                                                                      .client_name
                                                                      .config
                                                                      .childTooltip
                                                                  : ""
                                                              }
                                                              placement="top"
                                                            >
                                                              <InfoIcon
                                                                style={{
                                                                  color:
                                                                    "rgba(0, 0, 0, 0.54)"
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {/* {this.props.user
                                                            .client_name.config
                                                            .toolTipShow ===
                                                            "True" &&
                                                          this.props.user
                                                            .client_name.config
                                                            .spouseAndChildToolTip !==
                                                            "" ? (
                                                            <Tooltip
                                                              classes={{
                                                                tooltip:
                                                                  classes.toolTip
                                                              }}
                                                              title={
                                                                this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .spouseAndChildToolTip !==
                                                                undefined
                                                                  ? this.props
                                                                      .user
                                                                      .client_name
                                                                      .config
                                                                      .spouseAndChildToolTip
                                                                  : ""
                                                              }
                                                              placement="top"
                                                            >
                                                              <InfoIcon
                                                                style={{
                                                                  color:
                                                                    "rgba(0, 0, 0, 0.54)"
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          ) : (
                                                            ""
                                                          )} */}
                                                        </MenuItem>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Select>
                                                  </FormControl>
                                                </form>
                                                {depRule[planHead.coverageId]
                                                  .autoEnrollRelation ? (
                                                  ""
                                                ) : (
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .dependentOptOut &&
                                                          this.state.dependentOptOut.get(
                                                            planHead.planType
                                                          )
                                                        }
                                                        onChange={this.handleChangeSwitch(
                                                          "relation",
                                                          "Dependants",
                                                          planHead.planType
                                                        )}
                                                        value="checkedB"
                                                        color="primary"
                                                      />
                                                    }
                                                    classes={{
                                                      root: classes.switchRoot
                                                    }}
                                                    label="Opt Out"
                                                  />
                                                )}
                                                {depRule[planHead.coverageId]
                                                  .autoEnrollRelation &&
                                                depRule[planHead.coverageId]
                                                  .enableOptOut ? (
                                                  <FormControlLabel
                                                    control={
                                                      <Switch
                                                        checked={
                                                          this.props.user
                                                            .client_name.config
                                                            .isPruAgencyMedEnrolment ===
                                                          "True"
                                                            ? this.state
                                                                .planSelected &&
                                                              this.state.planSelected.has(
                                                                planHead.planType
                                                              ) &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ) === "Opt Out"
                                                              ? true
                                                              : this.props.user
                                                                  .client_name
                                                                  .config
                                                                  .isPruAgencyMedEnrolment ===
                                                                  "True" &&
                                                                this.state
                                                                  .dependendentPlanSelected &&
                                                                this.state.dependendentPlanSelected.has(
                                                                  planHead.planType
                                                                ) &&
                                                                this.state.dependendentPlanSelected.get(
                                                                  planHead.planType
                                                                ).length > 0 &&
                                                                this.state.dependendentPlanSelected.get(
                                                                  planHead.planType
                                                                )[0].planDep ===
                                                                  "Opt Out"
                                                              ? true
                                                              : false
                                                            : this.state
                                                                .dependentOptOut &&
                                                              this.state.dependentOptOut.get(
                                                                planHead.planType
                                                              )
                                                            ? true
                                                            : false
                                                        }
                                                        disabled={
                                                          this.props.user
                                                            .client_name.config
                                                            .isPruAgencyMedEnrolment ===
                                                          "True"
                                                            ? (this.state.planSelected.has(
                                                                planHead.planType
                                                              ) &&
                                                                this.state.planSelected.get(
                                                                  planHead.planType
                                                                ) ===
                                                                  "Opt Out") ||
                                                              (planHead.planType ===
                                                                "Group Extended Major Medical Rider (GMM) Benefits" &&
                                                                (dependentGHSOptOut ||
                                                                  dependentHasGhsPlan)) ||
                                                              (planHead.planType ===
                                                                "Group Extended Major Medical Rider (GMM) Benefits" &&
                                                                this.state.planSelected.has(
                                                                  "Group Hospital & Surgical (GHS) Benefits"
                                                                ) &&
                                                                (this.state.planSelected.get(
                                                                  "Group Hospital & Surgical (GHS) Benefits"
                                                                ) ===
                                                                  "Opt Out" ||
                                                                  this.state.planSelected.get(
                                                                    "Group Hospital & Surgical (GHS) Benefits"
                                                                  ) === ""))
                                                              ? true
                                                              : false
                                                            : false
                                                        }
                                                        // onChange={this.handleChangeSwitch(
                                                        //   "relation",
                                                        //   "Dependants",
                                                        //   planHead.planType
                                                        // )}
                                                        onChange={
                                                          this.state
                                                            .isPruAgencyMedEnrolment
                                                            ? user.dependencies.filter(
                                                                dependents =>
                                                                  dependents.dependent ===
                                                                  "CHILD"
                                                              ).length > 0 &&
                                                              user.dependencies.filter(
                                                                dependents =>
                                                                  dependents.dependent ===
                                                                  "SPOUSE"
                                                              ).length > 0
                                                              ? this.handleChangeSwitch(
                                                                  "relation",
                                                                  "Spouse & Child(ren)",
                                                                  planHead.planType
                                                                )
                                                              : user.dependencies.filter(
                                                                  dependents =>
                                                                    dependents.dependent ===
                                                                    "SPOUSE"
                                                                ).length > 0
                                                              ? this.handleChangeSwitch(
                                                                  "relation",
                                                                  "Spouse Only",
                                                                  planHead.planType
                                                                )
                                                              : this.handleChangeSwitch(
                                                                  "relation",
                                                                  "Child(ren) Only",
                                                                  planHead.planType
                                                                )
                                                            : this.handleChangeSwitch(
                                                                "relation",
                                                                "Dependants",
                                                                planHead.planType
                                                              )
                                                        }
                                                        value="checkedB"
                                                        color="primary"
                                                      />
                                                    }
                                                    classes={{
                                                      root: classes.switchRoot
                                                    }}
                                                    label="Opt Out"
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Grid>
                                            </Grid>
                                            <Grid
                                              container
                                              style={
                                                (this.state.dependentOptOut &&
                                                  this.state.dependentOptOut.get(
                                                    planHead.planType
                                                  )) ||
                                                this.state.dependent ===
                                                  "Dependants"
                                                  ? {
                                                      background: "#F6F6F6",
                                                      display: "none"
                                                    }
                                                  : { background: "#F6F6F6" }
                                              }
                                            >
                                              <Grid
                                                item
                                                xs
                                                sm
                                                md
                                                style={{
                                                  borderRight:
                                                    "1px solid #E4E3E4"
                                                }}
                                              >
                                                <Typography
                                                  component={"span"}
                                                  style={{
                                                    color: "#6B6A6D",
                                                    fontSize: "16px",
                                                    textAlign: "left",
                                                    padding: "20px"
                                                  }}
                                                >
                                                  Annual Premium
                                                </Typography>
                                                <Button
                                                  size="small"
                                                  color="secondary"
                                                >
                                                  {" "}
                                                </Button>
                                              </Grid>
                                              {(() => {
                                                switch (
                                                  depRule[planHead.coverageId]
                                                    .dependentCoverage
                                                ) {
                                                  case "same":
                                                    var coverageSelected = plans.filter(
                                                      plan =>
                                                        plan.planType ===
                                                        planHead.planType
                                                    )[0];
                                                    countDep = countDep + 1;
                                                    this.handleBadgeOnDependent(
                                                      planHead,
                                                      countDep
                                                    );
                                                    return planHead.planDetails.map(
                                                      function(
                                                        plan,
                                                        keyBottom
                                                      ) {
                                                        let disablePlan = false;

                                                        if (
                                                          this.state
                                                            .isPruAgencyMedEnrolment
                                                        ) {
                                                          if (
                                                            this.state
                                                              .dependendentPlanSelected &&
                                                            this.state.dependendentPlanSelected.has(
                                                              coverageSelected.planType
                                                            ) &&
                                                            this.state.dependendentPlanSelected.get(
                                                              coverageSelected.planType
                                                            )[0] &&
                                                            this.state.dependendentPlanSelected.get(
                                                              coverageSelected.planType
                                                            )[0].planDep ===
                                                              "Opt Out"
                                                          ) {
                                                            disablePlan = true;
                                                          } else if (
                                                            this.state
                                                              .planSelected
                                                          ) {
                                                            plan.option !==
                                                            this.state.planSelected.get(
                                                              coverageSelected.planType
                                                            ).option
                                                              ? (disablePlan = true)
                                                              : (disablePlan = false);
                                                          }
                                                        } else {
                                                          this.state
                                                            .dependent !=
                                                          "Dependants"
                                                            ? plan.header ===
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).header
                                                              ? (disablePlan = false)
                                                              : (disablePlan = true)
                                                            : (disablePlan = true);
                                                        }

                                                        return disablePlan ? (
                                                          <Grid
                                                            item
                                                            xs
                                                            sm
                                                            md
                                                            key={keyBottom}
                                                            style={{
                                                              borderRight:
                                                                "1px solid #E4E3E4",
                                                              textAlign:
                                                                "center"
                                                            }}
                                                          >
                                                            <Typography
                                                              component={"span"}
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                                textAlign:
                                                                  "center",
                                                                padding: "20px",
                                                                fontWeight: 600
                                                              }}
                                                            >
                                                              N/A
                                                            </Typography>
                                                          </Grid>
                                                        ) : (
                                                          <Grid
                                                            item
                                                            xs
                                                            sm
                                                            md
                                                            key={keyBottom}
                                                            style={{
                                                              borderRight:
                                                                "1px solid #E4E3E4",
                                                              textAlign:
                                                                "center"
                                                            }}
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems="center"
                                                          >
                                                            <Typography
                                                              component={"span"}
                                                            >
                                                              <span
                                                                className={
                                                                  classes.premiumText
                                                                }
                                                              >
                                                                S$
                                                                {parseFloat(
                                                                  this.premiumInfoDep(
                                                                    planHead.planType,
                                                                    plan,
                                                                    this.state
                                                                      .dependent
                                                                  )
                                                                ).toFixed(2)}
                                                              </span>
                                                              /year
                                                            </Typography>
                                                            <Button
                                                              style={{
                                                                padding: "0px"
                                                              }}
                                                              disabled={
                                                                disablePlan
                                                              }
                                                              onClick={e =>
                                                                this.handleDependentCardClick(
                                                                  coverageSelected.planType,
                                                                  plan,
                                                                  this.state
                                                                    .dependent
                                                                )
                                                              }
                                                            >
                                                              {this.state
                                                                .dependendentPlanSelected &&
                                                              this.state.dependendentPlanSelected.get(
                                                                coverageSelected.planType
                                                              ) &&
                                                              this.state.dependendentPlanSelected
                                                                .get(
                                                                  coverageSelected.planType
                                                                )
                                                                .filter(
                                                                  planObj =>
                                                                    planObj.dependentType ===
                                                                      this.state
                                                                        .dependent &&
                                                                    planObj
                                                                      .planDep
                                                                      .header ===
                                                                      plan.header
                                                                ).length > 0 ? (
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.buttonSelect
                                                                  }
                                                                >
                                                                  SELECTED
                                                                </Typography>
                                                              ) : (
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.buttonNormal
                                                                  }
                                                                >
                                                                  SELECT
                                                                </Typography>
                                                              )}
                                                            </Button>
                                                          </Grid>
                                                        );
                                                      },
                                                      this
                                                    );
                                                  case "downgrade":
                                                    var coverageSelected = plans.filter(
                                                      plan =>
                                                        plan.planType ===
                                                        planHead.planType
                                                    )[0];
                                                    countDep = countDep + 1;
                                                    this.handleBadgeOnDependent(
                                                      planHead,
                                                      countDep
                                                    );
                                                    return planHead.planDetails.map(
                                                      function(
                                                        plan,
                                                        keyBottom
                                                      ) {
                                                        let disablePlan = false;
                                                        this.state.dependent !=
                                                        "Dependants"
                                                          ? plan.option <=
                                                            this.state.planSelected.get(
                                                              planHead.planType
                                                            ).option
                                                            ? (disablePlan = false)
                                                            : (disablePlan = true)
                                                          : (disablePlan = true);
                                                        return disablePlan ? (
                                                          <Grid
                                                            item
                                                            xs
                                                            sm
                                                            md
                                                            key={keyBottom}
                                                            style={{
                                                              borderRight:
                                                                "1px solid #E4E3E4",
                                                              textAlign:
                                                                "center"
                                                            }}
                                                          >
                                                            <Typography
                                                              component={"span"}
                                                              style={{
                                                                fontSize:
                                                                  "16px",
                                                                textAlign:
                                                                  "center",
                                                                padding: "20px",
                                                                fontWeight: 600
                                                              }}
                                                            >
                                                              N/A
                                                            </Typography>
                                                          </Grid>
                                                        ) : (
                                                          <Grid
                                                            item
                                                            xs
                                                            sm
                                                            md
                                                            key={keyBottom}
                                                            style={{
                                                              borderRight:
                                                                "1px solid #E4E3E4",
                                                              textAlign:
                                                                "center"
                                                            }}
                                                            display="flex"
                                                            flexDirection="column"
                                                            alignItems="center"
                                                          >
                                                            <Typography
                                                              component={"span"}
                                                            >
                                                              <span
                                                                className={
                                                                  classes.premiumText
                                                                }
                                                              >
                                                                S$
                                                                {parseFloat(
                                                                  this.premiumInfoDep(
                                                                    planHead.planType,
                                                                    plan,
                                                                    this.state
                                                                      .dependent
                                                                  )
                                                                ).toFixed(2)}
                                                              </span>
                                                              /year
                                                            </Typography>
                                                            <Button
                                                              style={{
                                                                padding: "0px"
                                                              }}
                                                              disabled={
                                                                disablePlan
                                                              }
                                                              onClick={e =>
                                                                this.handleDependentCardClick(
                                                                  coverageSelected.planType,
                                                                  plan,
                                                                  this.state
                                                                    .dependent
                                                                )
                                                              }
                                                            >
                                                              {this.state
                                                                .dependendentPlanSelected &&
                                                              this.state.dependendentPlanSelected.get(
                                                                coverageSelected.planType
                                                              ) &&
                                                              this.state.dependendentPlanSelected
                                                                .get(
                                                                  coverageSelected.planType
                                                                )
                                                                .filter(
                                                                  planObj =>
                                                                    planObj.dependentType ===
                                                                      this.state
                                                                        .dependent &&
                                                                    planObj
                                                                      .planDep
                                                                      .header ===
                                                                      plan.header
                                                                ).length > 0 ? (
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.buttonSelect
                                                                  }
                                                                >
                                                                  SELECTED
                                                                </Typography>
                                                              ) : (
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  className={
                                                                    classes.buttonNormal
                                                                  }
                                                                >
                                                                  SELECT
                                                                </Typography>
                                                              )}
                                                            </Button>
                                                          </Grid>
                                                        );
                                                      },
                                                      this
                                                    );
                                                }
                                              })()}
                                            </Grid>
                                          </div>
                                        }
                                      </div>
                                    )
                                  );
                                case "name":
                                  return (
                                    this.dependentEnrolmentCheck(
                                      planHead.coverageId
                                    ) === "true" && (
                                      <div
                                        key={`${
                                          depRule[planHead.coverageId]
                                            .selectionMethod
                                        } + name`}
                                      >
                                        {user.dependencies.map(
                                          (dependent, dependentKey2) => {
                                            var coverageSelected = plans.filter(
                                              plan =>
                                                plan.planType ===
                                                planHead.planType
                                            )[0];
                                            let dependentSelection =
                                              this.state
                                                .dependendentPlanSelected &&
                                              this.state.dependendentPlanSelected.get(
                                                planHead.planType
                                              ) &&
                                              this.state.dependendentPlanSelected
                                                .get(planHead.planType)
                                                .filter(
                                                  planObj =>
                                                    planObj.dependentType.id ===
                                                    dependent.id
                                                )[0];
                                            let dependentOptOut =
                                              dependentSelection &&
                                              dependentSelection.planDep ===
                                                "Opt Out"
                                                ? true
                                                : false;
                                            countDep = countDep + 1;
                                            this.handleBadgeOnDependent(
                                              planHead,
                                              countDep
                                            );
                                            return (
                                              <div
                                                style={{ marginTop: "25px" }}
                                                key={dependentKey2}
                                              >
                                                <Grid container>
                                                  <Grid
                                                    item
                                                    xs
                                                    sm
                                                    md
                                                    style={{
                                                      background:
                                                        "rgba(228, 227, 228, 0.7)"
                                                    }}
                                                  >
                                                    <Typography
                                                      component={"span"}
                                                      style={{
                                                        fontSize: "16px",
                                                        color: "#6B6A6D",
                                                        padding: "15px",
                                                        float: "left"
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          color: "#000000"
                                                        }}
                                                      >
                                                        {dependent.full_name}
                                                      </b>{" "}
                                                      (
                                                      {this.capitalizeFLetter(
                                                        dependent.dependent
                                                      )}
                                                      )
                                                    </Typography>
                                                    <FormControlLabel
                                                      control={
                                                        <Switch
                                                          checked={
                                                            this.state
                                                              .dependentOptOutName &&
                                                            this.state.dependentOptOutName.get(
                                                              dependent.id
                                                            )
                                                          }
                                                          onChange={this.handleChangeSwitch(
                                                            "name",
                                                            dependent,
                                                            planHead.planType
                                                          )}
                                                          value="checkedB"
                                                          color="primary"
                                                          label="Opt Out"
                                                          classes={{
                                                            root:
                                                              classes.switchRoot
                                                          }}
                                                        />
                                                      }
                                                      classes={{
                                                        root: classes.switchRoot
                                                      }}
                                                      label="Opt Out"
                                                    />
                                                  </Grid>
                                                </Grid>
                                                <Grid
                                                  container
                                                  style={
                                                    this.state
                                                      .dependentOptOutName &&
                                                    this.state.dependentOptOutName.get(
                                                      dependent.id
                                                    )
                                                      ? {
                                                          background: "#F6F6F6",
                                                          display: "none"
                                                        }
                                                      : {
                                                          background: "#F6F6F6"
                                                        }
                                                  }
                                                >
                                                  <Grid
                                                    item
                                                    xs
                                                    sm
                                                    md
                                                    style={{
                                                      borderRight:
                                                        "1px solid #E4E3E4"
                                                    }}
                                                  >
                                                    <Typography
                                                      component={"span"}
                                                      style={{
                                                        color: "#6B6A6D",
                                                        fontSize: "16px",
                                                        textAlign: "left",
                                                        padding: "20px"
                                                      }}
                                                    >
                                                      Annual Premium
                                                    </Typography>

                                                    <Button
                                                      size="small"
                                                      color="secondary"
                                                    >
                                                      {" "}
                                                    </Button>
                                                  </Grid>
                                                  {(() => {
                                                    switch (
                                                      depRule[
                                                        planHead.coverageId
                                                      ].dependentCoverage
                                                    ) {
                                                      case "same":
                                                        return planHead.planDetails.map(
                                                          function(
                                                            plan,
                                                            keyBottom
                                                          ) {
                                                            let disablePlan = false;
                                                            plan.header ===
                                                            (this.state
                                                              .planSelected &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ) &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).header)
                                                              ? (disablePlan = false)
                                                              : (disablePlan = true);
                                                            return disablePlan ? (
                                                              <Grid
                                                                item
                                                                xs
                                                                sm
                                                                md
                                                                key={keyBottom}
                                                                style={{
                                                                  borderRight:
                                                                    "1px solid #E4E3E4",
                                                                  textAlign:
                                                                    "center"
                                                                }}
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  style={{
                                                                    fontSize:
                                                                      "16px",
                                                                    textAlign:
                                                                      "center",
                                                                    padding:
                                                                      "20px",
                                                                    fontWeight: 600
                                                                  }}
                                                                >
                                                                  N/A
                                                                </Typography>
                                                              </Grid>
                                                            ) : (
                                                              <Grid
                                                                item
                                                                xs
                                                                sm
                                                                md
                                                                key={keyBottom}
                                                                style={{
                                                                  borderRight:
                                                                    "1px solid #E4E3E4",
                                                                  textAlign:
                                                                    "center"
                                                                }}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                >
                                                                  <span
                                                                    className={
                                                                      classes.premiumText
                                                                    }
                                                                  >
                                                                    S$
                                                                    {parseFloat(
                                                                      this.premiumInfoDep(
                                                                        planHead.planType,
                                                                        plan,
                                                                        dependent
                                                                      )
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </span>
                                                                  /year
                                                                </Typography>
                                                                <Button
                                                                  style={{
                                                                    padding:
                                                                      "0px"
                                                                  }}
                                                                  disabled={
                                                                    disablePlan
                                                                  }
                                                                  onClick={e =>
                                                                    this.handleDependentCardClick(
                                                                      coverageSelected.planType,
                                                                      plan,
                                                                      dependent
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .dependendentPlanSelected &&
                                                                  this.state.dependendentPlanSelected.get(
                                                                    coverageSelected.planType
                                                                  ) &&
                                                                  this.state.dependendentPlanSelected
                                                                    .get(
                                                                      coverageSelected.planType
                                                                    )
                                                                    .filter(
                                                                      planObj =>
                                                                        planObj
                                                                          .dependentType
                                                                          .id ===
                                                                          dependent.id &&
                                                                        planObj
                                                                          .planDep
                                                                          .header ===
                                                                          plan.header
                                                                    ).length >
                                                                    0 ? (
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.buttonSelect
                                                                      }
                                                                    >
                                                                      SELECTED
                                                                    </Typography>
                                                                  ) : (
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.buttonNormal
                                                                      }
                                                                    >
                                                                      SELECT
                                                                    </Typography>
                                                                  )}
                                                                </Button>
                                                              </Grid>
                                                            );
                                                          },
                                                          this
                                                        );
                                                      case "downgrade":
                                                        return planHead.planDetails.map(
                                                          function(
                                                            plan,
                                                            keyBottom
                                                          ) {
                                                            let disablePlan = false;
                                                            plan.option <=
                                                            (this.state
                                                              .planSelected &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ) &&
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).option)
                                                              ? (disablePlan = false)
                                                              : (disablePlan = true);
                                                            return disablePlan ? (
                                                              <Grid
                                                                item
                                                                xs
                                                                sm
                                                                md
                                                                key={keyBottom}
                                                                style={{
                                                                  borderRight:
                                                                    "1px solid #E4E3E4",
                                                                  textAlign:
                                                                    "center"
                                                                }}
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                  style={{
                                                                    fontSize:
                                                                      "16px",
                                                                    textAlign:
                                                                      "center",
                                                                    padding:
                                                                      "20px",
                                                                    fontWeight: 600
                                                                  }}
                                                                >
                                                                  N/A
                                                                </Typography>
                                                              </Grid>
                                                            ) : (
                                                              <Grid
                                                                item
                                                                xs
                                                                sm
                                                                md
                                                                key={keyBottom}
                                                                style={{
                                                                  borderRight:
                                                                    "1px solid #E4E3E4",
                                                                  textAlign:
                                                                    "center"
                                                                }}
                                                                display="flex"
                                                                flexDirection="column"
                                                                alignItems="center"
                                                              >
                                                                <Typography
                                                                  component={
                                                                    "span"
                                                                  }
                                                                >
                                                                  <span
                                                                    className={
                                                                      classes.premiumText
                                                                    }
                                                                  >
                                                                    S$
                                                                    {parseFloat(
                                                                      this.premiumInfoDep(
                                                                        planHead.planType,
                                                                        plan,
                                                                        dependent
                                                                      )
                                                                    ).toFixed(
                                                                      2
                                                                    )}
                                                                  </span>
                                                                  /year
                                                                </Typography>
                                                                <Button
                                                                  style={{
                                                                    padding:
                                                                      "0px"
                                                                  }}
                                                                  disabled={
                                                                    disablePlan
                                                                  }
                                                                  onClick={e =>
                                                                    this.handleDependentCardClick(
                                                                      coverageSelected.planType,
                                                                      plan,
                                                                      dependent
                                                                    )
                                                                  }
                                                                >
                                                                  {this.state
                                                                    .dependendentPlanSelected &&
                                                                  this.state.dependendentPlanSelected.get(
                                                                    coverageSelected.planType
                                                                  ) &&
                                                                  this.state.dependendentPlanSelected
                                                                    .get(
                                                                      coverageSelected.planType
                                                                    )
                                                                    .filter(
                                                                      planObj =>
                                                                        planObj
                                                                          .dependentType
                                                                          .id ===
                                                                          dependent.id &&
                                                                        planObj
                                                                          .planDep
                                                                          .header ===
                                                                          plan.header
                                                                    ).length >
                                                                    0 ? (
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.buttonSelect
                                                                      }
                                                                    >
                                                                      SELECTED
                                                                    </Typography>
                                                                  ) : (
                                                                    <Typography
                                                                      component={
                                                                        "span"
                                                                      }
                                                                      className={
                                                                        classes.buttonNormal
                                                                      }
                                                                    >
                                                                      SELECT
                                                                    </Typography>
                                                                  )}
                                                                </Button>
                                                              </Grid>
                                                            );
                                                          },
                                                          this
                                                        );
                                                    }
                                                  })()}
                                                </Grid>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    )
                                  );
                                case "cluster":
                                  return (
                                    this.dependentEnrolmentCheck(
                                      planHead.coverageId
                                    ) === "true" && (
                                      <div
                                        key={
                                          depRule[planHead.coverageId]
                                            .selectionMethod
                                        }
                                      >
                                        {
                                          <div style={{ marginTop: "0px" }}>
                                            {this.spouseEnrolmentCheck(
                                              planHead.coverageId
                                            ) === "true" &&
                                              user.dependencies.filter(
                                                dependents =>
                                                  dependents.dependent ===
                                                  "SPOUSE"
                                              ).length > 0 && (
                                                <div
                                                  style={{ marginTop: "25px" }}
                                                >
                                                  <Grid container>
                                                    <Grid
                                                      item
                                                      xs
                                                      sm
                                                      md
                                                      style={{
                                                        background:
                                                          "rgba(228, 227, 228, 0.7)"
                                                      }}
                                                    >
                                                      <Typography
                                                        component={"span"}
                                                        style={{
                                                          fontSize: "16px",
                                                          color: "#6B6A6D",
                                                          padding: "20px",
                                                          float: "left"
                                                        }}
                                                      >
                                                        Select Coverage for
                                                      </Typography>
                                                      <form
                                                        className={classes.root}
                                                        autoComplete="off"
                                                      >
                                                        <FormControl
                                                          required
                                                          className={
                                                            classes.formControl
                                                          }
                                                          style={{
                                                            float: "left"
                                                          }}
                                                        >
                                                          <Select
                                                            // value={this.state.dependent || "Dependents"}
                                                            value={
                                                              "Spouse Only"
                                                            }
                                                            onChange={this.handleChangeSelect(
                                                              planHead
                                                            )}
                                                            name="dependent"
                                                            displayEmpty
                                                            inputProps={{
                                                              id:
                                                                "dependent-required"
                                                            }}
                                                            className={
                                                              classes.selectEmpty
                                                            }
                                                            disabled={true}
                                                            // disabled={this.state.dependentOptOut && this.state.dependentOptOut.get(planHead.planType)}
                                                          >
                                                            <MenuItem value="Dependants">
                                                              <em>
                                                                Dependants
                                                              </em>
                                                            </MenuItem>
                                                            {user.dependencies.filter(
                                                              dependents =>
                                                                dependents.dependent ===
                                                                "SPOUSE"
                                                            ).length > 0 ? (
                                                              <MenuItem
                                                                value="Spouse Only"
                                                                style={{
                                                                  fontWeight: 600
                                                                }}
                                                              >
                                                                Spouse Only
                                                              </MenuItem>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* {user.dependencies.filter(dependents => dependents.dependent === "CHILD").length >0 
                                                      ? <MenuItem value="Child(ren) Only" style={{fontWeight:600}}>Child(ren) Only</MenuItem> :''} */}
                                                            {/* { (user.dependencies.filter(dependents => dependents.dependent === "CHILD").length >0 
                                                        && user.dependencies.filter(dependents => dependents.dependent === "SPOUSE").length >0 )
                                                        ?<MenuItem value="Spouse & Child(ren)" style={{fontWeight:600}}>Spouse & Child(ren)</MenuItem>  : ''} */}
                                                          </Select>
                                                        </FormControl>
                                                      </form>
                                                      <FormControlLabel
                                                        control={
                                                          <Switch
                                                            // checked={this.state.dependentOptOutSpouse && this.state.dependentOptOutSpouse.get("spouse") }planHead.planType
                                                            checked={
                                                              this.state
                                                                .dependentOptOut &&
                                                              this.state.dependentOptOut.get(
                                                                planHead.planType +
                                                                  "Spouse Only"
                                                              )
                                                            }
                                                            disabled={
                                                              this.state
                                                                .dependentOptOut &&
                                                              planHead.planType ===
                                                                "Group OutPatient Specialist (GOSP) Benefits" &&
                                                              this.state.dependentOptOut.get(
                                                                "Group Pruclinicare (GPRU) Benefits" +
                                                                  "Spouse Only"
                                                              ) === true
                                                            }
                                                            onChange={this.handleChangeSwitch(
                                                              "cluster",
                                                              "Spouse Only",
                                                              planHead.planType
                                                            )}
                                                            value="checkedB"
                                                            color="primary"
                                                          />
                                                        }
                                                        classes={{
                                                          root:
                                                            classes.switchRoot
                                                        }}
                                                        label="Opt Out"
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    container
                                                    style={
                                                      this.state
                                                        .dependentOptOut &&
                                                      this.state.dependentOptOut.get(
                                                        planHead.planType +
                                                          "Spouse Only"
                                                      )
                                                        ? {
                                                            background:
                                                              "#F6F6F6",
                                                            display: "none"
                                                          }
                                                        : {
                                                            background:
                                                              "#F6F6F6"
                                                          }
                                                    }
                                                  >
                                                    <Grid
                                                      item
                                                      xs
                                                      sm
                                                      md
                                                      style={{
                                                        borderRight:
                                                          "1px solid #E4E3E4"
                                                      }}
                                                    >
                                                      <Typography
                                                        component={"span"}
                                                        style={{
                                                          color: "#6B6A6D",
                                                          fontSize: "16px",
                                                          textAlign: "left",
                                                          padding: "20px"
                                                        }}
                                                      >
                                                        Annual Premium
                                                      </Typography>
                                                      <Button
                                                        size="small"
                                                        color="secondary"
                                                      >
                                                        {" "}
                                                      </Button>
                                                    </Grid>
                                                    {(() => {
                                                      switch (
                                                        depRule[
                                                          planHead.coverageId
                                                        ].dependentCoverage
                                                      ) {
                                                        case "same":
                                                          var coverageSelected = plans.filter(
                                                            plan =>
                                                              plan.planType ===
                                                              planHead.planType
                                                          )[0];
                                                          countDep =
                                                            countDep + 1;
                                                          this.handleBadgeOnDependent(
                                                            planHead,
                                                            countDep
                                                          );
                                                          return planHead.planDetails.map(
                                                            function(
                                                              plan,
                                                              keyBottom
                                                            ) {
                                                              let disablePlan = false;
                                                              // this.state.dependent = "Spouse Only";
                                                              // this.state.dependent != "Dependents" ?
                                                              plan.header ===
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).header
                                                                ? (disablePlan = false)
                                                                : (disablePlan = true);
                                                              // : disablePlan = true;
                                                              return disablePlan ? (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    style={{
                                                                      fontSize:
                                                                        "16px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "20px",
                                                                      fontWeight: 600
                                                                    }}
                                                                  >
                                                                    N/A
                                                                  </Typography>
                                                                </Grid>
                                                              ) : (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                  display="flex"
                                                                  flexDirection="column"
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                  >
                                                                    <span
                                                                      className={
                                                                        classes.premiumText
                                                                      }
                                                                    >
                                                                      S$
                                                                      {parseFloat(
                                                                        this.premiumInfoDep(
                                                                          planHead.planType,
                                                                          plan,
                                                                          "Spouse Only"
                                                                        )
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                    /year
                                                                  </Typography>
                                                                  <Button
                                                                    style={{
                                                                      padding:
                                                                        "0px"
                                                                    }}
                                                                    disabled={
                                                                      disablePlan
                                                                    }
                                                                    onClick={e =>
                                                                      this.handleDependentCardClick(
                                                                        coverageSelected.planType,
                                                                        plan,
                                                                        "Spouse Only"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj.dependentType ===
                                                                            "Spouse Only" &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            plan.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                  </Button>
                                                                </Grid>
                                                              );
                                                            },
                                                            this
                                                          );
                                                        case "downgrade":
                                                          var coverageSelected = plans.filter(
                                                            plan =>
                                                              plan.planType ===
                                                              planHead.planType
                                                          )[0];
                                                          countDep =
                                                            countDep + 1;
                                                          this.handleBadgeOnDependent(
                                                            planHead,
                                                            countDep
                                                          );
                                                          return planHead.planDetails.map(
                                                            function(
                                                              plan,
                                                              keyBottom
                                                            ) {
                                                              let disablePlan = false;
                                                              // this.state.dependent = "Spouse Only";
                                                              // this.state.dependent != "Dependents"  ?
                                                              plan.option <=
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).option
                                                                ? (disablePlan = false)
                                                                : (disablePlan = true);
                                                              // : disablePlan = true;
                                                              return disablePlan ||
                                                                isForeignPlan ? (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    style={{
                                                                      fontSize:
                                                                        "16px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "20px",
                                                                      fontWeight: 600
                                                                    }}
                                                                  >
                                                                    N/A
                                                                  </Typography>
                                                                </Grid>
                                                              ) : (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                  display="flex"
                                                                  flexDirection="column"
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                  >
                                                                    <span
                                                                      className={
                                                                        classes.premiumText
                                                                      }
                                                                    >
                                                                      S$
                                                                      {parseFloat(
                                                                        this.premiumInfoDep(
                                                                          planHead.planType,
                                                                          plan,
                                                                          "Spouse Only"
                                                                        )
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                    /year
                                                                  </Typography>
                                                                  <Button
                                                                    style={{
                                                                      padding:
                                                                        "0px"
                                                                    }}
                                                                    disabled={
                                                                      disablePlan
                                                                    }
                                                                    onClick={e =>
                                                                      this.handleDependentCardClick(
                                                                        coverageSelected.planType,
                                                                        plan,
                                                                        "Spouse Only"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    //this.state.dependent
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj.dependentType ===
                                                                            "Spouse Only" &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            plan.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                  </Button>
                                                                </Grid>
                                                              );
                                                            },
                                                            this
                                                          );
                                                      }
                                                    })()}
                                                  </Grid>
                                                </div>
                                              )}
                                            {this.childrenEnrolmentCheck(
                                              planHead.coverageId
                                            ) === "true" &&
                                              user.dependencies.filter(
                                                dependents =>
                                                  dependents.dependent ===
                                                  "CHILD"
                                              ).length > 0 && (
                                                <div
                                                  style={{ marginTop: "25px" }}
                                                >
                                                  <Grid container>
                                                    <Grid
                                                      item
                                                      xs
                                                      sm
                                                      md
                                                      style={{
                                                        background:
                                                          "rgba(228, 227, 228, 0.7)"
                                                      }}
                                                    >
                                                      <Typography
                                                        component={"span"}
                                                        style={{
                                                          fontSize: "16px",
                                                          color: "#6B6A6D",
                                                          padding: "20px",
                                                          float: "left"
                                                        }}
                                                      >
                                                        Select Coverage for
                                                      </Typography>
                                                      <form
                                                        className={classes.root}
                                                        autoComplete="off"
                                                      >
                                                        <FormControl
                                                          required
                                                          className={
                                                            classes.formControl
                                                          }
                                                          style={{
                                                            float: "left"
                                                          }}
                                                        >
                                                          <Select
                                                            value={
                                                              "Child(ren) Only"
                                                            }
                                                            onChange={this.handleChangeSelect(
                                                              planHead
                                                            )}
                                                            name="Child(ren) Only"
                                                            displayEmpty
                                                            inputProps={{
                                                              id:
                                                                "dependent-required-children"
                                                            }}
                                                            className={
                                                              classes.selectEmpty
                                                            }
                                                            disabled={true}
                                                            // disabled={this.state.dependentOptOut && this.state.dependentOptOut.get(planHead.planType)}
                                                          >
                                                            <MenuItem value="Dependants">
                                                              <em>
                                                                Dependants
                                                              </em>
                                                            </MenuItem>
                                                            {/* {user.dependencies.filter(dependents => dependents.dependent === "SPOUSE").length >0 
                                                        ? <MenuItem value="Spouse Only" style={{fontWeight:600}}>Spouse Only</MenuItem> :''} */}
                                                            {user.dependencies.filter(
                                                              dependents =>
                                                                dependents.dependent ===
                                                                "CHILD"
                                                            ).length > 0 ? (
                                                              <MenuItem
                                                                value="Child(ren) Only"
                                                                style={{
                                                                  fontWeight: 600
                                                                }}
                                                              >
                                                                Child(ren) Only
                                                              </MenuItem>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {/* { (user.dependencies.filter(dependents => dependents.dependent === "CHILD").length >0 
                                                          && user.dependencies.filter(dependents => dependents.dependent === "SPOUSE").length >0 )
                                                          ?<MenuItem value="Spouse & Child(ren)" style={{fontWeight:600}}>Spouse & Child(ren)</MenuItem>  : ''} */}
                                                          </Select>
                                                        </FormControl>
                                                      </form>
                                                      <FormControlLabel
                                                        control={
                                                          <Switch
                                                            checked={
                                                              this.state
                                                                .dependentOptOut &&
                                                              this.state.dependentOptOut.get(
                                                                planHead.planType +
                                                                  "Child(ren) Only"
                                                              )
                                                            }
                                                            // checked={this.state.dependentOptOutChildren && this.state.dependentOptOutChildren.get("children") }
                                                            onChange={this.handleChangeSwitch(
                                                              "cluster",
                                                              "Child(ren) Only",
                                                              planHead.planType
                                                            )}
                                                            disabled={
                                                              this.state
                                                                .dependentOptOut &&
                                                              planHead.planType ===
                                                                "Group OutPatient Specialist (GOSP) Benefits" &&
                                                              this.state.dependentOptOut.get(
                                                                "Group Pruclinicare (GPRU) Benefits" +
                                                                  "Child(ren) Only"
                                                              ) === true
                                                            }
                                                            value="checkedB"
                                                            color="primary"
                                                          />
                                                        }
                                                        classes={{
                                                          root:
                                                            classes.switchRoot
                                                        }}
                                                        label="Opt Out"
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    container
                                                    style={
                                                      this.state
                                                        .dependentOptOut &&
                                                      this.state.dependentOptOut.get(
                                                        planHead.planType +
                                                          "Child(ren) Only"
                                                      )
                                                        ? {
                                                            background:
                                                              "#F6F6F6",
                                                            display: "none"
                                                          }
                                                        : {
                                                            background:
                                                              "#F6F6F6"
                                                          }
                                                    }
                                                  >
                                                    <Grid
                                                      item
                                                      xs
                                                      sm
                                                      md
                                                      style={{
                                                        borderRight:
                                                          "1px solid #E4E3E4"
                                                      }}
                                                    >
                                                      <Typography
                                                        component={"span"}
                                                        style={{
                                                          color: "#6B6A6D",
                                                          fontSize: "16px",
                                                          textAlign: "left",
                                                          padding: "20px"
                                                        }}
                                                      >
                                                        Annual Premium
                                                      </Typography>
                                                      <Button
                                                        size="small"
                                                        color="secondary"
                                                      >
                                                        {" "}
                                                      </Button>
                                                    </Grid>
                                                    {(() => {
                                                      switch (
                                                        depRule[
                                                          planHead.coverageId
                                                        ].dependentCoverage
                                                      ) {
                                                        case "same":
                                                          var coverageSelected = plans.filter(
                                                            plan =>
                                                              plan.planType ===
                                                              planHead.planType
                                                          )[0];
                                                          countDep =
                                                            countDep + 1;
                                                          this.handleBadgeOnDependent(
                                                            planHead,
                                                            countDep
                                                          );
                                                          return planHead.planDetails.map(
                                                            function(
                                                              plan,
                                                              keyBottom
                                                            ) {
                                                              let disablePlan = false;
                                                              // this.state.dependent = "Child(ren) Only";
                                                              // this.state.dependent != "Dependents" ?
                                                              plan.header ===
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).header
                                                                ? (disablePlan = false)
                                                                : (disablePlan = true);
                                                              // : disablePlan = true;
                                                              return disablePlan ? (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    style={{
                                                                      fontSize:
                                                                        "16px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "20px",
                                                                      fontWeight: 600
                                                                    }}
                                                                  >
                                                                    N/A
                                                                  </Typography>
                                                                </Grid>
                                                              ) : (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                  display="flex"
                                                                  flexDirection="column"
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                  >
                                                                    <span
                                                                      className={
                                                                        classes.premiumText
                                                                      }
                                                                    >
                                                                      S$
                                                                      {parseFloat(
                                                                        this.premiumInfoDep(
                                                                          planHead.planType,
                                                                          plan,
                                                                          "Child(ren) Only"
                                                                        )
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                    /year
                                                                  </Typography>
                                                                  <Button
                                                                    style={{
                                                                      padding:
                                                                        "0px"
                                                                    }}
                                                                    disabled={
                                                                      disablePlan
                                                                    }
                                                                    onClick={e =>
                                                                      this.handleDependentCardClick(
                                                                        coverageSelected.planType,
                                                                        plan,
                                                                        "Child(ren) Only"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj.dependentType ===
                                                                            "Child(ren) Only" &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            plan.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                  </Button>
                                                                </Grid>
                                                              );
                                                            },
                                                            this
                                                          );
                                                        case "downgrade":
                                                          var coverageSelected = plans.filter(
                                                            plan =>
                                                              plan.planType ===
                                                              planHead.planType
                                                          )[0];
                                                          countDep =
                                                            countDep + 1;
                                                          this.handleBadgeOnDependent(
                                                            planHead,
                                                            countDep
                                                          );
                                                          return planHead.planDetails.map(
                                                            function(
                                                              plan,
                                                              keyBottom
                                                            ) {
                                                              let disablePlan = false;
                                                              let separatePremiumForChildren = false;
                                                              let numberOfChildren = this.getNumberOfChildren();
                                                              // this.state.dependent = "Child(ren) Only";
                                                              // this.state.dependent != "Dependents" ?
                                                              plan.option <=
                                                              this.state.planSelected.get(
                                                                planHead.planType
                                                              ).option
                                                                ? (disablePlan = false)
                                                                : (disablePlan = true);

                                                              if (
                                                                depRule[
                                                                  planHead
                                                                    .coverageId
                                                                ]
                                                                  .samePremiumForAll ==
                                                                false
                                                              ) {
                                                                separatePremiumForChildren = true;
                                                              }
                                                              // check if the child has to be the same plan with spouse configuration is on
                                                              if (
                                                                depRule[
                                                                  planHead
                                                                    .coverageId
                                                                ]
                                                                  .childFollowSpouse
                                                              ) {
                                                                let dependentPlanSelected = this
                                                                  .state
                                                                  .dependendentPlanSelected;
                                                                if (
                                                                  dependentPlanSelected.size >
                                                                    0 &&
                                                                  dependentPlanSelected.get(
                                                                    planHead.planType
                                                                  ) !==
                                                                    undefined &&
                                                                  dependentPlanSelected.get(
                                                                    planHead.planType
                                                                  ).length > 0
                                                                ) {
                                                                  // only compute if its not opt out
                                                                  dependentPlanSelected
                                                                    .get(
                                                                      planHead.planType
                                                                    )
                                                                    .filter(
                                                                      dependent => {
                                                                        if (
                                                                          dependent.dependentType ==
                                                                            "Spouse Only" &&
                                                                          dependent.planDep !==
                                                                            "Opt Out"
                                                                        ) {
                                                                          // has to be same plan as spouse
                                                                          plan.option ==
                                                                          dependent
                                                                            .planDep
                                                                            .option
                                                                            ? (disablePlan = false)
                                                                            : (disablePlan = true);
                                                                        }
                                                                      }
                                                                    );
                                                                }
                                                              }
                                                              if (
                                                                depRule[
                                                                  planHead
                                                                    .coverageId
                                                                ]
                                                                  .childOnlyOnePlan !=
                                                                undefined
                                                              ) {
                                                                plan.option ==
                                                                depRule[
                                                                  planHead
                                                                    .coverageId
                                                                ]
                                                                  .childOnlyOnePlan
                                                                  ? (disablePlan = false)
                                                                  : (disablePlan = true);
                                                              }
                                                              return disablePlan ||
                                                                isForeignPlan ? (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                    style={{
                                                                      fontSize:
                                                                        "16px",
                                                                      textAlign:
                                                                        "center",
                                                                      padding:
                                                                        "20px",
                                                                      fontWeight: 600
                                                                    }}
                                                                  >
                                                                    N/A
                                                                  </Typography>
                                                                </Grid>
                                                              ) : separatePremiumForChildren ? (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                  display="flex"
                                                                  flexDirection="column"
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                  >
                                                                    <span
                                                                      className={
                                                                        classes.premiumText
                                                                      }
                                                                    >
                                                                      S$
                                                                      {parseFloat(
                                                                        this.premiumInfoDep(
                                                                          planHead.planType,
                                                                          plan,
                                                                          "Child(ren) Only"
                                                                        ) *
                                                                          numberOfChildren
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                    /year
                                                                  </Typography>
                                                                  <Button
                                                                    style={{
                                                                      padding:
                                                                        "0px"
                                                                    }}
                                                                    disabled={
                                                                      disablePlan
                                                                    }
                                                                    onClick={e =>
                                                                      this.handleDependentCardClick(
                                                                        coverageSelected.planType,
                                                                        plan,
                                                                        "Child(ren) Only"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj.dependentType ===
                                                                            "Child(ren) Only" &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            plan.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                  </Button>
                                                                </Grid>
                                                              ) : (
                                                                <Grid
                                                                  item
                                                                  xs
                                                                  sm
                                                                  md
                                                                  key={
                                                                    keyBottom
                                                                  }
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid #E4E3E4",
                                                                    textAlign:
                                                                      "center"
                                                                  }}
                                                                  display="flex"
                                                                  flexDirection="column"
                                                                  alignItems="center"
                                                                >
                                                                  <Typography
                                                                    component={
                                                                      "span"
                                                                    }
                                                                  >
                                                                    <span
                                                                      className={
                                                                        classes.premiumText
                                                                      }
                                                                    >
                                                                      S$
                                                                      {parseFloat(
                                                                        this.premiumInfoDep(
                                                                          planHead.planType,
                                                                          plan,
                                                                          "Child(ren) Only"
                                                                        )
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </span>
                                                                    /year
                                                                  </Typography>
                                                                  <Button
                                                                    style={{
                                                                      padding:
                                                                        "0px"
                                                                    }}
                                                                    disabled={
                                                                      disablePlan
                                                                    }
                                                                    onClick={e =>
                                                                      this.handleDependentCardClick(
                                                                        coverageSelected.planType,
                                                                        plan,
                                                                        "Child(ren) Only"
                                                                      )
                                                                    }
                                                                  >
                                                                    {this.state
                                                                      .dependendentPlanSelected &&
                                                                    this.state.dependendentPlanSelected.get(
                                                                      coverageSelected.planType
                                                                    ) &&
                                                                    this.state.dependendentPlanSelected
                                                                      .get(
                                                                        coverageSelected.planType
                                                                      )
                                                                      .filter(
                                                                        planObj =>
                                                                          planObj.dependentType ===
                                                                            "Child(ren) Only" &&
                                                                          planObj
                                                                            .planDep
                                                                            .header ===
                                                                            plan.header
                                                                      ).length >
                                                                      0 ? (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonSelect
                                                                        }
                                                                      >
                                                                        SELECTED
                                                                      </Typography>
                                                                    ) : (
                                                                      <Typography
                                                                        component={
                                                                          "span"
                                                                        }
                                                                        className={
                                                                          classes.buttonNormal
                                                                        }
                                                                      >
                                                                        SELECT
                                                                      </Typography>
                                                                    )}
                                                                  </Button>
                                                                </Grid>
                                                              );
                                                            },
                                                            this
                                                          );
                                                      }
                                                    })()}
                                                  </Grid>
                                                </div>
                                              )}
                                          </div>
                                        }
                                      </div>
                                    )
                                  );
                              }
                            })()}
                        </div>
                      </div>
                    </TabContainer>
                  </Paper>
                ))
              );
            }, this)}
          </div>
        </div>
        <Typography
          component={"div"}
          className={classes.note}
          dangerouslySetInnerHTML={{
            __html: note({
              supportEmail: placeholderMapTemp.get("{supportEmail}"),
              supportBillingEmail: this.props.user.client_name.config
                .supportBillingEmail
            })
          }}
        />
      </div>
    );
  }
}

const styles = theme => ({
  rootInfo: {
    height: "100%",
    padding: "25px"
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    minWidth: 72,
    color: "#979797",
    fontSize: 16,
    textAlign: "center",
    fontWeight: 600
  },
  selected: {
    color: "#000000",
    fontSize: 16,
    textAlign: "center",
    background: "white",
    border: "none",
    borderTop: "3px solid #ED1C24",
    fontWeight: 600
  },
  tabRoot: {
    textTransform: "initial"
  },
  switchRoot: {
    float: "right"
  },
  headerText: {
    color: "#ED1C24",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "28px",
    display: "flex",
    alignItems: "center",
    padding: "10px 0px"
  },
  infoText: {
    color: "#000000",
    fontSize: "18px",
    lineHeight: "30px"
  },

  contentInfo: {
    paddingTop: 10,
    // paddingBottom:40,
    background: "#FFFFFF",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px"
  },
  headerInfo: {
    // padding: theme.spacing.unit * 2.5
    padding: "15px"
  },

  wrapper: {
    //  zIndex:999
  },
  cardTop: {
    height: "100%",
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "4px 4px 0px 0px"
  },
  cardBottom: {
    height: "100%",
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "0px 0px 4px 4px"
  },
  cardTopSelected: {
    height: "100%",
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",

    // border: '3px solid #ED1C24',
    boxSizing: "border-box",
    borderRadius: "4px 4px 0px 0px"
    // borderBottom:'none'
  },
  cardBottomSelected: {
    height: "100%",
    background: "#FFFFFF",
    // border: '3px solid #ED1C24',
    boxSizing: "border-box",
    borderRadius: "0px 0px 4px 4px",
    borderTop: "none"
  },
  cardInner: {
    height: "100%",
    background: "#FFFFFF",
    border: "none",
    boxSizing: "border-box",
    borderRadius: "0px",
    borderLeft: "1px solid #C5C4C4",
    borderRight: "1px solid #C5C4C4"
  },
  cardInnerSelected: {
    height: "100%",
    background: "#FFFFFF",
    border: "none",
    boxSizing: "border-box",
    borderRadius: "0px"
  },
  cardTopDisabled: {
    height: "100%",
    background: "#F6F6F6",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "4px 4px 0px 0px"
  },
  cardBottomDisabled: {
    height: "100%",
    background: "#F6F6F6",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "0px 0px 4px 4px"
  },
  cardDisabledInner: {
    height: "100%",
    background: "#F6F6F6",
    border: "none",
    boxSizing: "border-box",
    borderRadius: "0px"
  },

  title: {
    marginBottom: 16,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000"
  },

  cardTitle: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center"
  },

  summaryTitle: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center"
  },

  premiumText: {
    color: "#333333",
    fontSize: "22px",
    fontWeight: 600,
    lineHeight: "40px",
    alignItems: "center"
  },

  cardAction: {
    display: "block",
    width: "100%",
    height: "100%",
    textAlign: "initial"
  },
  gridStyle1: {
    [theme.breakpoints.down("xs", "sm")]: {
      overflow: "scroll"
    }
  },
  gridStyleChild: {
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100% !important"
    }
  },
  panelSummary: {
    border: "1px solid #E4E3E4"
  },
  panelDetails: {
    border: "1px solid #E4E3E4"
  },

  expanded: {
    margin: 0
  },
  root: {
    padding: 0
  },
  content: {
    color: "#6B6A6D",
    padding: "5px",
    fontSize: "16px",
    lineHeight: "20px"
  },
  gridBorder: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    padding: "0px"
  },
  buttonSelect: {
    fontSize: "15.75px",
    lineHeight: "35px",
    textAlign: "center",
    color: "#FFF",
    background: "#ED1C24",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "20px",
    width: "100%",
    padding: "0px 25px"
  },
  buttonNormal: {
    fontSize: "15.75px",
    lineHeight: "35px",
    textAlign: "center",
    color: "#333333",
    background: "#FFFFFF",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    borderRadius: "20px",
    width: "100%",
    padding: "0px 25px"
  },
  buttonDisable: {
    fontSize: "15.75px",
    lineHeight: "35px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.26)",
    background: "rgba(0, 0, 0, 0.12)",
    border: "1px solid #C5C4C4",
    boxSizing: "border-box",
    boxShadow: "none",
    borderRadius: "20px",
    width: "100%",
    padding: "0px 25px"
  },
  note: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6B6A6D",
    padding: "15px"
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  tabsIndicator: {
    background: "#ffffff"
  },
  stickyCard: {
    position: "sticky",
    top: 0,
    zIndex: 2
  },
  subHeader1Text: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "23px",
    display: "flex",
    alignItems: "center"
  },
  bottomNote: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#6B6A6D"
  },
  cardRoot: {
    paddingBottom: "8px",
    paddingTop: "8px",
    textAlign: "center",
    [theme.breakpoints.down("xs", "sm")]: {
      paddingBottom: "8px",
      paddingTop: "8px"
    },
    "&:last-child": {
      paddingBottom: "8px"
    }
  },
  cardTopVertical: {
    height: "100%",
    minHeight: "75px",

    background: "#FFF5F5",
    border: "1px solid #C5C4C4",
    boxShadow: "none"
  },
  cardTopVerticalHeader: {
    height: "100%",
    minHeight: "75px",
    background: "#FFF5F5",
    border: "1px solid #C5C4C4",
    boxShadow: "none"
  },
  contentHeader: {
    color: "#000000",
    padding: "5px",
    fontSize: "18px",
    lineHeight: "20px"
  },
  toolTip: {
    maxWidth: "300px",
    marginLeft: "300px"
  },

  popup: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    alignSelf: "center",
    padding: "12",
    backgroundColor: theme.background.color,
    // backgroundColor: theme.palette.default.main,
    color: theme.palette.default.fontColor,
    p: 4,
    overflowY: "auto",
    textAlign: "center",
    borderRadius: "3px",
    boxShadow: "4px 8px 12px 0 rgba(0,0,0,0.4)",
    animation: "show-modal .7s ease-in-out"
  },
  containerPopup: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  button: {
    marginLeft: 20,
    minWidth: 150
  }
});

function mapStateToProps(state) {
  return {
    planSelected: state.planSelected,
    enableNext: state.enableNext,
    state: state,
    user: state.user,
    hdf: state.hdf.hasHDF
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(appActions, dispatch)
  };
}

SelectPlan.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(SelectPlan)));
