import Enums from "../../../utils/Enums";

export const document = [
  {
    desc: "Your Guide to Life Insurance (English Version)",
    file: require("./Your Guide to Life Insurance May 2020 English.pdf")
      .default,
    fileName: "Your Guide to Life Insurance May 2020 English.pdf",
    type: [Enums.P_CODE.UOB]
  },
  {
    desc: "Your Guide to Life Insurance (Chinese Version)",
    file: require("./Your Guide to Life Insurance May 2020 Chinese.pdf")
      .default,
    fileName: "Your Guide to Life Insurance May 2020 Chinese.pdf",
    type: [Enums.P_CODE.UOB]
  },
  {
    desc: "Your Guide to Life Insurance (English/Chinese Version)",
    docType: "weblink",
    weblink:
      "https://www.lia.org.sg/tools-and-resources/consumer-guides/2020/your-guide-to-life-insurance/",
    fileName: "Your Guild to Life Insurance (English/Chinese Version)",
    type: [Enums.P_CODE.PRUAGENCY]
  },
  {
    desc: "Your Guide to Health Insurance (English/Chinese Version)",
    docType: "weblink",
    weblink:
      "https://www.lia.org.sg/tools-and-resources/consumer-guides/2022/your-guide-to-health-insurance-2020-revised-edition/",
    fileName: "Your Guild to Health Insurance (English/Chinese Version)",
    type: [
      Enums.P_CODE.PRUAGENCY,
      Enums.P_CODE.SIA,
      Enums.P_CODE.SIA_SAL,
      Enums.P_CODE.SIA_SPL,
      Enums.P_CODE.SIA_TTTPL,
      Enums.P_CODE.SIA_SECL,
      Enums.P_CODE.SIA_KPL,
      Enums.P_CODE.SIA_SCOOT,
      Enums.P_CODE.PACS
    ]
  },
  {
    desc: "Your Guide to Health Insurance (English/Chinese Version)",
    docType: "weblink",
    weblink:
      "https://www.lia.org.sg/tools-and-resources/consumer-guides/2022/your-guide-to-health-insurance-2020-revised-edition/",
    fileName: "Your Guide to Health Insurance (English/Chinese Version)",
    type: [Enums.P_CODE.RI]
  },
  {
    desc:
      "Infographic - Evaluating My Health Insurance Coverage (English/Chinese Version)",
    docType: "weblink",
    weblink:
      "https://www.lia.org.sg/tools-and-resources/consumer-guides/2016/evaluating-my-health-insurance-coverage/",
    fileName:
      "Infographic - Evaluating My Health Insurance Coverage (English/Chinese Version)",
    type: [Enums.P_CODE.RI, Enums.P_CODE.PACS]
  },
  {
    desc:
      "OA Circular 062_24 - Enrolment for One Agency Voluntary Life Scheme 2024 - Group Term Life Group Crisis Cover and Group Pre-Crisis Cover",
    file: require("./OA Circular 062_24 - Enrolment for One Agency Voluntary Life Scheme 2024.pdf"),
    fileName:
      "OA Circular 062_24 - Enrolment for One Agency Voluntary Life Scheme 2024 - Group Term Life Group Crisis Cover and Group Pre-Crisis Cover.pdf",
    type: [Enums.P_CODE.PRUAGENCY]
  },
  {
    desc: "Product Summary",
    file: require("./Year 2022 GTL Product Summary_Vol GTL_Final.pdf"),
    fileName: "Year 2022 GTL Product Summary_Vol GTL_Final.pdf",
    type: [Enums.P_CODE.UOB]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/VIP-Medical Benefits.pdf"),
    fileName: "VIP-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.VIP]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan1-Medical Benefits.pdf"),
    fileName: "Plan1-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN1]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan2-Medical Benefits.pdf"),
    fileName: "Plan2-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN2]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan4A-Medical Benefits.pdf"),
    fileName: "Plan4A-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN4A]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan4B-Medical Benefits.pdf"),
    fileName: "Plan4B-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN4B]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan5A-Medical Benefits.pdf"),
    fileName: "Plan5A-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN5A]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan5B-Medical Benefits.pdf"),
    fileName: "Plan5B-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN5B]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan6A-Medical Benefits.pdf"),
    fileName: "Plan6A-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN6A]
  },
  {
    desc: "Medical Benefits",
    file: require("./UOBEXT/Plan6B-Medical Benefits.pdf"),
    fileName: "Plan6B-Medical Benefits.pdf",
    type: [Enums.P_CODE.UOBEXT],
    category: [Enums.CATEGORY_CODE.PLAN6B]
  },
  {
    desc: "Product Summary (Inpatient - Associates)",
    file: require("./Product Summary - GHS - Associates_Year 2025.pdf"),
    fileName: "Product Summary - GHS - Associates_Year 2025.pdf",
    type: [
      Enums.P_CODE.SIA,
      Enums.P_CODE.SIA_SAL,
      Enums.P_CODE.SIA_SPL,
      Enums.P_CODE.SIA_TTTPL,
      Enums.P_CODE.SIA_SECL,
      Enums.P_CODE.SIA_KPL,
      Enums.P_CODE.SIA_SCOOT
    ],
    category: [Enums.P_CODE.ASSOCIATE]
  },
  {
    desc: "Product Summary (Outpatient - Associates)",
    file: require("./Product Summary - GOP - Associates_Year 2025.pdf"),
    fileName: "Product Summary - GOP - Associates_Year 2025.pdf",
    type: [
      Enums.P_CODE.SIA,
      Enums.P_CODE.SIA_SAL,
      Enums.P_CODE.SIA_SPL,
      Enums.P_CODE.SIA_TTTPL,
      Enums.P_CODE.SIA_SECL,
      Enums.P_CODE.SIA_KPL,
      Enums.P_CODE.SIA_SCOOT
    ],
    category: [Enums.P_CODE.ASSOCIATE]
  },
  {
    desc: "Product Summary (Inpatient - Senior Staff)",
    file: require("./Product Summary - GHS - Executives and above_Year 2025.pdf"),
    fileName: "Product Summary - GHS - Executives and above_Year 2025.pdf",
    type: [
      Enums.P_CODE.SIA,
      Enums.P_CODE.SIA_SAL,
      Enums.P_CODE.SIA_SPL,
      Enums.P_CODE.SIA_TTTPL,
      Enums.P_CODE.SIA_SECL,
      Enums.P_CODE.SIA_KPL,
      Enums.P_CODE.SIA_SCOOT
    ],
    category: [Enums.P_CODE.SENIOR_EXECEUTIVE]
  },
  {
    desc: "Product Summary (Outpatient - Senior Staff)",
    file: require("./Product Summary - GOP - Executives and above_Year 2025.pdf"),
    fileName: "Product Summary - GOP - Executives and above_Year 2025.pdf",
    type: [
      Enums.P_CODE.SIA,
      Enums.P_CODE.SIA_SAL,
      Enums.P_CODE.SIA_SPL,
      Enums.P_CODE.SIA_TTTPL,
      Enums.P_CODE.SIA_SECL,
      Enums.P_CODE.SIA_KPL,
      Enums.P_CODE.SIA_SCOOT
    ],
    category: [Enums.P_CODE.SENIOR_EXECEUTIVE]
  },
  {
    desc: "Product Summary",
    file: require("./Product Summary - GPRU GHS GSMM_Yr 2024_updated_5 Oct 2023.pdf"),
    fileName: "Product Summary - GPRU GHS GSMM_Yr 2024_updated_5 Oct 2023.pdf",
    type: [Enums.P_CODE.PACS]
  },
  {
    desc: "Enrolment User Guide",
    file: require("./Step by Step Guide for PACS Employee Medical Benefits Enrolment Portal v1.2.pdf"),
    fileName:
      "Step by Step Guide for PACS Employee Medical Benefits Enrolment Portal v1.2.pdf",
    type: [Enums.P_CODE.PACS],
    page: [Enums.SCREEN_NAME.DOCUMENT]
  },
  {
    desc: "Product Summary - GHS",
    file: require("./2025 Product Summary - GHS.pdf"),
    fileName: "RI Product Summary 2025 - GHS.pdf",
    type: [Enums.P_CODE.RI]
  },
  {
    desc: "Product Summary - GOSP",
    file: require("./2025 Product Summary - GOSP.pdf"),
    fileName: "RI Product Summary 2025 - GOSP.pdf",
    type: [Enums.P_CODE.RI]
  },
  {
    desc: "Product Summary - GPRU",
    file: require("./2025 Product Summary - GPRU.pdf"),
    fileName: "RI Product Summary 2025 - GPRU.pdf",
    type: [Enums.P_CODE.RI]
  },
  {
    desc: "Product Summary - GADD",
    file: require("./2025 Product Summary - GADD.pdf"),
    fileName: "RI Product Summary 2025 - GADD.pdf",
    type: [Enums.P_CODE.RI]
  },
  {
    desc: "Product Summary - GCCE",
    file: require("./2025 Product Summary - GCCExtra.pdf"),
    fileName: "RI Product Summary 2025 - GCCE.pdf",
    type: [Enums.P_CODE.RI]
  },
  {
    desc: "Product Summary - GTL",
    file: require("./Product Summary - GTL_Pru Agy Life 2024.pdf"),
    fileName: "Product Summary - GTL.pdf",
    type: [Enums.P_CODE.PRUAGENCY]
  },
  {
    desc: "Product Summary - GCCA",
    file: require("./Product Summary - GCCAccl_Pru Agy Life 2024.pdf"),
    fileName: "Product Summary - GCCA.pdf",
    type: [Enums.P_CODE.PRUAGENCY]
  },
  {
    desc: "Product Summary - Group Pre-Crisis Guard - Accelerated",
    file: require("./Product Summary - Group Pre-Crisis Guard - Accelerated_Pru Agy Life 2024.pdf"),
    fileName: "Product Summary - Group Pre-Crisis Guard - Accelerated.pdf",
    type: [Enums.P_CODE.PRUAGENCY]
  },
  {
    desc: "Your Guide to Health Insurance (English/Chinese Version)",
    docType: "weblink",
    weblink: "https://www.lia.org.sg/tools-and-resources/consumer-guides",
    fileName: "Your Guild to Health Insurance (English/Chinese Version)",
    type: [Enums.P_CODE.PRUAGENCYMED]
  },
  {
    desc: "Pru Agy Vol 2024 Product Summary - GHS",
    file: require("./Pru Agy Vol 2024 Product Summary - GHS.pdf"),
    fileName: "Pru Agy Vol 2024 Product Summary - GHS.pdf",
    type: [Enums.P_CODE.PRUAGENCYMED]
  },
  {
    desc: "Pru Agy Vol 2024 Product Summary - GEMM",
    file: require("./Pru Agy Vol 2024 Product Summary - GEMM.pdf"),
    fileName: "Pru Agy Vol 2024 Product Summary - GEMM.pdf",
    type: [Enums.P_CODE.PRUAGENCYMED]
  },
  {
    desc: "Pru Agy Vol 2024 Product Summary - GADD",
    file: require("./Pru Agy Vol 2024 Product Summary - GADD.pdf"),
    fileName: "Pru Agy Vol 2024 Product Summary - GADD.pdf",
    type: [Enums.P_CODE.PRUAGENCYMED]
  },
  {
    desc:
      "OA Circular 036/24 - Enrolment for One Agency Voluntary Medical and Group Accidental Death",
    file: require("./OA Circular 036_24 - Enrolment for One Agency Voluntary Medical and Group Accidental Death.pdf"),
    fileName:
      "OA Circular 036_24 - Enrolment for One Agency Voluntary Medical and Group Accidental Death.pdf",
    type: [Enums.P_CODE.PRUAGENCYMED]
  }
];
